<template>
  <div>
    <SidebarNav />
    <MobileNavbarDash />
    <MobileSidebarDash />
    <SidebarNav />
    <!-- Content Wrapper -->
    <div class="view-wrapper">
      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <!-- Sidebar Trigger -->

            <div class="title-wrap">
              <h1 class="title is-4">Update Data Quisonary</h1>
            </div>
          </div>

          <div class="page-content-inner">
            <!--Form Layout 1-->
            <div class="form-layout">
              <div class="form-outer">
                <div class="form-header stuck-header">
                  <div class="form-header-inner">
                    <div class="left">
                      <h3>Form Input Quisonary</h3>
                    </div>
                    <div class="right">
                      <div class="buttons">
                        <button
                          @click="$router.go(-1)"
                          class="button h-button is-light is-dark-outlined"
                        >
                          <span class="icon">
                            <i class="lnir lnir-arrow-left rem-100"></i>
                          </span>
                          <span>Batal</span>
                        </button>
                        <button
                          class="button h-button is-primary is-raised"
                          @click.prevent="sendKuesioner"
                          :disabled="isFormInvalid"
                        >
                          Simpan
                        </button>
                        <div class="tombol">
                          <button
                            class="button h-button is-danger is-raised"
                            @click.prevent="hapus"
                            type="is-success"
                            outlined
                          >
                            Hapus
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-body">
                  <!--Fieldset-->
                  <div class="form-fieldset">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <div class="field">
                          <div class="control">
                            <label class="label">Pertayaan</label>

                            <input
                              @blur="$v.form.question.$touch()"
                              v-model="form.question"
                              type="text"
                              class="input is-link"
                              placeholder="Masukan Pertayaan"
                            />
                            <div v-if="$v.form.question.$error">
                              <span
                                v-if="!$v.form.question.required"
                                class="help is-danger"
                                >Pertayaan Harus Diisi</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="column is-12">
                        <div class="field">
                          <div class="control">
                            <label class="label">Type Jawaban</label>
                            <div class="grup">
                              <b-field type="is-link">
                                <b-select
                                  @blur="$v.form.type.$touch()"
                                  v-model="form.type"
                                  placeholder="Masukan Type Jawaban"
                                  expanded
                                >
                                  <!-- <option value="Checkbox">Check Box</option> -->
                                  <option value="Radio">Radio</option>
                                  <!-- <option value="Text">Text</option> -->
                                  <option value="Range">Range</option>
                                </b-select>
                              </b-field>

                              <div v-if="$v.form.type.$error">
                                <span
                                  v-if="!$v.form.type.required"
                                  class="help is-danger"
                                  >Type Harus Diisi</span
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- Star Type jawaban -->
                      <div class="column is-12">
                        <div class="field">
                          <div class="control">
                            <!--  star type Multiple Choice -->
                            <div
                              v-if="form.type === 'Radio'"
                              class="type-radio"
                            >
                              <label class="label">Jawaban</label>

                              <div class="field">
                                <div
                                  class="control is-expanded inputan"
                                  v-for="(item, index) in form.answers"
                                  :key="index"
                                >
                                  <input
                                    v-model="item.answer"
                                    class="input is-link"
                                    type="text"
                                    placeholder="Input Jawaban"
                                  />
                                  <div class="control">
                                    <div class="is-grouped">
                                      <button
                                        class="button h-button is-raised is-success"
                                        @click.prevent="updateJawaban(item)"
                                      >
                                        <span class="text">√</span>
                                      </button>
                                      <button
                                        class="button h-button is-raised is-danger"
                                        @click.prevent="
                                          hapusFormAnswer(index, item.id)
                                        "
                                      >
                                        <span class="text"> &times;</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                <div class="field">
                                  <div
                                    class="control is-expanded inputan"
                                    v-for="(item, index) in answerss"
                                    :key="index"
                                  >
                                    <input
                                      v-model="item.answer"
                                      class="input is-link"
                                      type="text"
                                    />
                                    <div class="is-grouped">
                                      <button
                                        class="button h-button is-raised is-success"
                                        @click.prevent="updateJawaban(item)"
                                      >
                                        <span class="text">√</span>
                                      </button>
                                      <button
                                        class="button h-button is-raised is-danger"
                                        @click.prevent="
                                          hapusFormAnswer(index, item.id)
                                        "
                                      >
                                        <span class="text"> &times;</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                <!-- end tampilan jawaban -->
                                <!-- star tambahan jawaban -->
                                <div>
                                  <div class="field input-baru">
                                    <div class="control is-expanded inputan">
                                      <input
                                        @blur="$v.formAnswer.answer.$touch()"
                                        v-model="formAnswer.answer"
                                        @keyup.enter="addNewAnswer"
                                        class="input is-link"
                                        type="text"
                                        placeholder="Input Jawaban Baru"
                                      />
                                      <br />
                                      <!-- <div v-if="$v.formAnswer.answer.$error">
                                        <span
                                          v-if="!$v.formAnswer.answer.required"
                                          class="help is-danger"
                                          >Jawaban Baru Harus Diisi</span
                                        >
                                      </div> -->
                                      <div class="is-grouped">
                                        <button
                                          class="button h-button is-raised is-link"
                                          @click="addNewAnswer"
                                          :disabled="isformAnswernvalid"
                                        >
                                          <span class="text">+</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <!-- end tambahan jawaban -->
                              </div>
                            </div>

                            <!-- star ceckbox -->
                            <!-- <div
                              v-if="form.type === 'Checkbox'"
                              class="type-radio"
                            >
                              <label class="label">Jawaban</label>

                              <div class="field">
                                <div
                                  class="control is-expanded inputan"
                                  v-for="(item, index) in form.answers"
                                  :key="index"
                                >
                                  <input
                                    v-model="item.answer"
                                    class="input is-link"
                                    type="text"
                                    placeholder="Input Jawaban"
                                  />
                                  <div class="control">
                                    <div class="is-grouped">
                                      <button
                                        class="button h-button is-raised is-success"
                                        @click.prevent="updateJawaban(item)"
                                      >
                                        <span class="text">√</span>
                                      </button>
                                      <button
                                        class="button h-button is-raised is-danger"
                                        @click.prevent="
                                          hapusFormAnswer(index, item.id)
                                        "
                                      >
                                        <span class="text"> &times;</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                               
                                <div>
                                  <div class="field input-baru">
                                    <div class="control is-expanded inputan">
                                      <input
                                        @blur="$v.formAnswer.answer.$touch()"
                                        v-model="formAnswer.answer"
                                        @keyup.enter="addNewAnswer"
                                        class="input is-link"
                                        type="text"
                                        placeholder="Input Jawaban Baru"
                                      />
                                      <br />
                                      <div v-if="$v.formAnswer.answer.$error">
                                        <span
                                          v-if="!$v.formAnswer.answer.required"
                                          class="help is-danger"
                                          >Jawaban Baru Harus Diisi</span
                                        >
                                      </div>
                                      <div class="is-grouped">
                                        <button
                                          class="button h-button is-raised is-link"
                                          @click="addNewAnswer"
                                          :disabled="isformAnswernvalid"
                                        >
                                          <span class="text">+</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              
                              </div>
                            </div> -->
                            <!-- end ceckbox -->

                            <!-- star type range -->
                            <div
                              v-if="form.type === 'Range'"
                              class="type-radio"
                            >
                              <label class="label">Jawaban</label>

                              <div class="field">
                                <div
                                  class="control is-expanded inputan"
                                  v-for="(item, index) in form.answers"
                                  :key="index"
                                >
                                  <input
                                    v-model="item.answer"
                                    class="input is-link"
                                    type="number"
                                    min="1"
                                    max="10"
                                    placeholder="Input Jawaban"
                                  />
                                  <div class="control">
                                    <div class="is-grouped">
                                      <button
                                        class="button h-button is-raised is-success"
                                        @click.prevent="updateJawaban(item)"
                                      >
                                        <span class="text">√</span>
                                      </button>
                                      <button
                                        class="button h-button is-raised is-danger"
                                        @click.prevent="
                                          hapusFormAnswer(index, item.id)
                                        "
                                      >
                                        <span class="text"> &times;</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                <div class="field">
                                  <div
                                    class="control is-expanded inputan"
                                    v-for="(item, index) in answerss"
                                    :key="index"
                                  >
                                    <input
                                      v-model="item.answer"
                                      class="input is-link"
                                       type="number"
                                    min="1"
                                    max="10"
                                    />
                                    <div class="is-grouped">
                                      <button
                                        class="button h-button is-raised is-success"
                                        @click.prevent="updateJawaban(item)"
                                      >
                                        <span class="text">√</span>
                                      </button>
                                      <button
                                        class="button h-button is-raised is-danger"
                                        @click.prevent="
                                          hapusFormAnswer(index, item.id)
                                        "
                                      >
                                        <span class="text"> &times;</span>
                                      </button>
                                    </div>
                                  </div>
                                </div>

                                <!-- end tampilan jawaban -->
                                <!-- star tambahan jawaban -->
                                <div>
                                  <div class="field input-baru">
                                    <div class="control is-expanded inputan">
                                      <input
                                        @blur="$v.formAnswer.answer.$touch()"
                                        v-model="formAnswer.answer"
                                        @keyup.enter="addNewAnswer"
                                        class="input is-link"
                                        type="number"
                                        min="1"
                                        max="10"
                                        placeholder="Input Jawaban Baru"
                                      />
                                      <!-- <div v-if="$v.formAnswer.answer.$error">
                                        <span
                                          v-if="!$v.formAnswer.answer.required"
                                          class="help is-danger"
                                          >Jawaban Baru Harus Diisi</span
                                        >
                                      </div> -->
                                      <div class="is-grouped">
                                        <button
                                          class="button h-button is-raised is-link"
                                          @click="addNewAnswer"
                                          :disabled="isformAnswernvalid"
                                        >
                                          <span class="text">+</span>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <!-- end tambahan jawaban -->
                              </div>
                            </div>

                            <!-- end type range -->

                            <!-- star type  text -->
                            <!-- <div v-if="form.type === 'Text'" class="type-text">
                              <div
                                class="control is-expanded inputan"
                                v-for="(item, index) in form.answers"
                                :key="index"
                              >
                                <textarea
                                  class="textarea is-link"
                                  v-model="item.answer"
                                  placeholder="e.g. text area"
                                  disabled
                                ></textarea>
                              </div>
                            </div> -->
                          </div>
                        </div>
                      </div>

                      <!-- End Type Jawaban -->
                    </div>
                  </div>
                  <!-- end form -->
                </div>
              </div>
            </div>
            <!-- end -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import SidebarNav from "../dashboardnav/SidebarNav.vue";
import MobileNavbarDash from "../dashboardnav/MobileNavbarDash.vue";
import MobileSidebarDash from "../dashboardnav/MobileSidebarDash.vue";

export default {
  name: "FormUpdateQuestionary",
  components: {
    SidebarNav,
    MobileNavbarDash,
    MobileSidebarDash,
  },
  data() {
    return {
      answerId: 1,
      answerss: [],
      form: {
        id: null,
        question: null,
        type: null,
        answers: [],
      },
      formAnswer: {
        answer: null,
        questionId: this.$route.params.id,
      },
    };
  },
  validations: {
    form: {
      question: { required },
      type: { required },
    },
    formAnswer: {
      answer: { required },
      questionId: { required },
    },
  },
  created() {
    this.id = this.$route.params.id;
    this.fetchQuestionById(this.id).then((resp) => {
      // console.log(resp);
      this.form = resp;
    });
  },
  computed: {
    ...mapState({
      content: (state) => state.question.items,
      answer: (state) => state.answer.items,
    }),
    isFormInvalid() {
      return this.$v.form.$invalid;
    },
    isformAnswernvalid() {
      return this.$v.formAnswer.$invalid;
    },
  },
  methods: {
    ...mapActions("question", [
      "fetchQuestion",
      "fetchQuestionById",
      "updateQuestion",
      "deletQuestionById",
    ]),
    ...mapActions("answer", ["addAnswer", "updateAnswer", "deletAnswerById"]),
    // input tambah jawaban

    addNewAnswer() {
      if (this.formAnswer.answer.trim().length === 0) {
        return;
      }
      this.answerss.push({
        id: this.formAnswer.id,
        answer: this.formAnswer.answer,
      });
      this.$store.dispatch("answer/addAnswer", this.formAnswer);
      console.log();
      this.formAnswer.answer = "";
    },

    hapusFormAnswer(index, id) {
      // console.log(index);
      // console.log(id);
      this.form.answers.splice(index, 1);
      this.$store.dispatch("answer/deletAnswerById", id);
    },

    // update jawaban
    updateJawaban(payload) {
      console.log(payload);
      this.updateAnswer(payload).then(() => {
        this.$toasted.success("Rubah Data Kuesionary Berhasil", {
          duration: 3000,
        });
        // this.$router.push("/dashboard/kuesioner");
      });
    },

    deleteAnswer(payload) {
      this.$store.dispatch("answer/deletAnswerById", payload);
    },
    // end update jawaban

    sendKuesioner() {
      this.updateQuestion(this.form)
        .then(() => {
          this.$toasted.success("Rubah Data Kuesionary Berhasil", {
            duration: 3000,
          });
          this.$router.push("/dashboard/kuesioner");
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 3000 });
        });
    },
    hapus() {
      this.$swal({
        title: "Apakah anda yakin akan menghapus kuesionary tersebut?!",
        text: "Setelah dihapus anda tidak dapat mengembalikannya!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#c0392b",
        confirmButtonText: "YA, HAPUS!",
      }).then((result) => {
        // <--
        if (result.value) {
          // <-- if confirmed
          this.$store.dispatch("question/deletQuestionById", this.id);
          this.$router.push("/dashboard/kuesioner");
          this.$toasted.success("Hapus Data Kuesionary Berhasil", {
            duration: 3000,
          });
        }
      });
    },
    mounted() {
      this.id = this.$route.params.id;
      this.fetchQuestionById(this.id).then((resp) => {
        this.form = resp;
      });
    },
  },
};
</script>

<style scoped>
.jawaban {
  display: flex;
  flex-direction: column;
  width: 483px;
}
.inputan {
  display: flex;
  margin-bottom: 10px;
}
.is-grouped {
  display: flex;
}
.is-grouped .button {
  width: 40px;
  height: 39px;
  margin-left: 10px;
  cursor: pointer;
}
.is-grouped .button .text {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}
.input-baru {
  opacity: 1;
}
/* .input-baru.show {
  opacity: 1;
} */
.tambah {
  position: absolute;
  margin-top: -147px;
  margin-left: 100%;
}
.tambah .button {
  width: 40px;
  height: 39px;
  margin-left: 10px;
  cursor: pointer;
}
.tambah .button .text {
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}
@media (max-width: 768px) {
  .tombol {
    margin-top: 10px;
    width: 300px;
  }
}
</style>
