<template>
  <div class="nav">
    <section>
      <div class="layer">
        <div class="field logo">
          <div class="control img"></div>
          <span class="title"
            >BALAI BESAR WILAYAH SUNGAI CIMANUK CISANGGARUNG <br />
            <span class="title1">
              DIREKTORAT JENDERAL SUMBER DAYA AIR <br />
              <span class="title">
                KEMENTERIAN PEKERJAAN UMUM DAN PERUMAHAN RAKYAT</span
              ></span
            >
          </span>
        </div>
      </div>
    </section>
    <br />
    <section>
      <b-dropdown aria-role="list">
        <template #trigger="{ active }">
          <b-button
            class="button"
            label="Menu"
            type="is-dark is-rounded is-focused"
            :icon-right="active ? 'menu-up' : 'menu-down'"
          />
        </template>

        <div class="">
          <b-dropdown-item aria-role="listitem">
            <router-link class="text" to="/">
              <i class="fa-solid fa-water"></i>
              Telemetry</router-link
            >
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem">
            <router-link class="text" to="/kualitasair">
              <i class="fa-solid fa-droplet"></i>
              Kualitas Air</router-link
            >
          </b-dropdown-item>

          <b-dropdown-item aria-role="listitem">
            <router-link class="text" to="/posdinas">
              <i class="fa-solid fa-cloud-bolt"></i>
              Pos Pengelolaan Dinas
            </router-link>
          </b-dropdown-item>
          <!-- <b-dropdown-item aria-role="listitem">
            <router-link class="text" to="/klimatologi">
              <i class="fa-solid fa-temperature-high"></i>
              Klimatologi</router-link
            >
          </b-dropdown-item> -->
          <div>
            <section v-if="!isAuthResolved">
              <b-dropdown-item aria-role="listitem">
                <router-link class="text" to="/login" data-hint="Login">
                  <i class="fa-solid fa-right-to-bracket"></i>
                  Masuk</router-link
                >
              </b-dropdown-item>
            </section>

            <section v-else>
              <b-dropdown-item aria-role="listitem">
                <router-link class="text" to="/dashboard/profile">
                  <i class="fa-solid fa-user"></i>
                  Dashboard</router-link
                >
              </b-dropdown-item>
            </section>
          </div>
        </div>
      </b-dropdown>
    </section>
  </div>
</template>

<script>
export default {
  name: "LinkNavigationBaru",
  components: {},
  data() {
    return {
      // navigation: "telemetry",
    };
  },
  created() {
    const authority = localStorage.getItem("authority");
    if (authority === "ADMIN") {
      this.otoritas = true;
    }
    const loc = this.$route.name;
    if (loc === "LandingPos" || loc === "Home" || loc === "LandingPage") {
      this.location = "150px";
    } else if (loc === "KualitasAir") {
      this.location = "214px";
    } else if (loc === "PosDinasLanding") {
      this.location = "278px";
    } else if (
      loc === "KontenInfo" ||
      loc === "PageInfoUmum" ||
      loc === "PageInformasiAnalisa" ||
      loc === "PageDataOlahan"
    ) {
      this.location = "342px";
    }
    // console.log(this.location)
  },
  computed: {
    isAuthResolved() {
      let isLogin = this.$store.state.auth.isAuthResolved;
      return isLogin;
    },
  },
};
</script>

<style scoped>
/* kops logo */

.logo {
  display: flex;
  /* justify-content: space-evenly; */
  padding: 8px;
}

.img {
  background-image: url("../../assets/logoPu.png");
  width: 55px;
  height: 55px;
  margin-right: 15px;
  /* margin-left: 0px; */
  background-size: contain;
  background-repeat: no-repeat;
  background-size: 55px 55px;
}

.title {
  font-family: "Roboto", Times, serif;
  font-size: 15px;
  font-weight: 600;
  padding-top: 4px;
  color: #212c5f;
}

.title1 {
  font-family: "Roboto", Times, serif;
  font-size: 13px;
  font-weight: 600;
  color: #212c5f;
}

.layer {
  position: relative;
  width: 530px;
  height: 70px;
  backdrop-filter: blur(10px);
  background: rgba(255, 254, 254, 0.369);
  border: 1px solid rgba(233, 231, 231, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.171);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.109);
  border-radius: 10px;
  transition: 0, 5s;
  z-index: 9;
}

/* end */

.button {
  width: 120px;
  height: 40px;
}
.fa-solid {
  margin: 10px;
}

.text {
  font-size: 12px;
  color: #212c5f;
}

@media screen and (max-width: 768px) {
  /* kops logo */
  .logo {
    display: flex;
    padding: 5px;
  }

  .img {
    background-image: url("../../assets/logoPu.png");
    width: 40px;
    height: 40px;
    margin-top: 4px;
    margin-left: 1px;
    margin-right: 3px;
    background-size: contain;
    background-repeat: no-repeat;
    background-size: 40px 40px;
  }

  .title {
    /* font-family: 'Roboto', Times, serif; */
    font-size: 8px;
    font-weight: 600;
    padding-top: 10px;
    color: #212c5f;
  }

  .title1 {
    /* font-family: 'Roboto', Times, serif; */
    font-size: 8px;
    font-weight: 600;
    color: #212c5f;
  }

  .layer {
    position: relative;
    width: 305px;
    height: 60px;
    backdrop-filter: blur(10px);
    background: rgba(255, 254, 254, 0.369);
    border: 1px solid rgba(233, 231, 231, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.171);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.109);
    border-radius: 10px;
    transition: 0, 5s;
  }
  /* end */

  .button {
    width: 100px;
    height: 32px;
  }
  .fa-solid {
    margin-left: 0px;
    margin-right: 7px;
  }
}
</style>
