<template>
  <div>
    <!-- <MobileNavbar />
    <MobileSidebarMain />
    <Sidebar /> -->
    <section class="heads">
      <LinkNavigationBaru />
    </section>

    <div
      id="grid-tiles"
      class="view-wrapper"
      data-naver-offset="214"
      data-menu-item="#layouts-sidebar-menu"
      data-mobile-item="#home-sidebar-menu-mobile"
    >
      <!-- Content Wrapper -->
      <div class="view-wrapper">
        <div class="page-content-wrapper">
          <div class="page-content is-relative">
            <div class="page-title has-text-centered">
              <!-- Sidebar Trigger -->

              <div class="">
                <h1 class="title">Pencarian Data Curah Hujan Manual</h1>
              </div>
            </div>

            <section>
              <div>
                <span>
                  <h4 class="text">{{ kdstr.namaPos }}</h4>
                </span>
              </div>
            </section>
            <section>
              <div>
                <span>
                  <h4 class="text1">{{ kdstr.lokasiPos }}</h4>
                </span>
              </div>
            </section>
            <br />

            <!-- START -->
            <div class="page-content-inner">
              <!--Business Dashboard V1-->
              <div class="business-dashboard flights-dashboard">
                <div class="columns">
                  <div class="column">
                    <!--Booking bar-->
                    <div class="booking-bar-wrapper is-info">
                      <img
                        class="travel-illustration light-image image"
                        src="../../assets/pdf-icon-2616.png"
                        alt=""
                      />

                      <div class="booking-bar">
                        <div class="booking-bar-inputs">
                          <div class="control has-icon">
                            <p class="label">Periode Tahun</p>
                            <date-picker
                              class="periode"
                              @blur="$v.form.tahun.$touch()"
                              v-model="form.tahun"
                              format="YYYY"
                              valueType="YYYY"
                              type="year"
                              placeholder="Pilih Tahun"
                            ></date-picker>
                            <div v-if="$v.form.tahun.$error">
                              <span
                                v-if="!$v.form.tahun.required"
                                class="help is-danger"
                                >Periode Harus Diisi</span
                              >
                            </div>
                          </div>
                          <div class="control has-icon tombol">
                            <button
                              :disabled="isFormInvalid"
                              class="button h-button is-success is-light"
                              @click.prevent="cariChannel"
                            >
                              <span class="titles">
                                <i class="fas fa-search"></i>
                                Cari Data
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <ModalInputKepuasan
                      :buka="isOpenKepuasan"
                      @onLoad="onLoading"
                      @close="close"
                    />
                    <ModalLoading :buka="isOpen" />

                    <div class="flights">
                      <div class="table-wrapper">
                        <section>
                          <table
                            class="table is-datatable is-hoverable table-is-bordered"
                          >
                            <thead class="is-primary">
                              <tr class="head">
                                <th>TANGGAL</th>
                                <th>JAN</th>
                                <th>FEB</th>
                                <th>MAR</th>
                                <th>APR</th>
                                <th>MEI</th>
                                <th>JUN</th>
                                <th>JUL</th>
                                <th>AGS</th>
                                <th>SEP</th>
                                <th>OKT</th>
                                <th>NOV</th>
                                <th>DES</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(hidro, index) in dataTahunan"
                                :key="index"
                              >
                                <td class="tanggal" data-label="TANGGAL">
                                  {{ hidro.tanggal }}
                                </td>
                                <td data-label="JANUARI">
                                  {{ hidro.januari }}
                                </td>
                                <td data-label="FEBUARI">
                                  {{ hidro.februari }}
                                </td>
                                <td data-label="MARET">{{ hidro.maret }}</td>
                                <td data-label="APRIL">{{ hidro.april }}</td>
                                <td data-label="MEI">{{ hidro.mei }}</td>
                                <td data-label="JUNI">{{ hidro.juni }}</td>
                                <td data-label="JULI">{{ hidro.juli }}</td>
                                <td data-label="AGUSTUS">
                                  {{ hidro.agustus }}
                                </td>
                                <td data-label="SEPTEMBER">
                                  {{ hidro.september }}
                                </td>
                                <td data-label="OKTOBER">
                                  {{ hidro.oktober }}
                                </td>
                                <td data-label="NOVEMBER">
                                  {{ hidro.november }}
                                </td>
                                <td data-label="DESEMBER">
                                  {{ hidro.desember }}
                                </td>
                              </tr>
                            </tbody>
                            <tfoot>
                              <tr>
                                <td data-label="MIN">
                                  <span class="">Min</span>
                                </td>
                                <td data-label="JANUARI">{{ min.januari }}</td>
                                <td data-label="FEBUARI">{{ min.februari }}</td>
                                <td data-label="MARET">{{ min.maret }}</td>
                                <td data-label="APRIL">{{ min.april }}</td>
                                <td data-label="MEI">{{ min.mei }}</td>
                                <td data-label="JUNI">{{ min.juni }}</td>
                                <td data-label="JULI">{{ min.juli }}</td>
                                <td data-label="AGUSTUS">{{ min.agustus }}</td>
                                <td data-label="SEPTEMBER">
                                  {{ min.september }}
                                </td>
                                <td data-label="OKTOBER">{{ min.oktober }}</td>
                                <td data-label="NOVEMBER">
                                  {{ min.november }}
                                </td>
                                <td data-label="DESEMBER">
                                  {{ min.desember }}
                                </td>
                              </tr>
                              <tr>
                                <td data-label="MAKS">
                                  <span class="">Maks</span>
                                </td>
                                <td data-label="JANUARI">{{ max.januari }}</td>
                                <td data-label="FEBUARI">{{ max.februari }}</td>
                                <td data-label="MARET">{{ max.maret }}</td>
                                <td data-label="APRIL">{{ max.april }}</td>
                                <td data-label="MEI">{{ max.mei }}</td>
                                <td data-label="JUNI">{{ max.juni }}</td>
                                <td data-label="JULI">{{ max.juli }}</td>
                                <td data-label="AGUSTUS">{{ max.agustus }}</td>
                                <td data-label="SEPTEMBER">
                                  {{ max.september }}
                                </td>
                                <td data-label="OKTOBER">{{ max.oktober }}</td>
                                <td data-label="NOVEMBER">
                                  {{ max.november }}
                                </td>
                                <td data-label="DESEMBER">
                                  {{ max.desember }}
                                </td>
                              </tr>
                              <tr>
                                <td data-label="RATA-TATA">
                                  <span class="">Rata-rata</span>
                                </td>
                                <td data-label="JANUARI">
                                  {{ rerata.januari }}
                                </td>
                                <td data-label="FEBUARI">
                                  {{ rerata.februari }}
                                </td>
                                <td data-label="MARET">{{ rerata.maret }}</td>
                                <td data-label="APRIL">{{ rerata.april }}</td>
                                <td data-label="MEI">{{ rerata.mei }}</td>
                                <td data-label="JUNI">{{ rerata.juni }}</td>
                                <td data-label="JULI">{{ rerata.juli }}</td>
                                <td data-label="AGUSTUS">
                                  {{ rerata.agustus }}
                                </td>
                                <td data-label="SEPTEMBER">
                                  {{ rerata.september }}
                                </td>
                                <td data-label="OKTOBER">
                                  {{ rerata.oktober }}
                                </td>
                                <td data-label="NOVEMBER">
                                  {{ rerata.november }}
                                </td>
                                <td data-label="DESEMBER">
                                  {{ rerata.desember }}
                                </td>
                              </tr>
                            </tfoot>
                          </table>
                        </section>
                      </div>
                    </div>
                    <div class="buttons is-right">
                      <button
                        :disabled="isShow"
                        class="button is-danger is-focused"
                        @click.prevent="onGeneratePdf"
                      >
                        <span class="titles">
                          <i class="fas fa-file-pdf"></i>
                          Unduh PDF
                          <i class="fas fa-angle-down"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- END -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import ModalLoading from "./ModalLoading";
import ModalInputKepuasan from "./ModalInputKepuasan";

// import Sidebar from "../navigation/Sidebar.vue";
// import MobileNavbar from "../navigation/MobileNavbar.vue";
// import MobileSidebarMain from "../navigation/MobileSidebarMain.vue";
import LinkNavigationBaru from "../navigation/LinkNavigationBaru.vue";

export default {
  name: "HidrologiPchSearchData",
  components: {
    // Sidebar,
    // MobileNavbar,
    // MobileSidebarMain,
    LinkNavigationBaru,
    DatePicker,
    ModalLoading,
    ModalInputKepuasan,
  },

  data() {
    return {
      data: [],
      isOpen: false,
      isOpenKepuasan: false,
      dataTahunan: [],
      min: "",
      max: "",
      rerata: "",
      form: {
        registerId: null,
        tahun: null,
      },
      isShow: true,
      kdstr: {
        elevasi: null,
        gambar: null,
        kodeRegister: null,
        lat: null,
        lng: null,
        lokasiPos: null,
        namaPos: null,
        isManualPch: true,
        isManualPda: true,
        isTelemetryPch: true,
        isTelemetryPda: true,
        sungai: {
          namaSungai: null,
        },
      },
    };
  },
  validations: {
    form: {
      tahun: {
        required,
      },
    },
  },
  created() {
    this.form.registerId = this.$route.params.registerId;
    const regId = this.$route.params.registerId;
    this.fetchLandingKadasterById(regId).then((resp) => {
      this.kdstr = resp;
      this.registerId = regId;
    });
  },
  computed: {
    isFormInvalid() {
      return this.$v.form.$invalid;
    },
    isShowButton() {
      return this.isShow;
    },
    ...mapState({
      landingHidrologiTahunan: (state) => state.landingHidrologiTahunan.items,
      hidroGeneratePdf: (state) => state.hidroGeneratePdf.item,
      kadaster: (state) => state.landingKadaster.item,
    }),
  },
  methods: {
    ...mapActions("landingHidrologiTahunan", ["fetchHidrologiPchTahunan"]),
    ...mapActions("hidroGeneratePdf", [
      "generateHidrologiPchTahunanPdf",
      "downloadPdfFile",
    ]),
    ...mapActions("landingKadaster", [
      "fetchLandingKadasterById",
      "getLandingKadasterFile",
    ]),

    cariChannel() {
      this.fetchHidrologiPchTahunan(this.form)
        .then(() => {
          this.dataTahunan = [];

          this.min = this.landingHidrologiTahunan.min;
          this.max = this.landingHidrologiTahunan.max;
          this.rerata = this.landingHidrologiTahunan.rerata;
          let currentTotal = this.landingHidrologiTahunan.dataTahunan.length;
          if (currentTotal / this.perPage > 1000) {
            currentTotal = this.perPage * 1000;
          }
          if (currentTotal > 0) {
            this.isShow = false;
          } else {
            this.isShow = true;
          }
          this.landingHidrologiTahunan.dataTahunan.forEach((item) => {
            this.loading = false;
            this.dataTahunan.push(item);
            // console.log(item)
          });
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 3000 });
        });
    },
    onGeneratePdf() {
      this.isOpenKepuasan = true;
      this.isShow = true;
    },
    onLoading() {
      this.isOpenKepuasan = false;
      this.isOpen = true;
      this.isShow = true;
      this.generateHidrologiPchTahunanPdf(this.form).then(() => {
        let filename = this.hidroGeneratePdf;
        this.downloadPdfFile(filename);

        setTimeout(() => (this.isOpen = false), 2000);
      });
    },
    close() {
      this.isOpenKepuasan = false;
    },
    /*
     * Handle page-change event
     */
    onPageChange(page) {
      this.page = page;
      // this.loadAsyncData();
    },
    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      // this.loadAsyncData();
    },
  },
};
</script>

<style scoped>
.heads {
  padding-top: 10px;
  margin-left: 20px;
}
.image {
  position: relative;
  height: 160px;
  width: 160px;
}

.fa-angle-down {
  margin-left: 10px;
}

.fa-file-pdf {
  margin-bottom: 3px;
}

.fas {
  margin-right: 10px;
}

.content-title {
  font-size: 14px;
  font-weight: 500;
}
.titles {
  font-size: 14px;
  font-weight: 400;
  /* color: #fff; */
}

.text1 {
  font-size: 18px;
  font-weight: 500;
}

.text {
  font-size: 22px;
  font-weight: 500;
}
.tombol {
  margin-top: 25px;
}

@media (max-width: 768px) {
  .button {
    margin-top: 20px;
  }
  .image {
    margin-left: -20px;
    height: 125px;
    width: 125px;
  }
  .title {
    font-size: 22px;
  }
  .text1 {
    font-size: 16px;
    font-weight: 500;
    margin-left: 10px;
    margin-top: -10px;
  }
  .text {
    font-size: 20px;
    font-weight: 500;
    color: black;
  }
}
</style>
