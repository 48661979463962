import axiosInstance from "../../conf/axiosAdmin";

export default {
  namespaced: true,
  state: {
    items: [],
    item: {},
  },
  actions: {
    fetchChannelKlimHourly({ state, commit }, payload) {
      commit(
        "setItems",
        { resource: "searchChannelKlim", items: [] },
        { root: true }
      );
      return axiosInstance
        .post(
          `/api/channel/search/hourly/klm/${payload.registerId}`,
          payload.search
        )
        .then((res) => {
          const searchKlim = res.data;
          commit(
            "setItems",
            { resource: "searchChannelKlim", items: searchKlim },
            { root: true }
          );
          return state.items;
        });
    },
    fetchChannelKlimDaily({ state, commit }, payload) {
      commit(
        "setItems",
        { resource: "searchChannelKlim", items: [] },
        { root: true }
      );
      return axiosInstance
        .post(
          `/api/channel/search/daily/klm/${payload.registerId}`,
          payload.search
        )
        .then((res) => {
          const searchKlim = res.data;
          commit(
            "setItems",
            { resource: "searchChannelKlim", items: searchKlim },
            { root: true }
          );
          return state.items;
        });
    },
  },
  mutations: {},
};
