import axios from "../../conf/axios";
// import ApiMethods from '@/conf/config.js'

export default {
  namespaced: true,
  state: {
    items: [],
    item: {},
  },
  getters: {},
  actions: {
    generatePosDinasPchTahunan({ state, commit }, payload) {
      commit(
        "setItem",
        { resource: "generatePosDinasPdf", item: {} },
        { root: true }
      );
      return axios
        .post(
          `/api/landing/generate/posdinas/pch/${payload.registerId}`,
          payload
        )
        .then((res) => {
          const fileName = res.data.fileName;
          commit(
            "setItem",
            { resource: "generatePosDinasPdf", item: fileName },
            { root: true }
          );
          return state.item;
        });
    },
    generatePosDinasPdaTahunan({ state, commit }, payload) {
      commit(
        "setItem",
        { resource: "generatePosDinasPdf", item: {} },
        { root: true }
      );
      return axios
        .post(
          `/api/landing/generate/posdinas/pda/${payload.registerId}`,
          payload
        )
        .then((res) => {
          const fileName = res.data.fileName;
          commit(
            "setItem",
            { resource: "generatePosDinasPdf", item: fileName },
            { root: true }
          );
          return state.item;
        });
    },

    downloadPdfFile({ state, commit }, payload) {
      commit(
        "setItem",
        { resource: "generatePosDinasPdf", item: {} },
        { root: true }
      );
      return axios
        .get(`/api/landing/download/${payload}`, {
          method: "get",
          responseType: "arraybuffer",
        })
        .then((res) => {
          const files = res.data;
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", payload);
          document.body.appendChild(link);
          link.click();
          commit(
            "setItem",
            { resource: "generatePosDinasPdf", item: files },
            { root: true }
          );
          return state.item;
        });
    },
  },
  mutations: {},
};
