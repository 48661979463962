<template>
  <div>
    <div class="tombol">
      <div class="tombol-button">
        <button
          class="button h-button is-success is-elevated"
          @click="isOpen = !isOpen"
          type="is-success"
          outlined
        >
          Pratinjau
        </button>
      </div>
      <!-- <div class="control btn1">
        <button
          @click.prevent="deletVolume"
          @click="isOpen = false"
          :disabled="isFormInvalid"
          class="button is-normal is-danger is-focused"
        >
          <span> Hapus </span>
        </button>
      </div> -->
    </div>

    <div :class="['modal', { 'is-active': isOpen }]">
      <div class="modal-background"></div>

      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Pengkinian Data Volume</p>
          <!-- TODO: Close Modal set isOpen to false -->
          <button
            @click="isOpen = false"
            class="delete"
            aria-label="close"
          ></button>
        </header>
        <section class="modal-card-body">
          <form>
            <div class="field">
              <div class="control">
                <label class="label is-normal">Volume</label>
                <input
                  @blur="$v.form.volume.$touch()"
                  v-model="form.volume"
                  type="number"
                  class="input"
                  placeholder="Masukan volume"
                />
                <div v-if="$v.form.volume.$error">
                  <span v-if="!$v.form.volume.required" class="help is-danger"
                    >volume Harus Diisi</span
                  >
                </div>
              </div>
            </div>
          </form>
        </section>
        <footer class="modal-card-foot">
          <div class="field is-grouped">
            <div class="control btn1">
              <button
                @click.prevent="editVolume"
                @click="isOpen = false"
                :disabled="isFormInvalid"
                class="button is-normal is-link is-focused"
              >
                <span> Simpan </span>
              </button>
            </div>

            <div class="control btn">
              <button
                @click="isOpen = false"
                class="button is-normal is-danger is-focused"
              >
                <span> Batal </span>
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";

export default {
  components: {},
  props: {
    volumeId: {
      type: Number,
      required: false,
    },
    evapVolume: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      isOpen: false,
      form: {
        id: this.volumeId,
        volume: this.evapVolume,
      },
    };
  },
  validations: {
    form: {
      volume: {
        required,
      },
    },
  },
  // created() {
  //   this.fetchEvaporasiByRegisterId(this.volumeId).then((res) => {
  //     this.form = res;
  //   });
  // },
  computed: {
    ...mapState({
      evaporasi: (state) => state.evaporasi.item,
    }),
    isFormInvalid() {
      return this.$v.form.$invalid;
    },
  },
  methods: {
    ...mapActions("evaporasi", [
      "fetchEvaporasiByRegisterId",
      "updateVolume",
      "deleteVolume",
    ]),
    editVolume() {
      this.updateVolume(this.form)
        .then(() => {
          this.$toasted.success("Rubah Data Volume Berhasil", {
            duration: 3000,
          });
          this.$emit("updatedSubmit");
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 3000 });
        });
    },
    // deletVolume() {
    //   this.$swal({
    //     title: "Data Volume Akan Dihapus?",
    //     text: "Setelah dihapus anda tidak dapat mengembalikannya!",
    //     type: "warning",
    //     showCancelButton: true,
    //     confirmButtonColor: "#c0392b",
    //     confirmButtonText: "YA, HAPUS!",
    //   }).then(() => {
    //     this.$store.dispatch("evaporasi/deleteVolume", this.volumeId);

    //     // this.$emit("deletSubmit");
    //     this.$toasted.success("Delet Volume Success", { duration: 3000 });
    //   });
    // },
  },
};
</script>

<style scoped>
.modal {
  animation: swingIn 1.2s ease-in-out;
}

@keyframes swingIn {
  0% {
    transform: scaleY(0.005) scaleX(0);
  }
  50% {
    transform: scaleY(0.005) scaleX(1);
  }
  100% {
    transform: scaleY(1) scaleX(1);
  }
}

.modal-card-title {
  font-size: 20px;
  font-weight: 600;
  color: #2d6efd;
  letter-spacing: 1px;
}
.buttons .button {
  width: 140px;
  margin-right: 20px;
}
.buttons .text {
  letter-spacing: 1px;
}
i {
  margin-right: 10px;
  font-size: 14px;
  font-weight: 500;
}
.is-grouped {
  padding-top: 10px;
}
</style>
