import axiosInstance from "../../conf/axiosAdmin";

export default {
  namespaced: true,
  state: {
    items: [],
    item: {},
  },
  getters: {},
  actions: {
    fetchActivityPie({ state, commit }) {
      commit(
        "setItems",
        { resource: "activityPie", items: [] },
        { root: true }
      );
      return axiosInstance.get("/api/activity/counter").then((res) => {
        const activityPies = res.data;
        // console.log(res.data);
        commit(
          "setItems",
          { resource: "activityPie", items: activityPies },
          { root: true }
        );
        return state.items;
      });
    },
  },
  mutations: {},
};
