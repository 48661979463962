<template>
  <div class="modal-channel">
    <button
      class="button h-button is-primary is-elevated"
      @click="isOpen = !isOpen"
      type="is-success"
      outlined
    >
      <i class="fas fa-search"></i><span>Cari Data</span>
    </button>

    <div :class="['modal', { 'is-active': isOpen }]">
      <div class="modal-background"></div>

      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Pencarian Telemetri PCH</p>
          <!-- TODO: Close Modal set isOpen to false -->
          <button
            @click="isOpen = false"
            class="delete"
            aria-label="close"
          ></button>
        </header>
        <section class="modal-card-body">
          <form>
            <div class="form">
              <div class="field">
                <div class="control">
                  <label class="label">Periode Awal</label>
                  <div class="periode">
                    <date-picker
                      class="periode"
                      @blur="$v.form.search.startDate.$touch()"
                      v-model="form.search.startDate"
                      format="YYYY-MM-DD"
                      type="date"
                      placeholder="Select date"
                      valueType="YYYY-MM-DD"
                    ></date-picker>
                    <div v-if="$v.form.search.startDate.$error">
                      <span
                        v-if="!$v.form.search.startDate.required"
                        class="help is-danger"
                        >Periode Awal Harus Diisi</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <label class="label">Periode Akhir</label>
                  <div class="periode">
                    <date-picker
                      class="periode"
                      @blur="$v.form.search.endDate.$touch()"
                      v-model="form.search.endDate"
                      format="YYYY-MM-DD"
                      type="date"
                      placeholder="Select date"
                      valueType="YYYY-MM-DD"
                    ></date-picker>
                    <div v-if="$v.form.search.endDate.$error">
                      <span
                        v-if="!$v.form.search.endDate.required"
                        class="help is-danger"
                        >Periode Akhir Harus Diisi</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <label class="label">Pilih Waktu</label>
                  <div class="select is-link">
                    <select
                      class="select"
                      @blur="$v.form.waktu.$touch()"
                      v-model="form.waktu"
                      aria-placeholder="pilih Satuan Waktu"
                    >
                      <!-- <option>Per 5 menit</option> -->
                      <option value="perjam">
                        <span class="option"> Perjam</span>
                      </option>
                      <option value="harian">
                        <span class="option"> Harian </span>
                      </option>
                    </select>
                    <div v-if="$v.form.waktu.$error">
                      <span
                        v-if="!$v.form.waktu.required"
                        class="help is-danger"
                        >Waktu Harus Diisi</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="is-grup">
              <div class="field">
                <div class="control tombol">
                  <button
                    :disabled="isFormInvalid"
                    class="button is-normal is-link is-focused buttons1"
                    @click.prevent="cariChannel"
                  >
                    <span class="titles">
                      <i class="fas fa-search"></i>
                      Lihat Data
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <br />

            <ModalLoading :buka="isOpenModal" />
            <div class="table-wrapper">
              <section>
                <b-table
                  class="table is-datatable is-hoverable table-is-bordered"
                  :data="data"
                  :columns="columns"
                  paginated
                  backend-pagination
                  :total="total"
                  :per-page="perPage"
                  @page-change="onPageChange"
                  aria-next-label="Next page"
                  aria-previous-label="Previous page"
                  aria-page-label="Page"
                  aria-current-label="Current page"
                  backend-sorting
                  :default-sort-direction="defaultSortOrder"
                  :default-sort="[sortField, sortOrder]"
                  @sort="onSort"
                  icon-pack="fas"
                  icon-left="chevron-right"
                  :bordered="isBordered"
                  :striped="isStriped"
                  :narrowed="isNarrowed"
                  :hoverable="isHoverable"
                  :loading="isLoading"
                  :focusable="isFocusable"
                  :mobile-cards="hasMobileCards"
                >
                  <b-table-column
                    field="tanggal"
                    label="Tanggal"
                    v-slot="props"
                  >
                    {{ props.row.tanggal }}
                  </b-table-column>

                  <b-table-column field="ch1" label="Vol (mm)" v-slot="props">
                    {{ props.row.ch1 }}
                  </b-table-column>
                </b-table>
              </section>
            </div>
          </form>
        </section>
        <footer class="modal-card-foot">
          <div class="field is-grouped">
            <div class="control btn1">
              <button
                class="button h-button is-primary is-light"
                :disabled="isShow"
                @click.prevent="onGenerateExcel"
              >
                <i class="fas fa-file-excel"></i>
                Unduh Excel
                <i class="fas fa-angle-down"></i>
              </button>
            </div>

            <div class="control btn">
              <button
                @click="isOpen = false"
                class="button h-button is-warning is-light"
              >
                <span> Batal </span>
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";

import ModalLoading from "../shared/ModalLoading.vue";

export default {
  name: "SearchTelemetryPch",
  components: {
    DatePicker,
    ModalLoading,
  },
  props: {
    registerId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isOpenModal: false,
      isOpen: false,
      data: [],
      total: 0,
      loading: false,
      sortField: "created",
      sortOrder: "ASC",
      defaultSortOrder: "ASC",
      page: 0,
      perPage: 10,
      columns: [
        {
          field: "tanggal",
          label: "Tanggal",
        },
        {
          field: "ch1",
          label: "VOL (mm)",
        },
      ],
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: false,
      isHoverable: false,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
      isShow: true,
      form: {
        registerId: null,
        waktu: null,
        search: {
          startDate: null,
          endDate: null,
        },
      },
    };
  },
  validations: {
    form: {
      waktu: {
        required,
      },
      search: {
        startDate: {
          required,
        },
        endDate: {
          required,
        },
      },
    },
  },
  created() {
    this.form.registerId = this.$route.params.registerId;
  },
  computed: {
    isFormInvalid() {
      return this.$v.form.$invalid;
    },
    isShowButton() {
      return this.isShow;
    },
    ...mapState({
      searchKanalPch: (state) => state.searchKanalPch.items,
      fileName: (state) => state.generateTelePchExcel.item,
    }),
  },
  methods: {
    ...mapActions("searchKanalPch", ["fetchPchHourly", "fetchPchDaily"]),
    ...mapActions("generateTelePchExcel", [
      "generateHourlyChannel",
      "generateDailyChannel",
      "downloadExcelFile",
    ]),

    cariChannel() {
      if (this.form.waktu === "harian") {
        this.fetchPchDaily(this.form).then(() => {
          this.data = [];
          let currentTotal = this.searchKanalPch.length;
          if (currentTotal / this.perPage > 1000) {
            currentTotal = this.perPage * 1000;
          }
          if (currentTotal > 0) {
            this.isShow = false;
          } else {
            this.isShow = true;
          }
          this.total = currentTotal;
          this.searchKanalPch
            .slice()
            .reverse()
            .forEach((item) => {
              this.isLoaded = false;
              item.tanggal = moment(String(item.tanggal)).format("YYYY-MM-DD");
              this.data.push(item);
            });
        });
      } else if (this.form.waktu === "perjam") {
        this.fetchPchHourly(this.form).then(() => {
          this.data = [];
          let currentTotal = this.searchKanalPch.length;
          if (currentTotal / this.perPage > 1000) {
            currentTotal = this.perPage * 1000;
          }
          if (currentTotal > 0) {
            this.isShow = false;
          } else {
            this.isShow = true;
          }
          this.total = currentTotal;
          this.searchKanalPch
            .slice()
            .reverse()
            .forEach((item) => {
              this.isLoaded = false;
              item.tanggal = moment(String(item.tanggal)).format(
                "YYYY-MM-DD HH:mm:ss"
              );
              this.data.push(item);
            });
        });
      } else {
        this.fetchPchDaily(this.form).then(() => {
          this.data = [];
          let currentTotal = this.searchKanalPch.length;
          if (currentTotal / this.perPage > 1000) {
            currentTotal = this.perPage * 1000;
          }
          this.total = currentTotal;
          this.searchKanalPch
            .slice()
            .reverse()
            .forEach((item) => {
              this.isLoaded = false;
              item.tanggal = moment(String(item.tanggal)).format(
                "YYYY-MM-DD HH:mm:ss"
              );
              this.data.push(item);
            });
        });
      }
    },
    onGenerateExcel() {
      (this.isOpenModal = true), (this.isShow = true);
      if (this.form.waktu === "harian") {
        this.generateDailyChannel(this.form).then(() => {
          let filename = this.fileName.substring(30);
          this.downloadExcelFile(filename);
          this.isOpenModal = false;
        });
      } else if (this.form.waktu === "perjam") {
        this.generateHourlyChannel(this.form).then(() => {
          let filename = this.fileName.substring(30);
          this.downloadExcelFile(filename);
          this.isOpenModal = false;
        });
      }
    },
    /*
     * Handle page-change event
     */
    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },
    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },
  },
};
</script>

<style scoped>
.modal is-active {
  animation: swingOut 1.2s ease-in-out;
}

.modal {
  animation: swingIn 1.2s ease-in-out;
}

@keyframes swingIn {
  0% {
    transform: scaleY(0.005) scaleX(0);
  }
  50% {
    transform: scaleY(0.005) scaleX(1);
  }
  100% {
    transform: scaleY(1) scaleX(1);
  }
}

@keyframes swingOut {
  0% {
    transform: scaleY(1) scaleX(1);
  }
  50% {
    transform: scaleY(0.005) scaleX(1);
  }
  100% {
    transform: scaleY(0.005) scaleX(0);
  }
}

.modal-card-title {
  font-size: 20px;
  font-weight: 600;
  color: #2d6efd;
  letter-spacing: 1px;
}

.form {
  display: flex;
  margin-left: 10px;
  padding-bottom: 10px;
  padding-top: 20px;
}

.periode {
  margin-right: 10px;
}

select {
  max-height: 37px;
  width: 120px;
  margin-top: -1px;
}

.tables {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 16px;
  font-weight: 400;
  border: 1px solid #000;
  padding-bottom: 15px;
}

.table {
  margin-right: 10px;
  margin-left: 10px;
}

.button {
  font-size: 14px;
  font-weight: 400;
  height: 35px;
  width: 150px;
  letter-spacing: 1px;
  margin-left: 10px;
}

.buttons1 {
  height: 35px;
  width: 210px;
}

.kembali i {
  margin-left: 10px;
  margin-right: 10px;
}

i {
  margin-left: 10px;
  margin-right: 10px;
}

.fal {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .modal-card {
    height: 600px;
    width: 350px;
  }
  .button {
    font-size: 14px;
    font-weight: 400;
    height: 35px;
    width: 135px;
    margin-bottom: 5px;
  }
  .form {
    display: flex;
    flex-direction: column;
  }
  .tables {
    border: 0px solid #000;
    padding-bottom: 15px;
  }
}
</style>
