import axiosInstance from "../../conf/axiosAdmin";
// import ApiMethods from '../../conf/config.js';

export default {
  namespaced: true,
  state: {
    items: [],
    item: {},
  },
  getters: {},
  actions: {
    generateHourlyChannelKlim({ state, commit }, payload) {
      commit(
        "setItem",
        { resource: "generateKlimExcel", item: {} },
        { root: true }
      );
      return axiosInstance
        .post(
          `/api/channel/generate/hourly/klm/${payload.registerId}`,
          payload.search
        )
        .then((res) => {
          const fileName = res.data.fileName;
          commit(
            "setItem",
            { resource: "generateKlimExcel", item: fileName },
            { root: true }
          );
          return state.item;
        });
    },
    generateDailyChannelKlim({ state, commit }, payload) {
      commit(
        "setItem",
        { resource: "generateKlimExcel", item: {} },
        { root: true }
      );
      return axiosInstance
        .post(
          `/api/channel/generate/daily/klm/${payload.registerId}`,
          payload.search
        )
        .then((res) => {
          const fileName = res.data.fileName;
          commit(
            "setItem",
            { resource: "generateKlimExcel", item: fileName },
            { root: true }
          );
          return state.item;
        });
    },
    downloadExcelFile({ state, commit }, payload) {
      commit(
        "setItem",
        { resource: "generateKlimExcel", item: {} },
        { root: true }
      );
      return axiosInstance
        .get(`/api/channel/download/${payload}`, {
          method: "get",
          responseType: "arraybuffer",
        })
        .then((res) => {
          const files = res.data;
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", payload);
          document.body.appendChild(link);
          link.click();
          commit(
            "setItem",
            { resource: "generateKlimExcel", item: files },
            { root: true }
          );
          return state.item;
        });
    },
  },
  mutations: {},
};
