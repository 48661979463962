<template>
  <div class="">
    <section>
      <div class="nav">
        <div class="linknav">
          <LinkNavigationBaru />
        </div>
      </div>
    </section>

    <section>
      <div class="informasi">
        <div class="infopos">
          <div class="card-klim">
            <div class="table-info">
              <div class="layer">
                <div class="cards">
                  <span class="title1">Informasi Peta </span>
                </div>
                <div class="field">
                  <div class="control">
                    <div class="lcontrol">
                      <div class="field">
                        <div class="biru"></div>
                        <span>Pos Duga Air</span>
                      </div>

                      <div class="field">
                        <div class="">
                          <div class="hijau"></div>
                        </div>

                        <span>Pos Curah Hujan</span>
                      </div>

                      <div class="field">
                        <div class="sungai"></div>
                        <span>Sungai</span>
                      </div>

                      <div class="field">
                        <div class="merah"></div>
                        <span>Banjir</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="table-klim" :class="{ show: showSidebar }">
              <div
                class="togle button is-focused"
                :class="{ show: showSidebar }"
                @click="showNav"
              >
                <span class="text">&times;</span>
              </div>
              <div
                class="table-data"
                ref="tableWrapper"
                @mousedown="startDrag"
                @mousemove="onDrag"
                @mouseup="endDrag"
                @mouseleave="endDrag"
              >
                <!-- Tabel untuk menampilkan data -->
                <!-- <table border="1"> -->
                <table border="1">
                  <thead>
                    <tr>
                      <th class="sticky-col">
                        WAKTU <i class="fas fa-clock"></i>
                      </th>
                      <th
                        class="tanggal"
                        v-for="(time, index) in tanggal"
                        :key="index"
                      >
                        {{ formatDate(time) }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="channel in channels" :key="channel.channelName">
                      <th class="sticky-col">
                        {{ channel.channelName }} ({{ channel.satuan }})
                      </th>
                      <td
                        v-for="(time, index) in tanggal"
                        :key="index + channel.channelName"
                      >
                        <!-- Cek apakah channel adalah WIND DIRECTION, dan tampilkan ikon arah angin -->
                        <template
                          v-if="channel.channelName === 'WIND DIRECTION'"
                        >
                          <div
                            class="wind-icon"
                            :style="{
                              transform:
                                'rotate(' + windDirection[index] + 'deg)',
                            }"
                          >
                            <img
                              class=""
                              src="@/assets/image/arrow.png"
                              style="max-height: 40px"
                            />
                            <!-- <i class="fa-solid fa-arrow-up"></i> -->
                          </div>
                          {{ channel.value ? channel.value[index] : "-" }}
                        </template>
                        <!-- Jika bukan WIND SPEED, tampilkan nilai seperti biasa -->
                        <template v-else>
                          {{ channel.value ? channel.value[index] : "-" }}
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <l-map
        class="map"
        :zoom="zoom"
        :center="center"
        :options="{ zoomControl: false }"
      >
        <l-control-zoom class="zoom" position="topright"></l-control-zoom>
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>

        <l-geo-json :geojson="wilKer" :options-style="styleWilker" />
        <l-geo-json :geojson="sungaiUtama" :options-style="styleCisanggarung" />

        <l-layer-group>
          <l-circle-marker
            :lat-lng="[-6.9924, 108.481]"
            :radius="circle.radius"
            :color="circle.color"
            :fillColor="circle.fillColor"
            :fillOpacity="circle.fillOpacity"
          >
            <l-tooltip> Pasir Gula </l-tooltip>
            <l-popup>
              <table class="table">
                <tbody>
                  <tr>
                    <td>Nama Pos</td>
                    <td>:</td>
                    <td>Pasir Gula</td>
                  </tr>

                  <tr>
                    <td>Lokasi</td>
                    <td>:</td>
                    <td>Ds. Pasir Gula, Kuningan Jawabarat</td>
                  </tr>
                  <tr>
                    <td>DAS</td>
                    <td>:</td>
                    <td>DAS Cimanuk</td>
                  </tr>
                  <tr>
                    <td>Sungai</td>
                    <td>:</td>
                    <td>Cikasarung</td>
                  </tr>
                </tbody>
              </table>
              <div class="tombol">
                <button
                  class="button lihat"
                  @click="showNav"
                  type="is-success"
                  outlined
                >
                  <i class="fas fa-search"></i>
                  <span class="title2"> Lihat Data </span>
                </button>
              </div>
            </l-popup>
            <!-- </l-marker>   -->
          </l-circle-marker>
          <!-- </l-marker> -->
        </l-layer-group>
      </l-map>
    </section>
  </div>
</template>

<script>
// import { mapActions, mapState } from "vuex";
import {
  LMap,
  LTileLayer,
  LControlZoom,
  LGeoJson,
  LPopup,
  LCircleMarker,
} from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import { icon } from "leaflet";
import LinkNavigationBaru from "../navigation/LinkNavigationBaru.vue";
import axios from "axios";

export default {
  name: "HomeMap",
  components: {
    LMap,
    LTileLayer,
    LControlZoom,
    LGeoJson,
    LPopup,
    LCircleMarker,
    LinkNavigationBaru,
  },

  data() {
    return {
      zoom: 10,
      center: [-6.7942, 108.2799],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors - Unit Hidrologi dan Kualitas Air - BBWS Cimanuk Cisanggarung © 2024',

      markerLatLng: [-6.73003, 108.54273],
      icon: icon({
        iconUrl: require("@/assets/blue-marker-10x10.svg"),
        iconSize: [32, 37],
        iconAnchor: [16, 37],
      }),
      fillColor: "#d63031",
      newLoc: "",
      newLt: 0,
      newLng: 0,
      locations: [
        {
          name: "BBWS Cimanuk-Cisanggarung",
          position: [-6.73003, 108.54273],
        },
      ],
      wilKer: require("@/assets/geojson/administrasi.json"),
      sungaiUtama: require("@/assets/geojson/SUNGAI_CIMANCIS.json"),
      cisanggarung: require("@/assets/geojson/Cisanggarung_UTAMA.json"),
      cimanuk: require("@/assets/geojson/RISKCIMANUK.json"),

      circle: {
        radius: 7,
        color: "#fa8231",
        fillColor: "#fa8231",
        fillOpacity: 50,
      },
      keepFirst: false,
      openOnFocus: false,
      selected: null,
      clearable: false,
      isFetching: false,
      showSidebar: false,
      isDragging: false,
      startX: 0,
      scrollLeft: 0,
      tanggal: [], // Untuk menyimpan array tanggal
      channels: [], // Untuk menyimpan data channel (channelName, satuan, value)
      windDirection: [], // Untuk menyimpan data arah angin dari WIND DIRECTION
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    styleFunction() {
      return () => {
        return {
          weight: 3,
          color: "#2980b9",
          opacity: 1,
          fillColor: "#c23616",
          fillOpacity: 1,
        };
      };
    },
    styleCisanggarung() {
      return () => {
        return {
          weight: 1,
          color: "#0652DD",
          opacity: 1,
          fillColor: "#c23616",
          fillOpacity: 1,
        };
      };
    },
    styleJangkelok() {
      return () => {
        return {
          weight: 3,
          color: "#EA2027",
          opacity: 1,
          fillColor: "#c23616",
          fillOpacity: 1,
        };
      };
    },
    styleWilker() {
      return () => {
        return {
          weight: 3,
          color: "#4b6584",
          opacity: 1,
          fillColor: "#c23616",
          fillOpacity: 1,
        };
      };
    },
  },
  methods: {
    // Fungsi untuk mengambil data dari API
    async fetchData() {
      try {
        const response = await axios.get(
          "http://proto.hkacimancis.info:10700/api/landing/klimatologi/c85f9ee855b94d7a98365b749958da4c"
        );

        // Menyimpan tanggal, channels dan arah angin
        this.tanggal = response.data.tanggal.sort(
          (a, b) => new Date(b) - new Date(a)
        ); // Mengurutkan tanggal dari terbaru ke terlama
        this.channels = response.data.channel.filter(
          (channel) => channel.value !== null
        ); // Mengabaikan channel yang memiliki value null
        const windDirectionChannel = response.data.channel.find(
          (channel) => channel.channelName === "WIND DIRECTION"
        );
        this.windDirection = windDirectionChannel
          ? windDirectionChannel.value
          : [];
      } catch (error) {
        console.error("Terjadi kesalahan saat mengambil data: ", error);
      }
    },
    formatDate(dateString) {
      const options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false, // 24-hour format
      };

      const date = new Date(dateString);
      return date.toLocaleString("id-ID", options); // Format Indonesia
    },

    // getWindDirectionIcon(degree) {
    //   if ((degree >= 0 && degree < 45) || (degree >= 315 && degree < 360)) {
    //     return "fas fa-arrow-up"; // Utara
    //   } else if (degree >= 45 && degree < 135) {
    //     return "fas fa-arrow-right"; // Timur
    //   } else if (degree >= 135 && degree < 225) {
    //     return "fas fa-arrow-down"; // Selatan
    //   } else if (degree >= 225 && degree < 315) {
    //     return "fa-solid fa-down-left-and-up-right-to-center"; // Barat
    //   }
    //   return ""; // Default, tidak ada ikon jika derajat tidak valid
    // },

    showNav() {
      this.showSidebar = !this.showSidebar;
    },
    startDrag(event) {
      this.isDragging = true;
      this.startX = event.clientX;
      this.scrollLeft = this.$refs.tableWrapper.scrollLeft;
    },
    onDrag(event) {
      if (!this.isDragging) return;
      const x = event.clientX;
      const walk = (x - this.startX) * 2; // Adjust drag speed
      this.$refs.tableWrapper.scrollLeft = this.scrollLeft - walk;
    },
    endDrag() {
      this.isDragging = false;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.marker.mapObject.toggleTooltip();
    });
  },
};
</script>
<style scoped>
.wind-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  font-size: 18px;
  transition: transform 0.3s ease-in-out;
}
.map {
  margin-top: 0px;
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.nav {
  position: absolute;
  margin-top: 5px;
  margin-left: 10px;
  z-index: 999;
}

.informasi {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 100%;
  z-index: 9;
  overflow: hidden;
}

.table-info {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.layer {
  position: relative;
  width: 400px;
  height: 70px;
  right: 10px;
  backdrop-filter: blur(5px);
  background: rgba(255, 254, 254, 0.369);
  border: 1px solid rgba(233, 231, 231, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.171);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.109);
  border-radius: 0 0 10px 10px;
}

.lcontrol {
  display: flex;
  justify-content: space-evenly;
}

span {
  font-family: "Roboto", Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.cards {
  background: #212c5f;
  text-align: center;
}

.title1 {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  padding-top: 10px;
  margin-left: 15px;
}

.biru {
  background-color: #2d6efd;
  width: 20px;
  height: 20px;
  margin-top: 5px;
  border-radius: 50px;
}

.hijau {
  background-color: rgb(150, 240, 15);
  width: 20px;
  height: 20px;
  margin-top: 5px;
  border-radius: 50px;
}

.sungai {
  background-color: #2d6efd;
  width: 40px;
  height: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 50px;
}

.merah {
  width: 40px;
  height: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 50px;
  background: #fc0000ff;
}

/* end */

/* star tabel klim */
.table-klim {
  position: relative;
  margin-top: 20px;
  max-width: 100%;
  height: 0px;
  background: #fffefee5;
  backdrop-filter: blur(10px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.109);
  transition: all 1s ease-in-out;
  animation-delay: 1s;
  user-select: none;
}

.table-klim.show {
  position: relative;
  margin-top: 10px;
  max-width: 100%;
  height: 270px;
  background: #fff;
  transition: all 1s ease-in-out;
  animation-delay: 1s;
}

.togle {
  position: absolute;
  left: 20px;
  margin-top: -20px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #212c5f;
  border: 1px solid #d6d5d5;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.109);
  line-height: 40px;
  cursor: pointer;
  transition: all 1s ease-in-out;
  animation-delay: 1s;
  opacity: 0;
  z-index: 99;
}

.togle.show {
  transition: all 1s ease-in-out;
  animation-delay: 1s;
  opacity: 1;
}

.togle:hover {
  background: #2d6efd;
  transform: rotate(360deg);
  transition: all 1s ease-in-out;
  animation-delay: 1s;
}

.text {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 36px;
  font-weight: 600;
}

.table-data {
  position: relative;
  width: 99%;
  height: 270px;
  padding-top: 30px;
  margin-left: 5px;
  white-space: nowrap;
  overflow: scroll;
  cursor: grab;
  /* background: #ed6755; */
}
.table-data:active {
  cursor: grabbing;
}
.table-data::-webkit-scrollbar {
  display: none;
}
.table-data table {
  width: max-content;
  padding-top: 10px;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  overflow-x: scroll;
  border-collapse: collapse;
  cursor: pointer;
}

.table-data table tr {
  border-bottom: 1px solid rgba(128, 128, 128, 0.398);
  padding: 5px;
}
.table-data table td {
  padding: 5px;
  text-align: center;
}

.tanggal {
  width: 150px;
  font-size: 12px;
  padding-top: 5px;
}

.table-data table tr th:first-child {
  position: sticky;
  text-align: left;
  width: 100px;
  left: 0;
  z-index: 2;
  padding: 5px;
  background: #fff;
}
.sticky-col {
  text-align: right;
  width: 100px;
  padding: 5px;
  z-index: 9;
  padding-right: 10px;
  background: #fff;
}
/* .table-data table tr th {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #fff;
} */

button.lihat {
  background-color: #ed6755;
  border: none;
  border-radius: 5px;
  /* width: 50px; */
  padding: 14px;
  font-size: 16px;
  color: white;
  box-shadow: 0px 6px 18px -5px rgba(237, 103, 85, 1);
}

.title2 {
  font-size: 12px;
  font-weight: 500;
  margin-left: 10px;
  padding-top: 2px;
}

@media screen and (max-width: 1368px) {
  .controllayer {
    position: relative;
    margin-left: 970px;
    transition: 0.5s;
  }
}

@media screen and (max-width: 1024px) {
  .nav {
    margin-top: 5px;
    margin-left: 4px;
    z-index: 9;
  }

  .map {
    width: 100%;
    height: 85vh;
    z-index: 1;
  }
}

@media screen and (max-width: 768px) {
  .nav {
    margin-top: 5px;
    margin-left: 4px;
    z-index: 9;
  }
  .map {
    width: 100%;
    height: 85vh;
    z-index: 1;
  }

  .box {
    width: 70%;
  }

  .layer1 {
    position: relative;
    width: 340px;
    height: 75px;
    backdrop-filter: blur(5px);
    background: rgba(255, 254, 254, 0.369);
    border: 1px solid rgba(233, 231, 231, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.171);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.109);
    border-radius: 0 0 10px 10px;
    transition: 0.5s;
  }

  span {
    font-family: "Roboto", Arial, sans-serif;
    font-size: 10px;
    font-weight: 500;
  }

  .biru {
    background-color: #0e6ad3;
    width: 15px;
    height: 15px;
    margin-top: 5px;
    border-radius: 50px;
  }

  .hijau {
    background-color: rgb(150, 240, 15);
    width: 15px;
    height: 15px;
    margin-top: 5px;
    border-radius: 50px;
  }

  .sungai {
    background-color: #2d6efd;
    width: 40px;
    height: 4px;
    margin-top: 10px;
    margin-bottom: 6px;
    border-radius: 50px;
  }

  .merah {
    width: 40px;
    height: 4px;
    margin-top: 10px;
    margin-bottom: 6px;
    border-radius: 50px;
    background: #fc0000ff;
  }

  .header {
    display: flex;
    flex-direction: column;
  }

  .informasi {
    display: none;
  }
}
</style>
