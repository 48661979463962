<template>
  <div class="dashboard-map-wrapper">
    <div class="dashboard-map-wrapper-inner is-reversed">
      <div class="maps">
        <l-map :zoom="zoom" :center="center" @click="onMapClick">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-geo-json :geojson="geojson" :options-style="styleFunction" />
          <l-marker
            v-for="(kualitas, index) in data"
            :lat-lng="[kualitas.lat, kualitas.lng]"
            :key="index"
            :icon="getIcon(kualitas)"
          >
            <l-tooltip>
              <i class="fas fa-water"></i>
              <span>{{ kualitas.sungai }}</span>
            </l-tooltip>
            <l-popup class="popup">
              <table class="table">
                <tbody>
                  <tr>
                    <td>Nama Sungai</td>
                    <td>:</td>
                    <td>{{ kualitas.sungai }}</td>
                  </tr>
                  <tr>
                    <td>Latitude</td>
                    <td>:</td>
                    <td>{{ kualitas.lat }}</td>
                  </tr>
                  <tr>
                    <td>Longitude</td>
                    <td>:</td>
                    <td>{{ kualitas.lng }}</td>
                  </tr>
                  <tr>
                    <td>Periode</td>
                    <td>:</td>
                    <td>{{ periode(kualitas.periode) }}</td>
                  </tr>
                  <tr>
                    <td>Mutu Air</td>
                    <td>:</td>
                    <td>{{ kategori(kualitas.hasilAnalisa) }}</td>
                  </tr>
                  <tr>
                    <td>Index Pencemaran</td>
                    <td>:</td>
                    <td>{{ kualitas.indexPencemaran }}</td>
                  </tr>
                </tbody>
              </table>
            </l-popup>
          </l-marker>
          <l-control class="layer-control" position="bottomleft">
            <div class="">
              <div class="cards">
                <span class="title1">Informasi Cemar </span>
              </div>
              <br />
              <div class="field">
                <div class="control">
                  <div class="biru"></div>

                  <span class="title2">Memenuhi Baku Mutu</span>
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <div class="hijau"></div>

                  <span class="title2">Cemar Ringan</span>
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <div class="card">
                    <div class="kuning"></div>
                  </div>
                  <span class="title2">Cemar Sedang</span>
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <div class="card">
                    <div class="merah"></div>
                  </div>
                  <span class="title2">Cemar Berat</span>
                </div>
              </div>
            </div>
          </l-control>
        </l-map>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
import { LMap, LTileLayer, LMarker, LGeoJson, LControl } from "vue2-leaflet";
import { icon } from "leaflet";
export default {
  name: "KualitasAirMap",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LGeoJson,
    LControl,
  },
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 9,
      center: [-6.7473905, 108.5505455],
      markerLatLng: [-6.73003, 108.54273],
      newLoc: "",
      newLt: 0,
      newLng: 0,
      locations: [
        {
          name: "BBWS Cimanuk",
          position: [-6.73003, 108.54273],
        },
      ],
      icon: null,
      geojson: require("@/assets/geojson/SUNGAI_LN.json"),
      data: [],
    };
  },
  created() {
    this.isLoading = true;
    this.fetchKualitasAir().then((response) => {
      response.data.forEach((item) => {
        this.isLoading = false;
        this.status = item.hasilAnalisa;
        // item.periode =  moment(String(item.periode)).format('YYYY-MM')
        this.data.push(item);
      });
    });
  },
  beforeMount() {
    const authority = localStorage.getItem("authority");
    if (authority === "DINAS") {
      this.$router.push("/dashboard/dinas");
    }
  },
  computed: {
    ...mapState({
      results: (state) => state.kualitas.items,
    }),
    styleFunction() {
      const fillColor = this.fillColor; // important! need touch fillColor in computed for re-calculate when change fillColor
      return () => {
        return {
          // weight: 1,
          color: "#3498db",
          opacity: 1,
          fillColor: fillColor,
          fillOpacity: 1,
        };
      };
    },
  },
  methods: {
    ...mapActions("kualitas", ["fetchKualitasAir", "searchKualitasAir"]),
    onMapClick(e) {
      this.newLt = e.latlng.lat;
      this.newLng = e.latlng.lng;
      this.newLoc =
        "Location on click: " +
        e.latlng.lat.toFixed(6) +
        "," +
        e.latlng.lng.toFixed(6);
    },
    lokasi() {
      this.fetchKualitasAir();
    },
    kategori(value) {
      if (value === "baku_mutu") {
        value = "Memenuhi Baku Mutu";
      } else if (value === "cemar_ringan") {
        value = "Cemar Ringan";
      } else if (value === "cemar_sedang") {
        value = "Cemar Sedang";
      } else if (value === "cemar_ringan") {
        value = "Cemar Berat";
      }
      return value;
    },
    periode(value) {
      return moment(String(value)).format("YYYY-MM");
    },
    getIcon(kualitas) {
      if (kualitas.hasilAnalisa === "baku_mutu") {
        return icon({
          iconUrl: require("../../assets/image/mutu_baku.png"),
          iconSize: [32, 37],
          iconAnchor: [16, 37],
        });
      } else if (kualitas.hasilAnalisa === "cemar_ringan") {
        return icon({
          iconUrl: require("../../assets/image/ringan.png"),
          iconSize: [32, 37],
          iconAnchor: [16, 37],
        });
      } else if (kualitas.hasilAnalisa === "cemar_sedang") {
        return icon({
          iconUrl: require("../../assets/image/sedang.png"),
          iconSize: [32, 37],
          iconAnchor: [16, 37],
        });
      } else if (kualitas.hasilAnalisa === "cemar_berat") {
        return icon({
          iconUrl: require("../../assets/image/berat.png"),
          iconSize: [32, 37],
          iconAnchor: [16, 37],
        });
      }
    },
  },
  mounted() {
    this.newLoc = this.locations[0].name;
    this.newLt = this.locations[0].position[0];
    this.newLng = this.locations[0].position[1];
    this.lokasi();
  },
};
</script>

<style scoped>
.maps {
  width: 100%;
  height: 60vh;
  margin-top: 70px;
  z-index: 1;
}
.fas {
  margin-right: 10px;
  color: #2d6efd;
}
.layer-control {
  position: relative;
  width: 170px;
  height: 155px;
  backdrop-filter: blur(10px);
  background: rgba(255, 255, 255, 0.383);
  border: 1px solid rgba(233, 231, 231, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.171);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.109);
  border-radius: 10px;
  transition: 0, 5s;
}
.cards {
  background: #2d6efd;
}

.control {
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
}

.title1 {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  padding-top: 10px;
  margin-left: 15px;
}

.title2 {
  font-size: 12px;
  font-weight: 500;
  margin-left: 10px;
  padding-top: 2px;
}

.biru {
  width: 20px;
  height: 20px;
  background: #0e6ad3;
  border-radius: 3px;
}

.hijau {
  width: 20px;
  height: 20px;
  background: #20bf6b;
  border-radius: 3px;
}

.kuning {
  width: 20px;
  height: 20px;
  background: #fbd104;
  border-radius: 3px;
}

.merah {
  width: 20px;
  height: 20px;
  background: #fc0000ff;
  border-radius: 3px;
}

@media screen and (max-width: 768px) {
  .layer-control {
    margin-left: 12px;
    margin-bottom: 20px;
  }

  /* .maps
 {
  margin-top: 60px;
  min-width: 100%;
 } */
}
</style>
