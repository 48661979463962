<template>
  <div class="modal-device">
    <div class="buttons">
      <button
        @click="$router.go(-1)"
        class="button h-button is-success is-light"
      >
        <i class="lnir lnir-angle-double-left"></i>
        Kembali
      </button>
      <button
        v-if="status == 'REGISTER'"
        class="button h-button is-danger is-light"
        @click.prevent="unregDevice"
        type="is-success"
        outlined
      >
        Unregister
      </button>
      <button
        v-else
        class="button h-button is-info is-light"
        @click="isOpen = !isOpen"
        type="is-success"
        outlined
      >
        <i class="lnir lnir-save"></i>
        Register
      </button>
    </div>

    <div :class="['modal', { 'is-active': isOpen }]">
      <div class="modal-background"></div>

      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Masukan Data Device</p>
          <!-- TODO: Close Modal set isOpen to false -->
          <button
            @click="isOpen = false"
            class="delete"
            aria-label="close"
          ></button>
        </header>
        <section class="modal-card-body">
          <!-- <FormInputDevice :sn="deviceId" /> -->
          <form>
            <div class="field">
              <div class="control">
                <label class="label">Jenis Device</label>
                <div class="select is-normal">
                  <select
                    v-model="form.jenisDevice"
                    @blur="$v.form.jenisDevice.$touch()"
                    aria-placeholder="Pilih Jenis Pos"
                  >
                    <option value="PDA">PDA</option>
                    <option value="PCH">PCH</option>
                    <option value="KLM">KLM</option>
                  </select>
                </div>
                <div v-if="$v.form.jenisDevice.$error">
                  <span
                    v-if="!$v.form.jenisDevice.required"
                    class="help is-danger"
                    >Jenis Pos Harus Diisi</span
                  >
                </div>
              </div>
            </div>

            <div class="field">
              <div class="control">
                <label class="label">Pilih Pos Kadaster</label>
                <div class="select is-normal">
                  <select
                    v-model="form.registerId"
                    @blur="$v.form.registerId.$touch()"
                    aria-placeholder="Pilih Jenis Pos"
                  >
                    <option
                      v-for="(kadaster, idx) in kadasters"
                      :key="idx"
                      :value="kadaster.registerId"
                    >
                      {{ kadaster.namaPos }}
                    </option>
                  </select>
                </div>
                <div v-if="$v.form.registerId.$error">
                  <span
                    v-if="!$v.form.registerId.required"
                    class="help is-danger"
                    >Pos Harus Diisi</span
                  >
                </div>
              </div>
            </div>

            <div class="field">
              <div class="control">
                <label class="label">Serial Number</label>
                <input
                  @blur="$v.form.serialNumber.$touch()"
                  v-model="form.serialNumber"
                  type="text"
                  class="input"
                  placeholder="Serial Number"
                  disabled
                />
                <div v-if="$v.form.serialNumber.$error">
                  <span
                    v-if="!$v.form.serialNumber.required"
                    class="help is-danger"
                    >Serial Number Harus Diisi</span
                  >
                </div>
              </div>
            </div>
          </form>
        </section>
        <footer class="modal-card-foot">
          <div class="field is-grouped">
            <div class="control btn1">
              <button
                @click.prevent="registerDevice"
                @click="isOpen = false"
                class="button is-normal is-link is-focused"
              >
                <span> Simpan </span>
                <!-- <span> Simpan </span>   -->
              </button>
            </div>

            <div class="control btn">
              <button
                @click="isOpen = false"
                class="button is-normal is-danger is-focused"
              >
                Batal
                <!-- <span> Batal </span> -->
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";

export default {
  // components: {

  // },
  props: {
    deviceId: {
      type: String,
      required: false,
    },
    status: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      isOpen: false,
      form: {
        jenisDevice: null,
        registerId: null,
        serialNumber: this.deviceId,
        status: this.status,
      },
    };
  },
  validations: {
    form: {
      jenisDevice: { required },
      registerId: { required },
      serialNumber: { required },
    },
  },
  computed: {
    isFormInvalid() {
      return this.$v.form.$invalid;
    },
    ...mapState({
      kadasters: (state) => state.kadasters.items,
    }),
  },
  created() {
    this.form.serialNumber = this.$route.params.serialNumber;
    this.getKadasters();
  },
  methods: {
    ...mapActions("kadasters", ["getKadasters"]),
    registerDevice() {
      this.$store
        .dispatch("device/createDevice", this.form)
        .then(() => {
          this.$toasted.success("Register Berhasil", { duration: 5000 });
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 3000 });
        });

      this.isOpen = false;
      this.$router.push("/dashboard/device/");
    },
    unregDevice() {
      this.$swal({
        title: "Perangkat Akan di Lepas?",
        text: "mohon pastikan kembali!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#c0392b",
        confirmButtonText: "YA, UNREGISTER!",
      }).then((result) => {
        // <--
        if (result.value) {
          // <-- if confirmed
          this.$store.dispatch("device/unregisterDevice", this.deviceId);
          this.$router.push("/dashboard/device");
          this.$toasted.success("UnRegister Berhasil", { duration: 5000 });
        }
      });
    },
  },
};
</script>

<style scoped>
.modal {
  animation: swingIn 1.2s ease-in-out;
}

@keyframes swingIn {
  0% {
    transform: scaleY(0.005) scaleX(0);
  }
  50% {
    transform: scaleY(0.005) scaleX(1);
  }
  100% {
    transform: scaleY(1) scaleX(1);
  }
}

.modal-card-title {
  font-size: 20px;
  font-weight: 600;
  color: #2d6efd;
  letter-spacing: 1px;
}
.button {
  font-size: 14px;
  font-weight: 400;
  height: 35px;
  width: 90px;
  letter-spacing: 1px;
  margin-left: 10px;
}

.grup {
  display: flex;
}

.batal {
  color: #fff;
}

span {
  color: #fff;
}

@media (max-width: 768px) {
  .modal-card {
    height: 450px;
    width: 300px;
  }

  .modal-card-title {
    font-size: 18px;
  }

  label {
    font-size: 14px;
  }
  .button {
    font-size: 12px;
    font-weight: 400;
    height: 30px;
    width: 90px;
    margin-bottom: 5px;
  }
}
</style>
