<template>
  <div>
    <SidebarNav />
    <MobileNavbarDash />
    <MobileSidebarDash />
    <SidebarNav />
    <!-- Content Wrapper -->
    <div class="view-wrapper">
      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <!-- Sidebar Trigger -->
          </div>
          <div class="page-content-inner">
            <!--Form Layout 1-->
            <div class="form-layout">
              <div class="form-outer">
                <div class="form-header stuck-header">
                  <div class="form-header-inner">
                    <div class="left">
                      <h3>Form Input User</h3>
                    </div>
                    <div class="right">
                      <div class="buttons">
                        <button
                          @click="$router.go(-1)"
                          class="button h-button is-light is-dark-outlined"
                        >
                          <span class="icon">
                            <i class="lnir lnir-arrow-left rem-100"></i>
                          </span>
                          <span>Batal</span>
                        </button>
                        <button
                          class="button h-button is-primary is-raised"
                          @click.prevent="sendUser"
                          :disabled="isFormInvalid"
                        >
                          Simpan
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-body">
                  <!--Fieldset-->
                  <div class="form-fieldset">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <div class="field">
                          <label>Email</label>
                          <div class="control has-icon">
                            <input
                              @blur="$v.form.email.$touch()"
                              v-model="form.email"
                              type="text"
                              class="input"
                              placeholder="your@mail.address"
                            />

                            <div v-if="$v.form.email.$error">
                              <span
                                v-if="!$v.form.email.required"
                                class="help is-danger"
                                >Email Harus Diisi</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-12">
                        <div class="field">
                          <label>Username</label>
                          <div class="control has-icon">
                            <input
                              @blur="$v.form.username.$touch()"
                              v-model="form.username"
                              type="text"
                              class="input"
                              placeholder="username"
                            />

                            <div v-if="$v.form.username.$error">
                              <span
                                v-if="!$v.form.username.required"
                                class="help is-danger"
                                >Username Harus Diisi</span
                              >
                              <span
                                v-if="!$v.form.username.minLength"
                                class="help is-danger"
                                >Username Min 6 Karakter</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-12">
                        <div class="field">
                          <label>Name</label>
                          <div class="control has-icon">
                            <input
                              @blur="$v.form.name.$touch()"
                              v-model="form.name"
                              type="text"
                              class="input"
                              placeholder="name"
                            />

                            <div v-if="$v.form.name.$error">
                              <span
                                v-if="!$v.form.name.required"
                                class="help is-danger"
                                >Name Harus Diisi</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-fieldset">
                    <div class="columns is-multiline">
                      <div class="column is-6">
                        <div class="field">
                          <label>password</label>

                          <div class="control has-icon">
                            <input
                              @blur="$v.form.password.$touch()"
                              v-model="form.password"
                              :type="show === true ? 'text' : 'password'"
                              class="input"
                              placeholder="xxxx"
                            />
                            <span @click="show = !show" class="form-icon">
                              <i class="fas fa-lock-open"></i>
                            </span>
                            <div v-if="$v.form.password.$error">
                              <span
                                v-if="!$v.form.password.required"
                                class="help is-danger"
                                >Password Harus Diisi</span
                              >
                              <span
                                v-if="!$v.form.password.minLength"
                                class="help is-danger"
                                >Password Min 6 Karatker</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-6">
                        <div class="field">
                          <label>password Confirm</label>

                          <div class="control has-icon">
                            <input
                              @blur="$v.form.confirmPassword.$touch()"
                              v-model="form.confirmPassword"
                              :type="show === true ? 'text' : 'password'"
                              class="input"
                              placeholder="xxxx"
                            />
                            <span @click="show = !show" class="form-icon">
                              <i class="fas fa-lock-open"></i>
                            </span>
                            <div v-if="$v.form.confirmPassword.$error">
                              <span
                                v-if="!$v.form.confirmPassword.required"
                                class="help is-danger"
                                >Password confirmation Harus Diisi</span
                              >
                              <span
                                v-if="
                                  form.confirmPassword &&
                                  !$v.form.confirmPassword.sameAsPassword
                                "
                                class="help is-danger"
                                >Password and Confirm Password should
                                match</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-fieldset">
                    <div class="fieldset-heading">
                      <h4>Informasi Pos</h4>
                      <h6>
                        Apabila Roles user adalah PENGAMAT atau DINAS pastikan
                        dipilih pos terkait
                      </h6>
                    </div>
                    <div class="columns is-multiline">
                      <div class="column is-4">
                        <div class="field">
                          <label class="label">Roles</label>
                          <div class="control">
                            <div class="select is-normal">
                              <select
                                v-model="form.authorityType"
                                type="text"
                                class="input"
                                placeholder="Pilih Roles"
                              >
                                <option disabled>Pilih Roles</option>
                                <option>ADMIN</option>
                                <option>OPERATOR</option>
                                <option>PENGAMAT</option>
                                <option>DINAS</option>
                              </select>
                            </div>
                            <!-- <div v-if="$v.form.roles.$error">
                                                <span v-if="!$v.form.roles.required" class="help is-danger"
                                                  >Roles Harus Diisi</span
                                                >
                                              </div> -->
                          </div>
                        </div>
                      </div>
                      <div
                        class="column is-6"
                        v-if="form.authorityType === 'PENGAMAT'"
                      >
                        <div class="field">
                          <label class="label label-pos">Pos</label>
                          <div class="pengamat-pos">
                            <div class="control is-groupeds">
                              <div class="select is-normal input-pos">
                                <select
                                  class="input pos"
                                  v-model="form.registerId"
                                  aria-placeholder="Pilih Jenis Pos"
                                >
                                  <option
                                    v-for="(kadaster, idx) in kadasters"
                                    :key="idx"
                                    :value="kadaster.registerId"
                                  >
                                    {{ kadaster.namaPos }}
                                  </option>
                                </select>
                              </div>

                              <div class="phone">
                                <label class="label label-phone"
                                  >Phone Number</label
                                >
                                <input
                                  class="input input-phone"
                                  type="number"
                                  placeholder="Isi nomer telphone"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="column is-6"
                        v-if="form.authorityType === 'DINAS'"
                      >
                        <div class="field">
                          <div class="control">
                            <label class="label">Dinas</label>
                            <div class="select is-normal dinas">
                              <select
                                v-model="form.registerId"
                                aria-placeholder="Pilih Jenis Pos"
                              >
                                <option
                                  v-for="(dinas, idx) in dinas"
                                  :key="idx"
                                  :value="dinas.dinasId"
                                >
                                  {{ dinas.dinasPengelola }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!--Fieldset-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email, minLength, sameAs } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";
import SidebarNav from "../dashboardnav/SidebarNav.vue";
import MobileNavbarDash from "../dashboardnav/MobileNavbarDash.vue";
import MobileSidebarDash from "../dashboardnav/MobileSidebarDash.vue";

export default {
  name: "FormInputUser",
  components: {
    SidebarNav,
    MobileNavbarDash,
    MobileSidebarDash,
    // AppSpinner
  },

  data() {
    return {
      show: false,
      sungaiData: [],
      form: {
        email: null,
        username: null,
        name: null,
        password: null,
        confirmPassword: null,
        authorityType: null,
        registerId: null,
      },

      keepFirst: false,
      openOnFocus: false,
      selected: null,
      clearable: false,
      isFetching: false,
    };
  },
  validations: {
    form: {
      username: { required, minLength: minLength(6) },
      name: { required },
      authorityType: { required },
      email: { required, email },
      password: { required, minLength: minLength(6) },
      confirmPassword: { required, sameAsPassword: sameAs("password") },
    },
  },
  created() {
    this.getKadasters();
    this.getDinas();
  },
  computed: {
    ...mapState({
      kadasters: (state) => state.kadasters.items,
      dinas: (state) => state.dinas.items,
    }),
    isFormInvalid() {
      return this.$v.form.$invalid;
    },
  },

  methods: {
    ...mapActions("kadasters", ["getKadasters"]),
    ...mapActions("dinas", ["getDinas"]),
    sendUser() {
      this.$store
        .dispatch("users/createUser", this.form)
        .then(() => {
          // this.$emit("kadasterAddSubmitted", this.form)
          this.$toasted.success("Tambah Data user Berhasil", {
            duration: 5000,
          });
          this.$router.push("/dashboard/users");
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 3000 });
        });
    },
  },
};
</script>

<style scoped>
.label {
  font-size: 13px;
  color: rgba(128, 128, 128, 0.667);
}
.label-pos {
  margin-top: 3px;
}
.input-pos {
  margin-top: -3px;
  margin-left: 30px;
}

.pos {
  width: 130px;
  height: 35px;
}
.dinas {
  margin-top: -2px;
}
.is-groupeds {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.phone {
  margin-top: -25px;
  margin-left: 10px;
}
.phone .input-phone {
  margin-top: -6px;
  width: 200px;
  height: 35px;
}
.label-phone {
  font-size: 13px;
  color: rgba(128, 128, 128, 0.667);
  padding-bottom: 3px;
}
</style>
