<template>
  <div class="modal-kepuasan">
    <div :class="['modal', { 'is-active': buka }]">
      <div class="modal-background"></div>

      <div class="modal-card">
        <!-- <header class="modal-card-head">
           
                   
         <button
            @click="buka = false"
            class="delete"
            aria-label="close"
          ></button>
        </header> -->
        <div class="modal-card-body">
          <section v-show="activeStep === 1">
            <form :class="animation">
              <div class="logo">
                <img src="@/assets/image/logoPu.png" style="max-height: 55px" />
              </div>

              <div class="field">
                <div class="control">
                  <span class="modal-card-title"
                    >Bantu kami meningkatkan layanan</span
                  >
                  <p class="subtitle">
                    Seberapa puaskah Anda dengan layanan yang kami berikan?
                  </p>
                </div>
              </div>

              <div class="field is-grouped is-grouped-centered">
                <div class="kepuasan">
                  <div class="control">
                    <label class="radio">
                      <img
                        class="gambar"
                        src="@/assets/image/sad.png"
                        style="max-height: 40px"
                      />
                      <input
                        v-model="form.score"
                        @blur="$v.form.score.$touch()"
                        type="radio"
                        name="kategori"
                        value="kecewa"
                      />
                      <p class="kategori">Kecewa</p>
                    </label>
                    <label class="radio">
                      <img
                        src="@/assets/image/expressionless.png"
                        style="max-height: 40px"
                      />
                      <input
                        v-model="form.score"
                        @blur="$v.form.score.$touch()"
                        name="kategori"
                        type="radio"
                        value="biasa"
                      />
                      <p class="kategori">Biasa</p>
                    </label>
                    <label class="radio">
                      <img
                        class=""
                        src="@/assets/image/smiling-face.png"
                        style="max-height: 40px"
                      />
                      <input
                        v-model="form.score"
                        @blur="$v.form.score.$touch()"
                        type="radio"
                        name="kategori"
                        value="puas"
                      />
                      <p class="kategori">Puas</p>
                    </label>
                  </div>
                </div>

                <div v-if="$v.form.score.$error" class="form-error">
                  <span v-if="!$v.form.score.required" class="help is-danger"
                    >Reting Harus Diisi</span
                  >
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <label class="label">Nama</label>
                  <div class="control has-icons-left has-icons-right">
                    <input
                      v-model="form.nama"
                      @blur="$v.form.nama.$touch()"
                      class="input"
                      type="text"
                      placeholder="Nama"
                    />
                    <span class="icon is-small is-left">
                      <i class="fal fa-user"></i>
                    </span>
                    <div v-if="$v.form.nama.$error" class="form-error">
                      <span v-if="!$v.form.nama.required" class="help is-danger"
                        >Nama Harus Diisi</span
                      >
                      <span
                        v-if="!$v.form.nama.minLength"
                        class="help is-danger"
                        >Nama minimun 3 karakter</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <label class="label">E-mail</label>
                  <div class="control has-icons-left has-icons-right">
                    <input
                      v-model="form.email"
                      @blur="$v.form.email.$touch()"
                      class="input"
                      type="email"
                      placeholder="Email"
                    />
                    <span class="icon is-small is-left">
                      <i class="far fa-envelope"></i>
                    </span>
                    <div v-if="$v.form.email.$error" class="form-error">
                      <span
                        v-if="!$v.form.email.required"
                        class="help is-danger"
                        >Surel Harus Diisi</span
                      >
                      <span v-if="!$v.form.email.email" class="help is-danger"
                        >Surel Tidak Sesuai</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <label class="label">Pekerjaan</label>
                  <div class="control has-icons-left has-icons-right">
                    <input
                      v-model="form.pekerjaan"
                      @blur="$v.form.pekerjaan.$touch()"
                      class="input"
                      type="text"
                      placeholder="Pekerjaan"
                    />
                    <span class="icon is-small is-left">
                      <i class="fal fa-user-hard-hat"></i>
                    </span>
                    <div v-if="$v.form.pekerjaan.$error" class="form-error">
                      <span
                        v-if="!$v.form.pekerjaan.required"
                        class="help is-danger"
                        >Pekerjaan Harus Diisi</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <label class="label">Instansi atau Perusahaan</label>
                  <div class="control has-icons-left has-icons-right">
                    <input
                      v-model="form.instansi"
                      @blur="$v.form.instansi.$touch()"
                      class="input"
                      type="text"
                      placeholder="Instansi atau Perusahaan"
                    />
                    <span class="icon is-small is-left">
                      <i class="fal fa-briefcase"></i>
                    </span>
                    <div v-if="$v.form.instansi.$error" class="form-error">
                      <span
                        v-if="!$v.form.instansi.required"
                        class="help is-danger"
                        >Instansi atau Perusahaan Harus Diisi</span
                      >
                    </div>
                  </div>
                </div>
              </div>
              <!--  <div class="field">
                <div class="control">
                  <label class="label">captcha Code</label>
                </div>
              </div>
              <div class="field is-grouped">
                <captcha-code
                  :captcha.sync="code"
                  @on-change="handleChange"
                  ref="captcha"
                ></captcha-code>
                <input
                  v-model="capcay"
                  @blur="$v.capcay.$touch()"
                  class="input"
                  type="text"
                  placeholder="captcha Code"
                />
                <vue-hcaptcha sitekey="ec450111-4d5e-4736-801c-e9023cec354a" @verify="onVerify" v-model="form.robot"></vue-hcaptcha> 
              </div> -->

              <!-- <div class="field is-group tombol">
                  <div class="control btn">
                  <button
                    @click="buka = false"
                    class="button is-normal is-danger is-focused"
                  >
                    <span> Batal </span>
                  </button>
                </div>
                <div class="control btn1">
                  <button
                    :disabled="isFormInvalid"
                    @click="buka = false"
                    @click.prevent="sendKepuasan"
                    class="button is-normal is-link is-focused"
                  >
                    <span> Kirim </span>
                  </button>
                  <button
                    class="button is-info is-focused"
                    v-if="activeStep != totalStep"
                    :disabled="isFormInvalid"
                    @click="nextStep"
                  >
                    Selanjut Nya
                  </button>
                </div>
              </div> -->
            </form>
          </section>

          <section class="kuesioner" v-show="activeStep === 2">
            <div class="button-close" @click="closed">
              <div class="tutup">
                <span class="text">&times;</span>
              </div>
            </div>
            <div class="">
              <form :class="animation" class="form">
                <div class="input-fields">
                  <div
                    class="input-container"
                    v-for="(kuesioner, index) in questions"
                    :key="index"
                  >
                    <div class="kuesioner">
                      {{ kuesioner.question }}
                    </div>

                    <!-- Star Type jawaban -->
                    <div class="column is-12">
                      <div class="field">
                        <div class="control">
                          <div class="jawaban">
                            <!--  star type Multiple Choice -->
                            <div
                              v-if="kuesioner.type === 'Radio'"
                              class="type-radio"
                            >
                              <label class="label">Jawaban</label>
                              <div class="field">
                                <div
                                  class="control isi-jawaban"
                                  v-for="(item, index1) in kuesioner.answers"
                                  :key="index1 + kuesioner.question"
                                >
                                  <label class="">
                                    <input
                                      class="inputan"
                                      type="radio"
                                      v-model="form.surveiResults[index]"
                                      :id="index + '_' + index1"
                                      :value="
                                        kuesioner.question + ' : ' + item.answer
                                      "
                                    />
                                    <span class="answer">{{
                                      item.answer
                                    }}</span>
                                  </label>
                                </div>
                              </div>
                            </div>

                            <!-- star type range-->
                            <div
                              v-if="kuesioner.type === 'Range'"
                              class="type-range"
                            >
                              <label class="label">Jawaban</label>
                              <div class="">
                                <div class="reting">
                                  <div
                                    class="control"
                                    v-for="(item, index1) in kuesioner.answers"
                                    :key="index1 + kuesioner.question"
                                  >
                                    <label class="radio">
                                      <input
                                        type="radio"
                                        v-model="form.surveiResults[index]"
                                        :id="index + '_' + index1"
                                        :value="
                                          kuesioner.question +
                                          ' : ' +
                                          item.answer
                                        "
                                      />
                                      <h1 class="reting-number">
                                        {{ item.answer }}
                                      </h1>
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <!-- end type  range -->
                            <!-- star type check box -->
                            <!-- <div
                              v-if="kuesioner.type === 'Checkbox'"
                              class="type-check-box"
                            >
                              <label class="label">Jawaban</label>
                              <div class="field">
                                <div
                                  class="control isi-jawaban"
                                  v-for="(item, index1) in kuesioner.answers"
                                  :key="index1 + kuesioner.question"
                                >
                                  <label class="" :for="answers">
                                    <input
                                      class="inputan"
                                      type="checkbox"
                                      v-model="surveyResult"
                                      :id="index"
                                      :value="
                                        kuesioner.question + ' : ' + item.answer
                                      "
                                    />
                                    <span class="answer">{{
                                      item.answer
                                    }}</span>
                                  </label>
                                </div>
                              </div>
                            </div> -->
                            <!-- end type check box -->

                            <!-- star type  text -->
                            <!-- <div
                              v-if="kuesioner.type === 'Text'"
                              class="type-text"
                            >
                              <b-field>
                                <b-input
                                  placeholder="Masukan Jawaban"
                                  v-model="surveyResult[index]"
                                  :value="kuesioner.question"
                                  maxlength="250"
                                  type="textarea"
                                ></b-input>
                              </b-field>
                            </div> -->

                            <!-- end type  text -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <br />
              </form>
            </div>
          </section>

          <section>
            <div class="field is-group tombol">
              <button
                class="button is-danger is-focused"
                v-if="activeStep == 1"
                @click="closed"
              >
                Batal
              </button>
              <div v-if="survei">
                <button
                  class="button is-info is-focused"
                  v-if="activeStep != totalStep"
                  @click.prevent="nextStep"
                  :disabled="isFormInvalid"
                >
                  Selanjut Nya
                </button>
              </div>

              <div v-else>
                <button
                  class="button is-info is-focused"
                  :disabled="isFormInvalid"
                  @click.prevent="send"
                >
                  kirim
                </button>
              </div>

              <button
                class="button is-info is-focused"
                v-if="activeStep == 2"
                :disabled="isFormInvalid"
                @click.prevent="send"
              >
                Kirim
              </button>
            </div>
          </section>
          <!-- <div>
            <pre>{{ answers | pretty }}</pre>
            <pre>{{ surveyResult }}</pre>
          </div> -->
        </div>

        <!-- <footer class="modal-card-foot">
          
        </footer> -->
      </div>
    </div>
  </div>
</template>

<script>
import { required, email, minLength } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";
// import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
// import CaptchaCode from "vue-captcha-code";
// import ModalKuesioner from "../landing/ModalKuesioner";
export default {
  components: {
    // VueHcaptcha,
    // CaptchaCode,
    // ModalKuesioner,
  },
  props: {
    buka: {
      type: Boolean,
      required: false,
    },
    formId: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      // isOpen: false,
      code: "",
      answers: [],
      surveiResults: [],
      survei: false,
      // radio: "",
      // capcay: null,
      isSesuai: true,
      form: {
        score: null,
        nama: null,
        email: null,
        pekerjaan: null,
        instansi: null,
        surveiResults: [],
        registerId: this.formId.registerId,
        tahun: this.formId.tahun,
      },
      activeSteps: {},
      activeStep: 1,
      totalStep: 2,
      animation: "animate-in",
      slide: "animate-slide",
    };
  },
  validations: {
    form: {
      score: {
        required,
      },
      nama: {
        required,
        minLength: minLength(3),
      },
      email: {
        required,
        email,
      },
      pekerjaan: {
        required,
      },
      instansi: {
        required,
      },
    },
    // capcay: {
    //   required,
    // },
  },
  created() {
    this.fetchLandingQuestion().then((resp) => {
      // console.log(resp.length);
      if (resp.length > 0) {
        this.survei = true;
      }
    });
  },
  computed: {
    ...mapState({
      questions: (state) => state.landingQuestion.items,
    }),
    isFormInvalid() {
      // return this.$v.form.$invalid;
      return this.$v.$invalid;
    },
    // fileName: (state) => state.landingGeneratePdf.item,
  },
  methods: {
    ...mapActions("landingQuestion", ["fetchLandingQuestion"]),
    send() {
      const tahun = this.formId.tahun;
      this.form.tahun = tahun;
      this.$store
        .dispatch("landingKepuasan/createLandingKepuasan", this.form)
        .then(() => this.$emit("onLoad"));
    },
    closed() {
      this.$emit("close");
    },
    // kirim() {
    //   this.answers.push({ ...this.surveyResult });
    // },
    // sendKepuasan() {
    //   if (this.capcay == this.code) {
    //     this.$store.dispatch(
    //       "landingKepuasan/createLandingKepuasan",
    //       this.form
    //     );
    //     then(() => this.$emit("onLoad"));
    //     this.form = {};
    //     this.handleClick();
    //   } else {
    //     this.$toasted.error("Captcha Code Tidak sama", { duration: 3000 });
    //     this.form = {};
    //     this.capcay = null;
    //     this.handleClick();
    //   }
    // },

    nextStep() {
      this.animation = "animate-out";
      setTimeout(() => {
        this.animation = "animate-in";
        this.activeStep += 1;
      }, 600);
    },

    // handleClick() {
    //   this.$refs.captcha.refreshCaptcha();
    // },
    handleChange(code) {
      console.log("code:", code);
    },
  },
};
</script>

<style scoped>
.modal-card {
  width: 650px;
}
.modal-card-head,
.modal-card-body,
.modal-card-foot {
  animation: swing 1.2s ease-in-out;
}

@keyframes swing {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0px);
    transition: all 0.5s;
  }
}

.logo {
  display: block;
  text-align: center;
  padding-bottom: 10px;
}
.modal-card-body {
  border-radius: 10px;
}
.modal-card-title {
  display: block;
  font-size: 18px;
  font-weight: 600;
  color: #2d6efd;
  letter-spacing: 1px;
  text-align: center;
}

.subtitle {
  font-size: 16px;
  font-weight: 600;
  color: #2d6efd;
  letter-spacing: 1px;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 30px;
}

.gambar {
  margin-left: 5px;
}

.pertayaan {
  display: flex;
  flex-direction: column;
  width: 720px;
  background-color: #fff;
}
.form {
  margin-top: 20px;
  margin-left: 20px;
  min-height: 400px;
  height: 100%;
  font-size: 16px;
}

.kepuasan .radio {
  margin-right: 50px;
  cursor: pointer;
}

.kepuasan .radio input[type="radio"] {
  visibility: hidden;
}

.kepuasan .radio input[type="radio"]:checked ~ .kategori {
  color: #2d6efd;
  cursor: pointer;
}

.is-group {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 50px;
}

/*  css question  */
.isi-jawaban {
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  font-weight: 400;
}
.isi-jawaban .inputan {
  position: relative;
  margin-right: 15px;
  margin-bottom: 10px;
  cursor: pointer;
}

.reting {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-left: -15px;
}
.reting .radio .reting-number:hover {
  font-weight: 600;
  background: #2d6efd;
  transition: all 1s;
  color: #fff;
  cursor: pointer;
}
.reting .radio .reting-number {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e0e0e0;
  border: 1px solid #a7a8ac51;
  color: #212c5f;
  font-weight: 500;
  height: 35px;
  width: 35px;
  margin-right: -10px;
  border-radius: 50%;
  transition: all 1s;
  cursor: pointer;
}
.reting .radio input[type="radio"] {
  visibility: hidden;
}
.reting .radio input[type="radio"]:checked ~ .reting-number {
  position: relative;
  font-weight: 700;
  color: #fff;
  border: 1px solid #212c5f51;
  background: #2d6efd;
  transition: all 1s;
  cursor: pointer;
}

.button-close {
  width: 40px;
  height: 40px;
  margin-top: -10px;
  margin-left: 95%;
  border-radius: 50%;
  background: rgba(128, 128, 128, 0.249);
  cursor: pointer;
  transition: all 1s;
}
.button-close:hover {
  background: #039be5;
  transition: all 1s ease-in-out;
  animation-delay: 1s;
}
.tutup {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text {
  margin-top: -5px;
  font-size: 30px;
  font-weight: 700;
  color: #fff;
}
.button {
  font-size: 14px;
  font-weight: 400;
  height: 35px;
  width: 135px;
  letter-spacing: 1px;
  margin-left: 10px;
}

/* star animation slide */
.animate-in {
  transform-origin: left;
  animation: in 1.2s ease;
  overflow: hidden;
}
.animate-out {
  transform-origin: right;
  animation: out 1.2s ease;
  overflow: hidden;
}
.animate-slide {
  transform-origin: left;
  animation: slide 1.2s ease-in-out;
  overflow: hidden;
}

@keyframes in {
  0% {
    transform: translateX(15%);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
    transition: all 1.2s;
  }
}

@keyframes out {
  0% {
    transform: translateX(0px);
    opacity: 1;
  }
  100% {
    transform: translateX(15%);
    opacity: 0;
    transition: all 1.2s;
  }
}

@keyframes slide {
  0% {
    transform: translateX(50%);
    opacity: 0;
    transition: all 1s;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
    transition: all 1.2s;
  }
}

@media (max-width: 768px) {
  .modal-card {
    height: 600px;
    width: 350px;
  }
  .modal-card-title {
    display: block;
    font-size: 16px;
    font-weight: 600;
    color: #2d6efd;
    letter-spacing: 1px;
    text-align: center;
  }
  .subtitle {
    font-size: 14px;
    font-weight: 600;
    color: #2d6efd;
    letter-spacing: 1px;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 30px;
  }
  .button {
    font-size: 14px;
    font-weight: 400;
    height: 35px;
    width: 135px;
    margin-bottom: 5px;
  }
  .kepuasan .radio {
    margin-right: 0px;
    cursor: pointer;
  }
  .is-group {
    display: flex;
    margin-left: 10px;
    padding-top: 30px;
    padding-bottom: 50px;
  }
  .button-close {
    width: 30px;
    height: 30px;
    margin-top: -10px;
    margin-left: 95%;
    border-radius: 50%;
    background: rgba(128, 128, 128, 0.249);
    cursor: pointer;
    transition: all 1s;
  }
  .text {
    margin-top: -4px;
    font-size: 20px;
    font-weight: 700;
    color: #fff;
  }
  .reting {
    margin-left: -17px;
  }
  .reting .radio .reting-number {
    height: 27px;
    width: 27px;
    margin-right: -20px;
    margin-left: -6px;
  }
}
</style>
