<template>
  <div class="modal h-modal is-big" :class="['modal', { 'is-active': isOpen }]">
    <div class="modal-background h-modal-close"></div>
    <div class="modal-content">
      <div class="modal-card">
        <header class="modal-card-head">
          <h3>Informasi Pos Dinas</h3>
          <button class="close" aria-label="close" @click="closed">
            <i class="fas fa-window-close"></i>
          </button>
        </header>
        <div class="modal-card-body" v-if="isLoading">
          <app-spinner />
        </div>
        <div class="modal-card-body" v-else>
          <div class="tabs-wrapper">
            <div class="tabs">
              <ul>
                <li v-bind:class="{ 'is-active': isActive == 'Detail Pos' }">
                  <a v-on:click="isActive = 'Detail Pos'">Detail Pos</a>
                </li>
                <li
                  v-bind:class="{ 'is-active': isActive == 'pdaManual' }"
                  v-if="data.jenisPos == 'PDA'"
                >
                  <a v-on:click="isActive = 'pdaManual'">Data Pos PDA</a>
                </li>
                <li
                  v-bind:class="{ 'is-active': isActive == 'pchManual' }"
                  v-if="data.jenisPos == 'PCH'"
                >
                  <a v-on:click="isActive = 'pchManual'">Data Pos PCH</a>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="tab-content"
            v-bind:class="{ 'is-active': isActive == 'Detail Pos' }"
          >
            <div class="detail">
              <div class="card">
                <section class="columns">
                  <div class="column is-12">
                    <div class="cards scroll-page">
                      <div class="columns">
                        <div class="column is-7 scroll">
                          <div class="header">
                            <h2 class="title is-4 is-narrow">Identitas Pos</h2>
                          </div>

                          <div class="card-content">
                            <div class="content">
                              <table class="table">
                                <tbody>
                                  <tr>
                                    <td>Nama Pos</td>
                                    <td>:</td>
                                    <td>{{ data.namaPos }}</td>
                                  </tr>
                                  <tr>
                                    <td>Lokasi Pos</td>
                                    <td>:</td>
                                    <td>{{ data.lokasiPos }}</td>
                                  </tr>

                                  <tr>
                                    <td>DAS</td>
                                    <td>:</td>
                                    <td>
                                      {{ data.namaDas }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Sungai</td>
                                    <td>:</td>
                                    <td>
                                      {{ data.namaSungai }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Jenis Pos</td>
                                    <td>:</td>
                                    <td>
                                      {{ data.jenisPos }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>Dinas Pengelola</td>
                                    <td>:</td>
                                    <td>
                                      {{ data.dinasPengelola }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="column gambar">
                          <div class="card is-4">
                            <div class="card-image">
                              <figure class="image is-3by2">
                                <img src="../../assets/img/sungai.jpg" alt="" />
                              </figure>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>

          <div
            class="tab-content"
            v-bind:class="{ 'is-active': isActive == 'pdaManual' }"
            v-if="data.jenisPos == 'PDA'"
          >
            <ModalPosDinasPadManual :registerId="data.registerId" />
          </div>
          <div
            class="tab-content"
            v-bind:class="{ 'is-active': isActive == 'pchManual' }"
            v-if="data.jenisPos == 'PCH'"
          >
            <ModalPosDinasPchManual :registerId="data.registerId" />
          </div>
        </div>
        <div class="modal-card-foot is-right">
          <button @click="closed" class="button is-normal is-focused tutup">
            <span>Tutup</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ModalPosDinasPadManual from "../landing/ModalPosDinasPdaManual.vue";
import ModalPosDinasPchManual from "../landing/ModalPosDinasPchManual.vue";
import AppSpinner from "../shared/AppSpinner.vue";
export default {
  name: "ModalPosDinas",
  components: {
    AppSpinner,
    ModalPosDinasPadManual,
    ModalPosDinasPchManual,
  },
  data() {
    return {
      selected: "Detail Pos",
      isActive: "Detail Pos",
      registerId: null,
      data: null,
      isOpen: false,
      isLoading: true,
      PDA: true,
      PCH: true,
    };
  },
  created() {
    this.isLoading = true;
  },
  computed: {
    ...mapState({
      landingPosDinas: (state) => state.landingPosDinas.items,
      dugaAirManual: (state) => state.hidrologiPdaLanding.items,
      curahHujanManual: (state) => state.hidrologiPchLanding.items,
    }),
  },
  methods: {
    ...mapActions("landingPosDinas", ["fetchLandingPosById"]),
    ...mapActions("hidrologiPdaLanding", ["fetchPdaManual"]),
    ...mapActions("hidrologiPchLanding", ["fetchPchManual"]),
    setSelected(tab) {
      this.selected = tab;
    },
    appendData(n) {
      this.fetchLandingPosById(n).then((resp) => {
        console.log(resp);
        this.data = resp;
        this.isLoading = false;
      });
    },

    closed() {
      this.isOpen = false;
      this.isActive = "Detail Pos";
      this.isLoading = true;
    },
  },
  mounted() {
    this.$root.$on("pos-dinas", this.appendData);
    this.$root.$on("pos-dinas", () => {
      if (!this.isOpen) {
        this.isOpen = !this.isOpen;
      }
    });
  },

  beforeDestroy() {
    this.$root.$off("pos-dinas", this.registerId);
  },
};
</script>

<style scoped>
.modal-card-head,
.modal-card-body,
.modal-card-foot {
  animation: swingIn 1.2s ease-in-out;
}

.modal-content {
  width: 100%;
  min-width: 1200px;
}

@keyframes swingIn {
  0% {
    transform: scaleY(0.005) scaleX(0);
  }
  50% {
    transform: scaleY(0.005) scaleX(1);
  }
  100% {
    transform: scaleY(1) scaleX(1);
  }
}

.header {
  margin-left: 30px;
}

.gambar {
  margin: auto;
  margin-right: 10px;
}
button.close {
  width: 30px;
  font-size: 20px;
  color: #c0c5cb;
  align-self: flex-end;
  background-color: transparent;
  border: none;
  margin-bottom: 10px;
}
button.tutup {
  background-color: #ed6755;
  border: none;
  border-radius: 5px;
  /* width: 50px; */
  padding: 14px;
  font-size: 16px;
  color: white;
  box-shadow: 0px 6px 18px -5px rgba(237, 103, 85, 1);
}
.chart {
  height: 400px;
  width: 100%;
}

.fas {
  margin-right: 10px;
  margin-left: -10px;
}

@media (max-width: 768px) {
  .modal-content {
    width: 100%;
    min-width: 350px;
  }
  .chart {
    margin-left: 2px;
  }
  .gambar {
    margin-left: 10px;
  }
}
.banting-kanan {
  float: right;
}
.tombol {
  padding-bottom: 10px;
}
</style>
