<template>
  <div>
    <SidebarNav />
    <MobileNavbarDash />
    <MobileSidebarDash />
    <SidebarNav />
    <!-- Content Wrapper -->
    <div class="view-wrapper">
      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <!-- Sidebar Trigger -->
            <div
              class="huro-hamburger nav-trigger push-resize"
              data-sidebar="layouts-sidebar"
            >
              <span class="menu-toggle has-chevron">
                <span class="icon-box-toggle">
                  <span class="rotate">
                    <i class="icon-line-top"></i>
                    <i class="icon-line-center"></i>
                    <i class="icon-line-bottom"></i>
                  </span>
                </span>
              </span>
            </div>

            <div class="title-wrap">
              <h1 class="title is-4">Data Form Kepuasan</h1>
            </div>
          </div>

          <!-- header star -->
          <div class="page-content-inner">
            <div class="lifestyle-dashboard lifestyle-dashboard-v4">
              <div class="columns">
                <div class="column is-12">
                  <!--Header-->

                  <div class="illustration-header-2">
                    <div class="header-image">
                      <img
                        src="../../assets/img/illustrations/placeholders/search-1.svg"
                        alt=""
                        width="200"
                      />
                    </div>
                    <div class="">
                      <h3>Data Form Kepuasan.</h3>
                      <p>
                        Data Form Kepuasan adalah formulir yang berisi
                        pertanyaan untuk mengumpulkan pendapat dan tanggapan
                        pengguna mengenai informasi web. Form kepuasan juga
                        disebut sebagai form survey kepuasan pengguna...
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <!-- header end -->

          <div v-if="isLoaded" class="flex-list-inner">
            <div class="page-content-inner">
              <div class="table-wrapper">
                <b-table
                  class="table is-datatable is-hoverable table-is-bordered"
                  :data="isEmpty ? [] : data"
                  detailed
                  focusable
                  paginated
                  :total="totalData"
                  :per-page="perPage"
                  @page-change="onPageChange"
                  aria-next-label="Next page"
                  aria-previous-label="Previous page"
                  aria-page-label="Page"
                  aria-current-label="Current page"
                  :default-sort-direction="defaultSortOrder"
                  :default-sort="[sortField, sortOrder]"
                  @sort="onSort"
                  icon-pack="fas"
                  icon-left="chevron-right"
                >
                  <b-table-column field="nama" label="Nama" v-slot="props">
                    {{ props.row.nama }}
                  </b-table-column>

                  <b-table-column field="email" label="Email" v-slot="props">
                    {{ props.row.email }}
                  </b-table-column>

                  <b-table-column
                    field="pekerjaan"
                    label="Pekerjaan"
                    v-slot="props"
                  >
                    {{ props.row.pekerjaan }}
                  </b-table-column>

                  <b-table-column
                    field="instansi"
                    label="Instansi"
                    v-slot="props"
                  >
                    {{ props.row.instansi }}
                  </b-table-column>

                  <b-table-column field="score" label="Score" v-slot="props">
                    {{ props.row.score }}
                  </b-table-column>

                  <template #detail="props">
                    <div class="label-hasil">
                      <strong>Hasil Survei :</strong><br />
                    </div>
                    <div
                      class="data-kepuasan"
                      v-for="(item, index) in props.row.surveiResult"
                      :key="index"
                    >
                      <!-- <pre class="hasil"
                        >{{ item.question }} : {{ item.answer }}</pre
                      > -->
                      <table class="table">
                        <tbody>
                          <tr>
                            <td>
                              {{ item.question }}
                              <span class="td-titik">:</span> {{ item.answer }}
                            </td>
                            <!-- <td width="20%"><span class="td-titik">:</span></td>
                            <td width="30%">{{ item.answer }}</td> -->
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </template>

                  <template #empty>
                    <div class="has-text-centered">
                      Tidak Ada Data Yang Ditampilkan
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
          </div>

          <div v-else class="flex-list-inner spiner">
            <Roket />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SidebarNav from "../dashboardnav/SidebarNav.vue";
import MobileNavbarDash from "../dashboardnav/MobileNavbarDash.vue";
import MobileSidebarDash from "../dashboardnav/MobileSidebarDash.vue";
import Roket from "../shared/Roket.vue";

export default {
  name: "KepuasanList",
  components: {
    SidebarNav,
    MobileNavbarDash,
    MobileSidebarDash,
    Roket,
  },
  data() {
    return {
      isLoaded: false,
      data: [],
      totalData: 0,
      perPage: 10,
      sortField: "created",
      sortOrder: "DESC",
      defaultSortOrder: "DESC",
      page: 0,
      hasDisablePreviousPage: true,
      hasDisableNextPage: false,
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: true,
      isHoverable: false,
      isFocusable: false,
      isLoading: true,
      hasMobileCards: true,
    };
  },
  beforeMount() {
    const authority = localStorage.getItem("authority");
    if (authority === "DINAS") {
      this.$router.push("/dashboard/posdinas");
    }
  },
  created() {
    this.searchKepuasan().then(() => {
      this.isLoaded = true;
    });
  },
  computed: {
    ...mapState({
      kepuasan: (state) => state.kepuasan.items,
    }),
  },
  methods: {
    ...mapActions("kepuasan", ["searchKepuasan"]),
    loadAsyncData() {
      this.searchKepuasan().then((resp) => {
        this.data = [];
        let currentTotal = resp.length;
        if (currentTotal / this.perPage > 1000) {
          currentTotal = this.perPage * 1000;
        }
        this.totalData = currentTotal;
        resp.forEach((item) => {
          this.data.push(item);
        });
      });
    },

    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },

    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },
    increment() {
      this.page += 1;
      this.loadAsyncData();
    },
    decrement() {
      this.page -= 1;
      this.loadAsyncData();
    },
  },
  mounted() {
    this.loadAsyncData();
  },
};
</script>

<style scoped>
h3 {
  font-family: "Segoe UI", sans-serif;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
}
p {
  font-family: "Segoe UI", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
}

.spiner {
  padding-top: 80px;
}
.label-hasil {
  margin-bottom: 10px;
}
strong {
  font-size: 14px;
  font-weight: 700;
}
.data-kepuasan {
  margin-bottom: 5px;
}
.hasil {
  font-size: 14px;
  font-weight: 600;
  color: gray;
}
.table {
  border-collapse: collapse;
}
.table tr {
  border-bottom: 1px solid rgba(128, 128, 128, 0.663);
}
.td-titik {
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 700;
}

@media (max-width: 768px) {
}
</style>
