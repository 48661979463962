<template>
  <nav
    class="navbar mobile-navbar no-shadow is-hidden-desktop is-hidden-tablet"
    aria-label="main navigation"
  >
    <div class="container">
      <!-- Brand -->
      <div class="navbar-brand">
        <!-- Mobile menu toggler icon -->
        <div class="brand-start" @click="onActive">
          <div class="navbar-burger" :class="{ 'is-active': isActive }">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>

        <!-- <a class="navbar-item is-brand">
                <img class="light-image" src="../../assets/logoPu.png" alt="">
                <img class="dark-image" src="../../assets/logoPu.png" alt="">
            </a> -->

        <div class="brand-end">
          <div
            class="dropdown is-right is-spaced dropdown-trigger user-dropdown"
          >
            <div class="is-trigger" aria-haspopup="true">
              <div class="profile-avatar">
                <router-link class="text" to="/">
                  <img
                    class="light-image"
                    src="../../assets/logoPu.png"
                    alt=""
                  />
                </router-link>

                <!-- <img class="avatar" src="https://via.placeholder.com/150x150" alt=""> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "MobileNavbarDash",
  components: {},
  data() {
    return {
      isActive: "",
    };
  },
  methods: {
    onActive() {
      this.isActive = this.isActive ? "" : "is-active";
      this.$root.$emit("mobile-navbar", this.isActive);
    },
  },
};
</script>
