<template>
  <div class="page-content-wrapper">
    <div class="page-content-inner">
      <div class="datatable-toolbar">
        <div class="buttons">
          <InputVolume :registeredId="regId" @submitted="submitted" />
        </div>
      </div>
      <div class="flex-list-wrapper flex-list-v1">
        <div class="flex-table">
          <!--Table header-->
          <div class="flex-table-header">
            <span>Tanggal</span>
            <span>Volume</span>
            <span>Actions</span>
          </div>

          <div v-if="isLoaded" class="flex-list-inner">
            <!--Table item-->
            <div
              v-for="(evap, index) in evaporasi"
              :key="index"
              class="flex-table-item"
            >
              <div class="flex-table-cell" data-th="Tanggal">
                <span class="light-text">
                  {{ formatDate(evap.created).date }}
                </span>
              </div>
              <div class="flex-table-cell" data-th="volume">
                <span class="light-text">
                  {{ evap.volume }}
                </span>
              </div>

              <div class="flex-table-cell">
                <UpdateVolume
                  :volumeId="evap.id"
                  :evapVolume="evap.volume"
                  @updatedSubmit="updateVolum"
                />
              </div>
            </div>
          </div>
          <div v-else class="flex-list-inner">
            <AppSpinner />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import InputVolume from "./InputVolume.vue";
import UpdateVolume from "./UpdateVolume.vue";
import AppSpinner from "../shared/AppSpinner.vue";
export default {
  name: "TabelEvaporation",
  components: {
    AppSpinner,
    InputVolume,
    UpdateVolume,
  },
  props: {
    regId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoaded: false,
    };
  },
  created() {
    this.fetchEvaporasiByRegisterId(this.regId).then(() => {
      this.isLoaded = true;
    });
  },
  computed: {
    ...mapState({
      evaporasi: (state) => state.evaporasi.item,
    }),
  },
  methods: {
    ...mapActions("evaporasi", ["fetchEvaporasiByRegisterId"]),
    loadAsyncData() {
      this.fetchEvaporasiByRegisterId(this.regId).then(() => {
        this.isLoaded = true;
      });
    },
    updateVolum() {
      this.loadAsyncData();
    },

    submitted() {
      this.loadAsyncData();
    },
    // formatDate(dateString) {
    //   const date = new Date(dateString);
    //   return date.toLocaleString("id-ID", {
    //     // Format date to Indonesian locale
    //     weekday: "long", // Display the day of the week
    //     year: "numeric",
    //     month: "long",
    //     day: "numeric",
    //     hour: "numeric",
    //     minute: "numeric",
    //     second: "numeric",
    //   });
    // },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      const hours = ("0" + date.getHours()).slice(-2);
      const minutes = ("0" + date.getMinutes()).slice(-2);
      const seconds = ("0" + date.getSeconds()).slice(-2);

      const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;

      return { date: formattedDate };
    },
  },

  mounted() {
    this.loadAsyncData();
  },
};
</script>
