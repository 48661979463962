<template>
  <div class="klimatologi">
    <div class="datatable-toolbar">
      <div class="buttons">
        <SearchKlimatologi :registerId="regId" />
      </div>
    </div>
    <div class="card">
      <div v-if="isLoaded" class="table-data">
        <table>
          <thead>
            <tr>
              <th class="sticky-col">WAKTU <i class="fas fa-clock"></i></th>
              <th v-for="(channel, index) in channels" :key="index">
                {{ channel.channelName }} ({{ channel.satuan }})
              </th>
            </tr>
          </thead>

          <tbody>
            <tr v-for="(data, index) in paginatedData" :key="index">
              <td class="sticky-col">{{ formatDate(data.tanggal).date }}</td>
              <!-- <td class="sticky-col">{{ formatDate(data.tanggal).time }}</td> -->
              <td
                class="data-index"
                v-for="(channel, channelIndex) in channels"
                :key="channelIndex"
              >
                <template v-if="data[`ch${channelIndex + 1}`] !== null">
                  <!-- {{ data[`ch${channelIndex + 1}`] }} -->
                </template>
                <!-- <template v-if="channel.channelName === 'WIND DIRECTION'">
                  <div>
                    {{
                      data[`ch${channelIndex + 1}`] !== null
                        ? data[`ch${channelIndex + 1}`]
                        : "-"
                    }}
                  </div>
                  <div
                    class="arah-agin"
                    v-if="data[`ch${channelIndex + 1}`] !== null"
                  >
                    <div
                      class="wind-icon"
                      :style="{
                        transform: 'rotate(' + windDirection[index] + 'deg)',
                      }"
                    >
                      <img
                        src="@/assets/image/Red_Arrow.png"
                        style="max-height: 40px"
                      />
                    </div>
                    <div class="wind-description">
                      {{ getWindDirectionDescription(windDirection[index]) }}
                    </div>
                  </div>
                </template> -->
                <template>
                  {{
                    data[`ch${channelIndex + 1}`] !== null
                      ? data[`ch${channelIndex + 1}`]
                      : "-"
                  }}
                </template>
                <!-- <template v-if="channel.channelName === 'HUMIDITY'">
                  <div class="humidity-description">
                    {{
                      data[`ch${channelIndex + 1}`] !== null
                        ? getHumidityDescription(data[`ch${channelIndex + 1}`])
                        : ""
                    }}
                  </div>
                </template> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-else class="flex-list-inner spiner">
        <Roket />
      </div>

      <!-- Pagination Controls -->
      <div class="pagination">
        <button
          @click="prevPage"
          :disabled="currentPage === 1"
          class="page-button rounded"
        >
          <i class="fas fa-chevron-left"></i> Previous
        </button>

        <!-- Page Numbers with Ellipses -->
        <button v-if="currentPage > 3" @click="goToPage(1)" class="page-button">
          1
        </button>
        <span v-if="currentPage > 3" class="pagination-ellipsis">&hellip;</span>

        <button
          v-for="page in visiblePages"
          :key="page"
          @click="goToPage(page)"
          :class="['page-button', { active: page === currentPage }]"
        >
          {{ page }}
        </button>

        <span v-if="currentPage < totalPages - 2" class="pagination-ellipsis"
          >&hellip;</span
        >

        <button
          v-if="currentPage < totalPages - 2"
          @click="goToPage(totalPages)"
          class="page-button"
        >
          {{ totalPages }}
        </button>

        <button
          @click="nextPage"
          :disabled="currentPage === totalPages"
          class="page-button"
        >
          Next <i class="fas fa-chevron-right"></i>
        </button>
      </div>

      <!-- end pagination -->

      <br />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Roket from "../shared/Roket.vue";
import SearchKlimatologi from "../pos/SearchKlimatologi.vue";
let sseClient;

export default {
  name: "ModalTableKlimDash",
  components: {
    Roket,
    SearchKlimatologi,
  },
  props: {
    regId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoaded: false,
      channelData: [],
      channels: [],
      // windDirection: [],
      currentPage: 1,
      pageSize: 10,
    };
  },
  created() {
    this.fetchDataKlimDash(this.regId).then(() => {
      this.isLoaded = true;
      this.loadAsyncData();
    });
    this.loadSubcribe();
  },
  computed: {
    ...mapState({
      klimatologi: (state) => state.klimatologi.items,
    }),
    paginatedData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.channelData.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.channelData.length / this.pageSize);
    },
    visiblePages() {
      // Calculate the range of pages to display (maximum 5 buttons)
      let start = Math.max(1, this.currentPage - 2); // Start from two pages before current
      let end = Math.min(this.totalPages, start + 4); // End 4 pages after the start

      // Adjust if near the beginning or end of pagination
      if (this.currentPage <= 3) {
        start = 1;
        end = Math.min(5, this.totalPages);
      } else if (this.currentPage + 2 >= this.totalPages) {
        start = Math.max(1, this.totalPages - 4);
        end = this.totalPages;
      }

      // Return the array of visible pages
      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    },
  },
  methods: {
    ...mapActions("klimatologi", ["fetchDataKlimDash"]),
    loadAsyncData() {
      this.fetchDataKlimDash(this.regId)
        .then((klimData) => {
          this.isLoading = false;
          this.channels = klimData.header;
          this.channelData = klimData.channel.map((data) => {
            return {
              tanggal: data.tanggal,
              ...data,
            };
          });
          this.channelData.sort(
            (a, b) => new Date(b.tanggal) - new Date(a.tanggal)
          );
          this.windDirection = this.channelData.map((data) => data.ch2 || 0);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          this.isLoading = false;
        });
    },

    loadSubcribe() {
      sseClient = this.$sse.create({
        url: "/socket/klim/subscribe",
        format: "json",
        withCredentials: false,
        polyfill: true,
      });

      // Catch any errors (e.g., lost connections, etc.)
      sseClient.on("error", (e) => {
        console.error("Lost connection or failed to parse!", e);
      });

      // Handle 'channel' messages (new real-time data)
      sseClient.on("channel", (msg) => {
        // Assuming we only care about the current registered serial number.
        // Update the channelData array with the new message
        if (msg.registerId === this.regId) {
          this.updateChannelData(msg);
        }
        
      });

      sseClient
        .connect()
        .then(() => {
          console.log("Data Realtime connected!");
        })
        .catch((err) => {
          console.error("Failed to connect to server", err);
        });
    },

    updateChannelData(newData) {
      // Get current time and the time of the incoming message
      const currentTime = new Date();
      const messageTime = new Date(newData.tanggal);
      const timeDifference = currentTime - messageTime; // in milliseconds

      // Only process the data if it's been less than 5 minutes since the message arrived
      if (timeDifference <= 300000) {
        // 300000ms = 5 minutes
        // Find if there's existing data with the same timestamp
        const existingDataIndex = this.channelData.findIndex(
          (data) => data.tanggal === newData.tanggal
        );

        if (existingDataIndex !== -1) {
          // If data with the same timestamp exists, update it
          this.$set(this.channelData, existingDataIndex, {
            tanggal: newData.tanggal,
            ...newData,
          });
        } else {
          // If no existing data with the same timestamp, add the new data
          this.channelData.unshift({
            tanggal: newData.tanggal,
            ...newData,
          });
        }

        // Sort the data by date to maintain the most recent at the top
        this.channelData.sort(
          (a, b) => new Date(b.tanggal) - new Date(a.tanggal)
        );
      }
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      const hours = ("0" + date.getHours()).slice(-2);
      const minutes = ("0" + date.getMinutes()).slice(-2);
      const seconds = ("0" + date.getSeconds()).slice(-2);

      const formattedDate = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
      //  const formattedTime = `${hours}:${minutes}:${seconds}`;
      // return { date: formattedDate, time: formattedTime };
      return { date: formattedDate };
    },

    // getWindDirectionDescription(degrees) {
    //   if (degrees >= 0 && degrees < 22.5) {
    //     return "Utara";
    //   } else if (degrees >= 22.5 && degrees < 67.5) {
    //     return "Timur Laut";
    //   } else if (degrees >= 67.5 && degrees < 112.5) {
    //     return "Timur";
    //   } else if (degrees >= 112.5 && degrees < 157.5) {
    //     return "Tenggara";
    //   } else if (degrees >= 157.5 && degrees < 202.5) {
    //     return "Selatan";
    //   } else if (degrees >= 202.5 && degrees < 247.5) {
    //     return "Barat Daya";
    //   } else if (degrees >= 247.5 && degrees < 292.5) {
    //     return "Barat";
    //   } else if (degrees >= 292.5 && degrees < 337.5) {
    //     return "Barat Laut";
    //   } else {
    //     return "Utara";
    //   }
    // },
    // getHumidityDescription(humidity) {
    //   if (humidity >= 0 && humidity < 30) {
    //     return "Kering";
    //   } else if (humidity >= 30 && humidity < 60) {
    //     return "Normal";
    //   } else if (humidity >= 60 && humidity < 80) {
    //     return "Lembab";
    //   } else {
    //     return "Sangat Lembab";
    //   }
    // },

    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    goToPage(page) {
      if (page !== this.currentPage) {
        this.currentPage = page;
      }
    },
  },
  mounted() {
    this.loadAsyncData();
  },
  beforeDestroy() {
    sseClient.disconnect();
  },
};
</script>

<style scoped>
.arah-agin {
  display: flex;
  justify-content: center;
  align-items: center;
}
.wind-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  font-size: 18px;
  transition: transform 0.3s ease-in-out;
}
.humidity-description {
  font-size: 14px;
  text-align: center;
}

.table-data {
  position: relative;
  width: 100%;
  height: auto;
  overflow: auto;
  user-select: none;
}

.table-wrapper {
  width: 100%;
  overflow-x: auto; /* Horizontal scroll for the table */
}

.table-data table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}

.table-data table th,
.table-data table td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: center;
}

.table-data table th {
  background-color: #f2f2f2;
}

.sticky-col {
  position: sticky;
  left: 0;
  background: #fff;
  z-index: 1;
  text-align: left;
}

.pagination {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-button {
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  border: none;
  border-radius: 7px;
  background-color: #f2f2f2;
  transition: background-color 0.5s ease-in-out;
}

.page-button:hover {
  background-color: #007bff;
  color: #fff;
  transition: background-color 0.5s ease-in-out;
}

.page-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.page-button.active {
  background-color: #007bff;
  color: white;
  transition: 0.5s ease-in-out;
}

.pagination button i {
  margin-right: 5px;
}
</style>
