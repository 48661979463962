import { render, staticRenderFns } from "./UpdateVolume.vue?vue&type=template&id=377d5ccd&scoped=true&"
import script from "./UpdateVolume.vue?vue&type=script&lang=js&"
export * from "./UpdateVolume.vue?vue&type=script&lang=js&"
import style0 from "./UpdateVolume.vue?vue&type=style&index=0&id=377d5ccd&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "377d5ccd",
  null
  
)

export default component.exports