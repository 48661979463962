<template>
  <div class="modal-channel">
    <button
      class="button h-button is-primary is-elevated"
      @click="isOpen = !isOpen"
      type="is-success"
      outlined
    >
      <i class="fas fa-search"></i><span>Cari Data</span>
    </button>

    <div :class="['modal', { 'is-active': isOpen }]">
      <div class="modal-background"></div>

      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Pencarian Data Klimatologi</p>
          <!-- TODO: Close Modal set isOpen to false -->
          <button
            @click="isOpen = false"
            class="delete"
            aria-label="close"
          ></button>
        </header>
        <section class="modal-card-body">
          <form>
            <div class="form">
              <div class="field">
                <div class="control">
                  <label class="label">Periode Awal</label>
                  <div class="periode">
                    <date-picker
                      class="periode"
                      @blur="$v.form.search.startDate.$touch()"
                      v-model="form.search.startDate"
                      format="YYYY-MM-DD"
                      type="date"
                      placeholder="Select date"
                      valueType="YYYY-MM-DD"
                    ></date-picker>
                    <div v-if="$v.form.search.startDate.$error">
                      <span
                        v-if="!$v.form.search.startDate.required"
                        class="help is-danger"
                        >Periode Awal Harus Diisi</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <label class="label">Periode Akhir</label>
                  <div class="periode">
                    <date-picker
                      class="periode"
                      @blur="$v.form.search.endDate.$touch()"
                      v-model="form.search.endDate"
                      format="YYYY-MM-DD"
                      type="date"
                      placeholder="Select date"
                      valueType="YYYY-MM-DD"
                    ></date-picker>
                    <div v-if="$v.form.search.endDate.$error">
                      <span
                        v-if="!$v.form.search.endDate.required"
                        class="help is-danger"
                        >Periode Akhir Harus Diisi</span
                      >
                    </div>
                  </div>
                </div>
              </div>

              <div class="field">
                <div class="control">
                  <label class="label">Pilih Waktu</label>
                  <div class="select is-link">
                    <select
                      class="select"
                      @blur="$v.form.waktu.$touch()"
                      v-model="form.waktu"
                      aria-placeholder="pilih Satuan Waktu"
                    >
                      <!-- <option>Per 5 menit</option> -->
                      <option value="perjam">
                        <span class="option"> Perjam</span>
                      </option>
                      <option value="harian">
                        <span class="option"> Harian </span>
                      </option>
                    </select>
                    <div v-if="$v.form.waktu.$error">
                      <span
                        v-if="!$v.form.waktu.required"
                        class="help is-danger"
                        >Waktu Harus Diisi</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="is-grup">
              <div class="field">
                <div class="control tombol">
                  <button
                    :disabled="isFormInvalid"
                    class="button is-normal is-link is-focused buttons1"
                    @click.prevent="cariChannel"
                  >
                    <span class="titles">
                      <i class="fas fa-search"></i>
                      Lihat Data
                    </span>
                  </button>
                </div>
              </div>
            </div>
            <br />

            <ModalLoading :buka="isOpenModal" />
            <div>
              <section
                class="table-data"
                ref="tableWrapper"
                @mousedown="startDrag"
                @mousemove="onDrag"
                @mouseup="endDrag"
                @mouseleave="endDrag"
              >
                <table>
                  <thead>
                    <tr>
                      <th class="sticky-col">
                        WAKTU <i class="fas fa-clock"></i>
                      </th>
                      <th v-for="(channel, index) in channels" :key="index">
                        {{ channel.channelName }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(data, index) in paginatedData" :key="index">
                      <!-- <td class="sticky-col">{{ formatDate(data.tanggal) }}</td>                       -->
                      <td class="sticky-col">{{ tgl[index] }}</td>
                      <td
                        v-for="(channel, channelIndex) in channels"
                        :key="channelIndex"
                      >
                        <template v-if="data[`ch${channelIndex + 1}`] !== null">
                          <!-- {{ data[`ch${channelIndex + 1}`] }} -->
                        </template>
                        <!-- <template
                          v-if="channel.channelName === 'WIND DIRECTION'"
                        >
                          <div
                            class="wind-icon"
                            :style="{
                              transform:
                                'rotate(' + windDirection[index] + 'deg)',
                            }"
                          >
                            <img
                              src="@/assets/image/arrow.png"
                              style="max-height: 40px"
                            />
                          </div>
                          {{
                            data[`ch${channelIndex + 1}`] !== null
                              ? data[`ch${channelIndex + 1}`]
                              : "-"
                          }}
                        </template> -->
                        <template>
                          {{
                            data[`ch${channelIndex + 1}`] !== null
                              ? data[`ch${channelIndex + 1}`]
                              : "-"
                          }}
                        </template>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </section>
            </div>
          </form>
          <!-- Pagination Controls -->
          <div class="pagination">
            <button
              @click="prevPage"
              :disabled="currentPage === 1"
              class="page-button rounded"
            >
              <i class="fas fa-chevron-left"></i> Previous
            </button>

            <!-- Page Numbers with Ellipses -->
            <button
              v-if="currentPage > 3"
              @click="goToPage(1)"
              class="page-button"
            >
              1
            </button>
            <span v-if="currentPage > 3" class="pagination-ellipsis"
              >&hellip;</span
            >

            <button
              v-for="page in visiblePages"
              :key="page"
              @click="goToPage(page)"
              :class="['page-button', { active: page === currentPage }]"
            >
              {{ page }}
            </button>

            <span
              v-if="currentPage < totalPages - 2"
              class="pagination-ellipsis"
              >&hellip;</span
            >

            <button
              v-if="currentPage < totalPages - 2"
              @click="goToPage(totalPages)"
              class="page-button"
            >
              {{ totalPages }}
            </button>

            <button
              @click="nextPage"
              :disabled="currentPage === totalPages"
              class="page-button"
            >
              Next <i class="fas fa-chevron-right"></i>
            </button>
          </div>

          <!-- end pagination -->
        </section>

        <footer class="modal-card-foot">
          <div class="field is-grouped">
            <div class="control btn1">
              <button
                class="button h-button is-primary is-light"
                :disabled="channelData.length === 0 || isShow"
                @click.prevent="onGenerateExcel"
              >
                <i class="fas fa-file-excel"></i>
                Unduh Excel
                <i class="fas fa-angle-down"></i>
              </button>
            </div>

            <div class="control btn">
              <button
                @click="isOpen = false"
                class="button h-button is-warning is-light"
              >
                <span> Batal </span>
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import ModalLoading from "../shared/ModalLoading.vue";

export default {
  name: "SearchTelemetryPda",
  components: {
    DatePicker,
    ModalLoading,
  },
  props: {
    registerId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isOpenModal: false,
      isOpen: false,
      isDragging: false,
      startX: 0,
      scrollLeft: 0,
      channelData: [],
      channels: [],
      //  windDirection: [],
      tgl: [],
      currentPage: 1,
      pageSize: 10,
      isShow: true,
      form: {
        registerId: null,
        waktu: null,
        search: {
          startDate: null,
          endDate: null,
        },
      },
    };
  },
  validations: {
    form: {
      waktu: {
        required,
      },
      search: {
        startDate: {
          required,
        },
        endDate: {
          required,
        },
      },
    },
  },
  created() {
    this.form.registerId = this.$route.params.registerId;
  },
  computed: {
    paginatedData() {
      const start = (this.currentPage - 1) * this.pageSize;
      const end = this.currentPage * this.pageSize;
      return this.channelData.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.channelData.length / this.pageSize);
    },
    visiblePages() {
      // Calculate the range of pages to display (maximum 5 buttons)
      let start = Math.max(1, this.currentPage - 2); // Start from two pages before current
      let end = Math.min(this.totalPages, start + 4); // End 4 pages after the start

      // Adjust if near the beginning or end of pagination
      if (this.currentPage <= 3) {
        start = 1;
        end = Math.min(5, this.totalPages);
      } else if (this.currentPage + 2 >= this.totalPages) {
        start = Math.max(1, this.totalPages - 4);
        end = this.totalPages;
      }

      // Return the array of visible pages
      return Array.from({ length: end - start + 1 }, (_, i) => start + i);
    },
    isFormInvalid() {
      return this.$v.form.$invalid;
    },
    isShowButton() {
      return this.isShow;
    },
    ...mapState({
      searchChannelKlim: (state) => state.searchChannelKlim.items,
      fileName: (state) => state.generateKlimExcel.item,
    }),
  },
  methods: {
    ...mapActions("searchChannelKlim", [
      "fetchChannelKlimHourly",
      "fetchChannelKlimDaily",
    ]),
    ...mapActions("generateKlimExcel", [
      "generateHourlyChannelKlim",
      "generateDailyChannelKlim",
      "downloadExcelFile",
    ]),
    cariChannel() {
      if (this.form.waktu === "harian") {
        this.fetchChannelKlimDaily(this.form)
          .then((klimData) => {
            this.channels = klimData.header;
            this.channelData = klimData.channel.map((data) => {
              return {
                tanggal: data.tanggal,
                ...data,
              };
            });
            this.channelData.sort(
              (a, b) => new Date(b.tanggal) - new Date(a.tanggal)
            );
            this.tgl = this.channelData.map((data) => data.tanggal);
            this.windDirection = this.channelData.map((data) => data.ch2 || 0);
            this.isShow = false;
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } else if (this.form.waktu === "perjam") {
        this.fetchChannelKlimHourly(this.form)
          .then((klimData) => {
            this.channels = klimData.header;
            this.channelData = klimData.channel.map((data) => {
              return {
                tanggal: this.formatDate(data.tanggal),
                ...data,
              };
            });
            this.channelData.sort(
              (a, b) => new Date(b.tanggal) - new Date(a.tanggal)
            );
            this.tgl = this.channelData.map((data) =>
              this.formatDate(data.tanggal)
            );
            this.windDirection = this.channelData.map((data) => data.ch2 || 0);
            this.isShow = false;
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      } else {
        this.fetchChannelKlimDaily(this.form)
          .then((klimData) => {
            this.channels = klimData.header;
            this.channelData = klimData.channel.map((data) => {
              return {
                tanggal: data.tanggal,
                ...data,
              };
            });
            this.channelData.sort(
              (a, b) => new Date(b.tanggal) - new Date(a.tanggal)
            );
            this.tgl = this.channelData.map((data) => data.tanggal);
            this.windDirection = this.channelData.map((data) => data.ch2 || 0);
            this.isShow = false;
          })
          .catch((error) => {
            console.error("Error fetching data:", error);
          });
      }
    },
    onGenerateExcel() {
      this.isOpenModal = true;
      this.isShow = true;
      if (this.form.waktu === "harian") {
        this.generateDailyChannelKlim(this.form).then(() => {
          let filename = this.fileName.substring(30);
          this.downloadExcelFile(filename);
          this.isOpenModal = false;
        });
      } else if (this.form.waktu === "perjam") {
        this.generateHourlyChannelKlim(this.form).then(() => {
          let filename = this.fileName.substring(30);
          this.downloadExcelFile(filename);
          this.isOpenModal = false;
        });
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      const hours = ("0" + date.getHours()).slice(-2);
      const minutes = ("0" + date.getMinutes()).slice(-2);
      const seconds = ("0" + date.getSeconds()).slice(-2);
      return `${day}-${month}-${year}, ${hours}:${minutes}:${seconds}`;
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    goToPage(page) {
      if (page !== this.currentPage) {
        this.currentPage = page;
      }
    },
    startDrag(event) {
      this.isDragging = true;
      this.startX = event.clientX;
      this.scrollLeft = this.$refs.tableWrapper.scrollLeft;
    },
    onDrag(event) {
      if (!this.isDragging) return;
      const x = event.clientX;
      const walk = (x - this.startX) * 2; // Adjust drag speed
      this.$refs.tableWrapper.scrollLeft = this.scrollLeft - walk;
    },
    endDrag() {
      this.isDragging = false;
    },
  },
};
</script>

<style scoped>
.modal is-active {
  animation: swingOut 1.2s ease-in-out;
}

.modal {
  animation: swingIn 1.2s ease-in-out;
}

@keyframes swingIn {
  0% {
    transform: scaleY(0.005) scaleX(0);
  }
  50% {
    transform: scaleY(0.005) scaleX(1);
  }
  100% {
    transform: scaleY(1) scaleX(1);
  }
}

@keyframes swingOut {
  0% {
    transform: scaleY(1) scaleX(1);
  }
  50% {
    transform: scaleY(0.005) scaleX(1);
  }
  100% {
    transform: scaleY(0.005) scaleX(0);
  }
}

.modal-card-title {
  font-size: 20px;
  font-weight: 600;
  color: #2d6efd;
  letter-spacing: 1px;
}

.form {
  display: flex;
  margin-left: 10px;
  padding-bottom: 10px;
  padding-top: 20px;
}

.periode {
  margin-right: 10px;
}

select {
  max-height: 37px;
  width: 120px;
  margin-top: -1px;
}

.wind-icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  font-size: 18px;
  transition: transform 0.3s ease-in-out;
}

.table-data {
  position: relative;
  width: 100%;
  height: auto;
  overflow: auto;
  user-select: none;
  cursor: grab;
}

.table-data table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}

.table-data table th,
.table-data table td {
  padding: 10px;
  border: 1px solid #ccc;
}

.table-data table th {
  background-color: #f2f2f2;
}

.sticky-col {
  position: sticky;
  left: 0;
  background: #fff;
  z-index: 1;
  text-align: left;
}

.pagination {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-button {
  padding: 5px 10px;
  margin: 0 5px;
  cursor: pointer;
  border: none;
  border-radius: 7px;
  background-color: #f2f2f2;
  transition: background-color 0.5s ease-in-out;
}

.page-button:hover {
  background-color: #007bff;
  color: #fff;
  transition: background-color 0.5s ease-in-out;
}

.page-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.page-button.active {
  background-color: #007bff;
  color: white;
  transition: 0.5s ease-in-out;
}

.pagination button i {
  margin-right: 5px;
}

.button {
  font-size: 14px;
  font-weight: 400;
  height: 35px;
  width: 150px;
  letter-spacing: 1px;
  margin-left: 10px;
}

.buttons1 {
  height: 35px;
  width: 210px;
}

.kembali i {
  margin-left: 10px;
  margin-right: 10px;
}

i {
  margin-left: 10px;
  margin-right: 10px;
}

.fal {
  margin-right: 10px;
}

@media (max-width: 768px) {
  .modal-card {
    height: 600px;
    width: 350px;
  }
  .button {
    font-size: 14px;
    font-weight: 400;
    height: 35px;
    width: 135px;
    margin-bottom: 5px;
  }
  .form {
    display: flex;
    flex-direction: column;
  }
  .tables {
    border: 0px solid #000;
    padding-bottom: 15px;
  }
}
</style>
