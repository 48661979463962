<template>
  <div class="business-dashboard company-dashboard ecommerce-dashboard-v1">
    <div class="columns is-multiline">
      <div class="column is-6">
        <div class="stats-wrapper">
          <div class="columns is-multiline is-flex-tablet-p">
            <div class="column is-6">
              <div class="dashboard-card">
                <div class="media-flex-center">
                  <div class="h-icon is-info is-rounded">
                    <i class="fad fa-bolt"></i>
                  </div>
                  <div class="flex-meta">
                    <span>{{ getVoltPch }} V</span>
                    <span>Volatase</span>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="column is-6">
              <div class="dashboard-card">
                <div class="media-flex-center">
                  <div class="h-icon is-purple is-rounded">
                    <i class="fas fa-thermometer-quarter"></i>
                  </div>
                  <div class="flex-meta">
                    <span>{{ getSuhuPch }} °C</span>
                    <span>Suhu</span>
                  </div>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="datatable-toolbar">
      <div class="buttons">
        <search-telemetry-pch :registerId="regId" />
      </div>
    </div>

    <div class="columns is-multiline">
      <div class="column is-12">
        <div class="dashboard-card is-base-chart">
          <div class="content-box">
            <div class="revenue-stats is-dark-bordered-12">
              Curah Hujan (mm)
            </div>
          </div>
          <div class="chart-container">
            <v-chart autoresize class="chart" :option="arr" />
          </div>
        </div>
      </div>

      <div class="column is-12">
        <!-- Datatable -->
        <div class="table-wrapper">
          <b-table
            class="table is-datatable is-hoverable table-is-bordered"
            :data="channelList"
            :columns="columns"
            focusable
            paginated
            :total="total"
            :per-page="perPage"
            @page-change="onPageChange"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            :default-sort-direction="defaultSortOrder"
            :default-sort="[sortField, sortOrder]"
            @sort="onSort"
            icon-pack="fas"
            icon-left="chevron-right"
          >
          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let sseClient;
import { mapActions, mapState } from "vuex";
import SearchTelemetryPch from "./SearchTelemetryPch.vue";
import VChart from "vue-echarts";
export default {
  name: "TablePchTelemetry",
  components: {
    SearchTelemetryPch,
    VChart,
  },

  props: {
    regId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      sn: null,
      page: 0,
      perPage: 10,
      total: 0,
      columns: [
        {
          field: "tanggal",
          label: "Tanggal",
        },
        {
          field: "serialNumber",
          label: "Serial Number",
        },
        {
          field: "ch1",
          label: "Volume (mm)",
        },
      ],
      sortField: "tanggal",
      sortOrder: "ASC",
      defaultSortOrder: "ASC",
      hasDisablePreviousPage: true,
      hasDisableNextPage: false,
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: true,
      isHoverable: false,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
      arr: {},
    };
  },
  created() {
    this.fetchPch(this.regId).then(() => {
      this.kanalPch.slice().forEach((item) => {
        this.sn = item.serialNumber;
      });
    });
    this.loadSubcribe();
  },
  computed: {
    ...mapState({
      kanalPch: (state) => state.kanalPch.items,
    }),
    channelList() {
      return this.kanalPch;
    },
    getVoltPch() {
      return this.kanalPch
        .slice(0, 1)
        .map((r) => r.ch3)
        .join(", ");
    },
    getSuhuPch() {
      return this.kanalPch
        .slice(0, 1)
        .map((r) => r.ch2)
        .join(", ");
    },
  },
  methods: {
    ...mapActions("kanalPch", ["fetchPch", "addPch"]),
    setSelected(tab) {
      this.selected = tab;
    },

    loadSubcribe() {
      sseClient = this.$sse.create({
        url: "/socket/pch/subscribe",
        format: "json",
        withCredentials: false,
        polyfill: true,
      });

      // Catch any errors (ie. lost connections, etc.)
      sseClient.on("error", (e) => {
        console.error("lost connection or failed to parse!", e);

        // If this error is due to an unexpected disconnection, EventSource will
        // automatically attempt to reconnect indefinitely. You will _not_ need to
        // re-add your handlers.
      });

      // Handle messages without a specific event
      sseClient.on("message", this.message);

      // Handle 'chat' messages
      sseClient.on("pch", (msg) => {
        // console.log(msg)
        // console.log(this.sn)
        if (msg.serialNumber === this.sn) {
          this.$store.dispatch("kanalPch/addPch", msg);
          const colors = ["#5470C6", "#91CC75", "#EE6666"];
          this.arr = {
            color: colors,
            title: {
              left: "1%",
            },
            legend: {},
            tooltip: {
              trigger: "axis",
              axisPointer: {
                type: "cross",
              },
            },
            dataZoom: [
              {
                type: "inside",
              },
              {
                type: "slider",
              },
            ],
            xAxis: {
              type: "category",
              axisTick: {
                alignWithLabel: true,
              },
              data: this.kanalPch
                .slice()
                .reverse()
                .map((r) => r.tanggal),
            },
            yAxis: {
              type: "value",
              name: "Volume",
              position: "right",
              alignTicks: true,
              axisLine: {
                show: true,
                lineStyle: {
                  color: colors[0],
                },
              },
              axisLabel: {
                formatter: "{value} mm",
              },
            },
            series: [
              {
                data: this.kanalPch
                  .slice()
                  .reverse()
                  .map((r) => r.ch1),
                type: "bar",
              },
            ],
          };
        }
      });

      sseClient
        .connect()
        .then((sse) => {
          console.log("Data Realtime connected!");

          // Unsubscribes from event-less messages after 7 seconds
          setTimeout(() => {
            sseClient.off("message", this.message);
            // console.log("Stopped listening to event-less messages!");
          }, 7000);

          // Unsubscribes from chat messages after 14 seconds
          setTimeout(() => {
            sse.off("channel", this.data);
            // console.log("Stopped listening to Pda Monitoring messages!");
          }, 14000);
        })
        .catch((err) => {
          // When this error is caught, it means the initial connection to the
          // events server failed.  No automatic attempts to reconnect will be made.
          console.error("Failed to connect to server", err);
        });
    },
    // channnel table
    loadAsyncData() {
      this.isLoading = true;

      this.fetchPch(this.regId).then(() => {
        let currentTotal = this.kanalPch.length;
        if (currentTotal / this.perPage > 1000) {
          currentTotal = this.perPage * 1000;
        }
        this.total = currentTotal;

        const colors = ["#5470C6", "#91CC75", "#EE6666"];
        this.arr = {
          color: colors,
          title: {
            left: "1%",
          },
          legend: {},
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
            },
          },
          dataZoom: [
            {
              type: "inside",
            },
            {
              type: "slider",
            },
          ],
          xAxis: {
            type: "category",
            axisTick: {
              alignWithLabel: true,
            },
            data: this.kanalPch
              .slice()
              .reverse()
              .map((r) => r.tanggal),
          },
          yAxis: {
            type: "value",
            name: "Volume",
            position: "right",
            alignTicks: true,
            axisLine: {
              show: true,
              lineStyle: {
                color: colors[0],
              },
            },
            axisLabel: {
              formatter: "{value} mm",
            },
          },
          series: [
            {
              data: this.kanalPch
                .slice()
                .reverse()
                .map((r) => r.ch1),
              type: "bar",
            },
          ],
        };
      });
      // END PDA
    },

    onPageChange(page) {
      this.page = page;
      this.loadAsyncData();
    },

    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },
    increment() {
      this.page += 1;
      this.loadAsyncData();
    },
    decrement() {
      this.page -= 1;
      this.loadAsyncData();
    },
  },
  beforeDestroy() {
    // Make sure to close the connection with the events server
    // when the component is destroyed, or we'll have ghost connections!
    sseClient.disconnect();

    // Alternatively, we could have added the `sse: { cleanup: true }` option to our component,
    // and the SSEManager would have automatically disconnected during beforeDestroy.
  },
  mounted() {
    this.loadAsyncData();
  },
};
</script>

<style scoped>
.chart {
  height: 450px;
  width: 100%;
}

@media (max-width: 868px) {
  .chart {
    height: 200px;
    width: 360px;
  }
}
</style>
