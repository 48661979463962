<template>
  <div>
    <!-- <MobileNavbar />
    <MobileSidebarMain />
    <Sidebar /> -->

    <section class="heads">
      <LinkNavigationBaru />
    </section>

    <div
      id="grid-tiles"
      class="view-wrapper"
      data-naver-offset="214"
      data-menu-item="#layouts-sidebar-menu"
      data-mobile-item="#home-sidebar-menu-mobile"
    >
      <!-- Content Wrapper -->
      <div class="view-wrapper">
        <div class="page-content-wrapper">
          <div class="page-content is-relative">
            <div class="page-title has-text-centered">
              <!-- Sidebar Trigger -->

              <div class="">
                <h1 class="title">Pencarian Data Telemetry Duga Air</h1>
              </div>
            </div>

            <section>
              <div>
                <span>
                  <h4 class="text">{{ kdstr.namaPos }}</h4>
                </span>
              </div>
            </section>
            <section>
              <div>
                <span>
                  <h4 class="text1">{{ kdstr.lokasiPos }}</h4>
                </span>
              </div>
            </section>
            <br />

            <!-- START -->
            <div class="page-content-inner">
              <!--Business Dashboard V1-->
              <div class="business-dashboard flights-dashboard">
                <div class="columns">
                  <div class="column">
                    <!--Booking bar-->
                    <div class="booking-bar-wrapper is-info">
                      <img
                        class="travel-illustration light-image image"
                        src="../../assets/pdf-icon-2616.png"
                        alt=""
                      />

                      <div class="booking-bar">
                        <div class="booking-bar-inputs">
                          <div class="control has-icon">
                            <p class="label">Periode Awal</p>
                            <date-picker
                              class="periode"
                              @blur="$v.form.search.startDate.$touch()"
                              v-model="form.search.startDate"
                              format="YYYY-MM-DD"
                              type="date"
                              placeholder="Select date"
                              valueType="YYYY-MM-DD"
                            ></date-picker>
                            <div v-if="$v.form.search.startDate.$error">
                              <span
                                v-if="!$v.form.search.startDate.required"
                                class="help is-danger"
                                >Periode Awal Harus Diisi</span
                              >
                            </div>
                          </div>

                          <div class="control has-icon">
                            <p class="label">Periode Akhir</p>
                            <date-picker
                              class="periode"
                              @blur="$v.form.search.endDate.$touch()"
                              v-model="form.search.endDate"
                              format="YYYY-MM-DD"
                              type="date"
                              placeholder="Select date"
                              valueType="YYYY-MM-DD"
                            ></date-picker>
                            <div v-if="$v.form.search.endDate.$error">
                              <span
                                v-if="!$v.form.search.endDate.required"
                                class="help is-danger"
                                >Periode Akhir Harus Diisi</span
                              >
                            </div>
                          </div>
                          <div class="control has-icon">
                            <p class="label">Pilih Waktu</p>
                            <div class="select">
                              <select
                                @blur="$v.form.waktu.$touch()"
                                v-model="form.waktu"
                                aria-placeholder="pilih Satuan Waktu"
                              >
                                <!-- <option>Per 5 menit</option> -->
                                <option value="perjam">
                                  <span class="option"> Perjam</span>
                                </option>
                                <option value="harian">
                                  <span class="option"> Harian </span>
                                </option>
                              </select>
                            </div>

                            <div v-if="$v.form.waktu.$error">
                              <span
                                v-if="!$v.form.waktu.required"
                                class="help is-danger"
                                >Waktu Harus Diisi</span
                              >
                            </div>
                          </div>
                          <div class="control has-icon tombol">
                            <button
                              :disabled="isFormInvalid"
                              class="button h-button is-success is-light"
                              @click.prevent="cariChannel"
                            >
                              <span class="titles">
                                <i class="fas fa-search"></i>
                                Cari Data
                              </span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <ModalInputKepuasan
                      :buka="isOpenKepuasan"
                      @onLoad="onLoading"
                      @close="close"
                    />
                    <ModalLoading :buka="isOpen" />

                    <div class="flights">
                      <div class="table-wrapper">
                        <section>
                          <b-table
                            class="table is-datatable is-hoverable table-is-bordered"
                            :data="data"
                            :columns="columns"
                            paginated
                            backend-pagination
                            :total="total"
                            :per-page="perPage"
                            @page-change="onPageChange"
                            aria-next-label="Next page"
                            aria-previous-label="Previous page"
                            aria-page-label="Page"
                            aria-current-label="Current page"
                            backend-sorting
                            :default-sort-direction="defaultSortOrder"
                            :default-sort="[sortField, sortOrder]"
                            @sort="onSort"
                            icon-pack="fas"
                            icon-left="chevron-right"
                            :bordered="isBordered"
                            :striped="isStriped"
                            :narrowed="isNarrowed"
                            :hoverable="isHoverable"
                            :loading="isLoading"
                            :focusable="isFocusable"
                            :mobile-cards="hasMobileCards"
                          >
                            <b-table-column
                              field="tanggal"
                              label="Tanggal"
                              v-slot="props"
                            >
                              {{ props.row.tanggal }}
                            </b-table-column>

                            <b-table-column
                              field="ch1"
                              label="TMA (cm)"
                              v-slot="props"
                            >
                              {{ props.row.ch1 }}
                            </b-table-column>
                            <template #empty>
                              <div class="has-text-centered">
                                Tidak Ada Data Yang Ditampilkan
                              </div>
                            </template>
                          </b-table>
                        </section>
                      </div>
                    </div>
                    <div class="buttons is-right">
                      <button
                        :disabled="isShow"
                        class="button is-danger is-focused"
                        @click.prevent="onGeneratePdf"
                      >
                        <span class="titles">
                          <i class="fas fa-file-pdf"></i>
                          Unduh PDF
                          <i class="fas fa-angle-down"></i>
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- END -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import ModalLoading from "./ModalLoading";
import ModalInputKepuasan from "./ModalInputKepuasan";

// import Sidebar from "../navigation/Sidebar.vue";
// import MobileNavbar from "../navigation/MobileNavbar.vue";
// import MobileSidebarMain from "../navigation/MobileSidebarMain.vue";
import LinkNavigationBaru from "../navigation/LinkNavigationBaru.vue";

export default {
  name: "TelemetryPdaSearchData",
  components: {
    // Sidebar,
    // MobileNavbar,
    // MobileSidebarMain,
    LinkNavigationBaru,
    DatePicker,
    ModalLoading,
    ModalInputKepuasan,
  },

  data() {
    return {
      data: [],
      isOpen: false,
      isOpenKepuasan: false,
      total: 0,
      loading: false,
      sortField: "created",
      sortOrder: "ASC",
      defaultSortOrder: "ASC",
      page: 0,
      perPage: 10,
      columns: [
        {
          field: "tanggal",
          label: "Tanggal",
        },
        {
          field: "ch1",
          label: "TMA (cm)",
        },
      ],
      kdstr: {
        elevasi: null,
        gambar: null,
        kodeRegister: null,
        lat: null,
        lng: null,
        lokasiPos: null,
        namaPos: null,
        isManualPch: true,
        isManualPda: true,
        isTelemetryPch: true,
        isTelemetryPda: true,
        sungai: {
          namaSungai: null,
        },
      },
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: false,
      isHoverable: false,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
      isShow: true,
      form: {
        registerId: null,
        waktu: null,
        search: {
          startDate: null,
          endDate: null,
        },
      },
    };
  },
  validations: {
    form: {
      waktu: {
        required,
      },
      search: {
        startDate: {
          required,
        },
        endDate: {
          required,
        },
      },
    },
  },
  created() {
    this.form.registerId = this.$route.params.registerId;
    const regId = this.$route.params.registerId;
    this.fetchLandingKadasterById(regId).then((resp) => {
      this.kdstr = resp;
      this.registerId = regId;
    });
  },
  computed: {
    isFormInvalid() {
      return this.$v.form.$invalid;
    },
    isShowButton() {
      return this.isShow;
    },
    ...mapState({
      landingChannel: (state) => state.landingChannel.items,
      pdaGeneratePdf: (state) => state.pdaGeneratePdf.item,
      kadaster: (state) => state.landingKadaster.item,
    }),
  },
  methods: {
    ...mapActions("landingChannel", [
      "fetchLandingDailyChannel",
      "fetchLandingHourlyChannel",
    ]),
    ...mapActions("pdaGeneratePdf", [
      "generatePdaHourlyPdf",
      "generatePdaDailyPdf",
      "downloadPdfFile",
    ]),
    ...mapActions("landingKadaster", [
      "fetchLandingKadasterById",
      "getLandingKadasterFile",
    ]),

    cariChannel() {
      if (this.form.waktu === "harian") {
        this.fetchLandingDailyChannel(this.form).then(() => {
          this.data = [];
          let currentTotal = this.landingChannel.length;
          if (currentTotal / this.perPage > 1000) {
            currentTotal = this.perPage * 1000;
          }
          if (currentTotal > 0) {
            this.isShow = false;
          } else {
            this.isShow = true;
          }
          this.total = currentTotal;
          this.landingChannel
            .slice()
            .reverse()
            .forEach((item) => {
              this.isLoaded = false;
              item.tanggal = moment(String(item.tanggal)).format("YYYY-MM-DD");
              this.data.push(item);
            });
        });
      } else if (this.form.waktu === "perjam") {
        this.fetchLandingHourlyChannel(this.form).then(() => {
          this.data = [];
          let currentTotal = this.landingChannel.length;
          if (currentTotal / this.perPage > 1000) {
            currentTotal = this.perPage * 1000;
          }
          if (currentTotal > 0) {
            this.isShow = false;
          } else {
            this.isShow = true;
          }
          this.total = currentTotal;
          this.landingChannel
            .slice()
            .reverse()
            .forEach((item) => {
              this.isLoaded = false;
              item.tanggal = moment(String(item.tanggal)).format(
                "YYYY-MM-DD HH:mm:ss"
              );
              this.data.push(item);
            });
        });
      } else {
        this.fetchLandingDailyChannel(this.form).then(() => {
          this.data = [];
          let currentTotal = this.landingChannel.length;
          if (currentTotal / this.perPage > 1000) {
            currentTotal = this.perPage * 1000;
          }
          this.total = currentTotal;
          this.landingChannel
            .slice()
            .reverse()
            .forEach((item) => {
              this.isLoaded = false;
              item.tanggal = moment(String(item.tanggal)).format("YYYY-MM-DD");
              this.data.push(item);
            });
        });
      }
    },
    onGeneratePdf() {
      this.isOpenKepuasan = true;
      // this.isOpen = true
      this.isShow = true;
    },
    onLoading() {
      this.isOpenKepuasan = false;
      this.isOpen = true;
      this.isShow = true;
      if (this.form.waktu === "harian") {
        this.generatePdaDailyPdf(this.form).then(() => {
          let filename = this.pdaGeneratePdf;
          this.downloadPdfFile(filename);
          setTimeout(() => (this.isOpen = false), 2000);
        });
      } else if (this.form.waktu === "perjam") {
        this.generatePdaHourlyPdf(this.form).then(() => {
          let filename = this.pdaGeneratePdf;
          this.downloadPdfFile(filename);
          setTimeout(() => (this.isOpen = false), 2000);
        });
      }
    },
    close() {
      this.isOpenKepuasan = false;
    },

    /*
     * Handle page-change event
     */
    onPageChange(page) {
      this.page = page;
    },
    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
    },
  },
};
</script>

<style scoped>
.heads {
  padding-top: 10px;
  margin-left: 20px;
}

.image {
  position: relative;
  height: 160px;
  width: 160px;
}

.fa-angle-down {
  margin-left: 10px;
}

.fa-file-pdf {
  margin-bottom: 3px;
}

.fas {
  margin-right: 10px;
}

.content-title {
  font-size: 14px;
  font-weight: 500;
}
.titles {
  font-size: 14px;
  font-weight: 400;
  /* color: #fff; */
}

.text {
  font-size: 22px;
  font-weight: 500;
}

.text1 {
  font-size: 18px;
  font-weight: 500;
}

.tombol {
  margin-top: 25px;
}

@media (max-width: 768px) {
  .button {
    margin-top: 20px;
  }
  .image {
    margin-left: -20px;
    height: 125px;
    width: 125px;
  }
  .title {
    font-size: 22px;
  }
  .text1 {
    font-size: 16px;
    font-weight: 500;
    margin-left: 10px;
    margin-top: -10px;
  }
  .text {
    font-size: 20px;
    font-weight: 500;
    color: black;
  }
}
</style>
