<template>
  <div>
    <div class="buttons">
      <button
        class="button h-button is-primary is-elevated"
        @click="isOpen = !isOpen"
        type="is-success"
        outlined
      >
        <i class="fal fa-plus"></i><span class="text">Input Volume</span>
      </button>
    </div>

    <div :class="['modal', { 'is-active': isOpen }]">
      <div class="modal-background"></div>

      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Masukan Data Volume</p>
          <!-- TODO: Close Modal set isOpen to false -->
          <button
            @click="isOpen = false"
            class="delete"
            aria-label="close"
          ></button>
        </header>
        <section class="modal-card-body">
          <form>
            <div class="field">
              <div class="control">
                <label class="label is-normal">Volume</label>
                <input
                  @blur="$v.form.volume.$touch()"
                  v-model="form.volume"
                  type="number"
                  class="input"
                  placeholder="Masukan volume"
                />
                <div v-if="$v.form.volume.$error">
                  <span v-if="!$v.form.volume.required" class="help is-danger"
                    >volume Harus Diisi</span
                  >
                </div>
              </div>
            </div>
          </form>
        </section>
        <footer class="modal-card-foot">
          <div class="field is-grouped">
            <div class="control btn1">
              <button
                @click.prevent="saveVolume"
                @click="isOpen = false"
                :disabled="isFormInvalid"
                class="button is-normal is-link is-focused"
              >
                <span> Simpan </span>
              </button>
            </div>

            <div class="control btn">
              <button
                @click="isOpen = false"
                class="button is-normal is-danger is-focused"
              >
                <span> Batal </span>
              </button>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";

export default {
  components: {},
  props: {
    registeredId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isOpen: false,
      form: {
        registerId: this.registeredId,
        volume: null,
      },
    };
  },
  validations: {
    form: {
      volume: {
        required,
      },
    },
  },
  computed: {
    isFormInvalid() {
      return this.$v.form.$invalid;
    },
  },
  methods: {
    saveVolume() {
      this.$store
        .dispatch("evaporasi/createVolumeEvaporasi", this.form)
        .then(() => {
          this.$emit("submitted", this.form);
          this.$toasted.success("Input Data Volume Berhasil", {
            duration: 5000,
          });
        })
        .catch((error) =>
          this.$toasted.error(error.response.data.details, { duration: 5000 })
        );
      this.isOpen = false;
    },
  },
};
</script>

<style scoped>
.modal {
  animation: swingIn 1.2s ease-in-out;
}

@keyframes swingIn {
  0% {
    transform: scaleY(0.005) scaleX(0);
  }
  50% {
    transform: scaleY(0.005) scaleX(1);
  }
  100% {
    transform: scaleY(1) scaleX(1);
  }
}

.modal-card-title {
  font-size: 20px;
  font-weight: 600;
  color: #2d6efd;
  letter-spacing: 1px;
}
.buttons .button {
  width: 140px;
  margin-right: 20px;
}
.buttons .text {
  letter-spacing: 1px;
}
i {
  margin-right: 10px;
  font-size: 14px;
  font-weight: 500;
}
</style>
