<template>
  <div>
    <SidebarNav />
    <MobileNavbarDash />
    <MobileSidebarDash />
    <SidebarNav />
    <!-- Content Wrapper -->
    <div class="view-wrapper">
      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <!-- Sidebar Trigger -->
          </div>
          <div class="page-content-inner">
            <!--Form Layout 1-->
            <div class="form-layout">
              <div class="form-outer">
                <div class="form-header stuck-header">
                  <div class="form-header-inner">
                    <div class="left">
                      <h3>Form Input Pos</h3>
                    </div>
                    <div class="right">
                      <div class="buttons">
                        <button
                          @click="$router.go(-1)"
                          class="button h-button is-light is-dark-outlined"
                        >
                          <span class="icon">
                            <i class="lnir lnir-arrow-left rem-100"></i>
                          </span>
                          <span>Batal</span>
                        </button>
                        <button
                          class="button h-button is-primary is-raised"
                          @click.prevent="sendKadaster"
                          :disabled="isFormInvalid"
                        >
                          Simpan
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-body">
                  <!--Fieldset-->
                  <div class="form-fieldset">
                    <div class="fieldset-heading">
                      <FormInputGambar @gambarSubmitted="getGambar($event)" />
                    </div>

                    <div class="field">
                      <label class="label">Jenis Pos</label>
                      <div class="control">
                        <div class="select is-normal">
                          <b-field type="is-link">
                            <b-select
                              v-model="form.jenisDevice"
                              placeholder="Pilih Jenis Pos"
                              expanded
                            >
                              <!-- <option value="Checkbox">Check Box</option> -->
                              <option value="PDA">PDA</option>
                              <option value="PCH">PCH</option>
                              <option value="KLM">KLM</option>
                            </b-select>
                          </b-field>
                        </div>
                      </div>
                    </div>

                    <div v-if="form.jenisDevice === 'PDA'" class="pda">
                      <div class="fieldset-heading">
                        <label class="checkbox is-outlined is-primary">
                          <input type="checkbox" v-model="form.isManualPda" />
                          <span></span>
                          PDA Manual
                        </label>
                        <label class="checkbox is-outlined is-primary">
                          <input
                            type="checkbox"
                            v-model="form.isTelemetryPda"
                          />
                          <span></span>
                          PDA Telemetry
                        </label>
                      </div>
                    </div>

                    <div v-if="form.jenisDevice === 'PCH'" class="pch">
                      <div class="fieldset-heading">
                        <label class="checkbox is-outlined is-primary">
                          <input type="checkbox" v-model="form.isManualPch" />
                          <span></span>
                          PCH Manual
                        </label>
                        <label class="checkbox is-outlined is-primary">
                          <input
                            type="checkbox"
                            v-model="form.isTelemetryPch"
                          />
                          <span></span>
                          PCH Telemetry
                        </label>
                      </div>
                    </div>

                    <div v-if="form.jenisDevice === 'KLM'" class="klm">
                      <div class="fieldset-heading">
                        <label class="checkbox is-outlined is-primary">
                          <input type="checkbox" v-model="form.isWindSpeed" />
                          <span></span>
                          Kecepatan Angin
                        </label>
                        <label class="checkbox is-outlined is-primary">
                          <input
                            type="checkbox"
                            v-model="form.isWindDirection"
                          />
                          <span></span>
                          Arah Angin
                        </label>
                        <label class="checkbox is-outlined is-primary">
                          <input type="checkbox" v-model="form.isHumidity" />
                          <span></span>
                          Kelembapan
                        </label>
                        <label class="checkbox is-outlined is-primary">
                          <input type="checkbox" v-model="form.isTemperature" />
                          <span></span>
                          Suhu
                        </label>
                        <label class="checkbox is-outlined is-primary">
                          <input type="checkbox" v-model="form.isRainfall" />
                          <span></span>
                          Curah Hujan
                        </label>
                        <label class="checkbox is-outlined is-primary">
                          <input type="checkbox" v-model="form.isRadiation" />
                          <span></span>
                          Penyinaran
                        </label>
                        <label class="checkbox is-outlined is-primary">
                          <input type="checkbox" v-model="form.isEvaporate" />
                          <span></span>
                          Penguapan
                        </label>
                        <label class="checkbox is-outlined is-primary">
                          <input type="checkbox" v-model="form.isAtmosphere" />
                          <span></span>
                          Tekanan Udara
                        </label>

                        <!-- type checkbox untuk pilihan manual dan auto -->

                        <!-- <label class="checkbox is-outlined is-primary">
                          <input type="checkbox" v-model="form.isManualKlim" />
                          <span></span>
                          Manual Klim
                        </label> 
                        <label class="checkbox is-outlined is-primary">
                          <input type="checkbox" v-model="isChecked" />
                          <span></span>
                          Automatis Klim
                        </label>
                        <div v-if="isChecked" style="margin-left: 20px">
                          <label class="checkbox is-outlined is-primary">
                            <input type="checkbox" v-model="form.isWindSpeed" />
                            <span></span>
                            Wind Speed
                          </label>
                          <label class="checkbox is-outlined is-primary">
                            <input
                              type="checkbox"
                              v-model="form.isWindDirection"
                            />
                            <span></span>
                            Wind Direction
                          </label>
                          <label class="checkbox is-outlined is-primary">
                            <input type="checkbox" v-model="form.isHumidity" />
                            <span></span>
                            Humidity
                          </label>
                          <label class="checkbox is-outlined is-primary">
                            <input
                              type="checkbox"
                              v-model="form.isTemperature"
                            />
                            <span></span>
                            Temperature
                          </label>
                          <label class="checkbox is-outlined is-primary">
                            <input type="checkbox" v-model="form.isRainfall" />
                            <span></span>
                            Rain Fall
                          </label>
                          <label class="checkbox is-outlined is-primary">
                            <input
                              type="checkbox"
                              v-model="form.isAtmosphere"
                            />
                            <span></span>
                            Atmosphere
                          </label>
                        </div>-->
                        <!-- end klim  -->
                      </div>
                    </div>

                    <!-- <div class="fieldset-heading">
                      <div class="column is-12">
                        <div class="field">
                          <label>Jenis Pos</label>
                          <div class="control">
                            <label class="checkbox is-outlined is-primary">
                              <input
                                type="checkbox"
                                v-model="form.isManualPda"
                              />
                              <span></span>
                              PDA Manual
                            </label>
                            <label class="checkbox is-outlined is-primary">
                              <input
                                type="checkbox"
                                v-model="form.isTelemetryPda"
                              />
                              <span></span>
                              PDA Telemetry
                            </label>
                            <label class="checkbox is-outlined is-primary">
                              <input
                                type="checkbox"
                                v-model="form.isManualPch"
                              />
                              <span></span>
                              PCH Manual
                            </label>
                            <label class="checkbox is-outlined is-primary">
                              <input
                                type="checkbox"
                                v-model="form.isTelemetryPch"
                              />
                              <span></span>
                              PCH Telemetry
                            </label>
                          </div>
                        </div>
                      </div>
                    </div> -->

                    <div class="columns is-multiline">
                      <div class="column is-6">
                        <div class="field">
                          <label>Nama DAS</label>
                          <div class="control has-icon">
                            <b-autocomplete
                              v-model="form.namaDas"
                              placeholder="e.g. Cimanuk"
                              :keep-first="keepFirst"
                              :open-on-focus="openOnFocus"
                              :data="filteredDataDas"
                              field="namaDas"
                              @select="
                                (option) =>
                                  option !== null
                                    ? (form.dasId = option.id)
                                    : Number()
                              "
                              :clearable="clearable"
                            >
                              <template #empty
                                >No results for {{ namaDas }}</template
                              >
                            </b-autocomplete>
                            <div class="form-icon">
                              <i class="lnil lnil-trees-alt-1"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-6">
                        <div class="field">
                          <label>Nama Sungai</label>
                          <div class="control has-icon">
                            <b-autocomplete
                              :data="filteredDataSungai"
                              v-model="form.namaSungai"
                              placeholder="e.g. Cimanuk"
                              @typing="getAsyncData"
                              :loading="isFetching"
                              field="namaSungai"
                              @select="
                                (option) =>
                                  option !== null
                                    ? (form.sungaiId = option.id)
                                    : Number()
                              "
                              :clearable="clearable"
                            >
                              <template #empty
                                >No results for {{ namaSungai }}</template
                              >
                            </b-autocomplete>
                            <div class="form-icon">
                              <i class="lnil lnil-air-flow"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--Fieldset-->
                  <div class="form-fieldset">
                    <div class="fieldset-heading">
                      <h4>Informasi Pos</h4>
                    </div>

                    <div class="columns is-multiline">
                      <div class="column is-6">
                        <div class="field">
                          <label>Nama Pos</label>
                          <div class="control has-icon">
                            <input
                              @blur="$v.form.namaPos.$touch()"
                              v-model="form.namaPos"
                              type="text"
                              class="input"
                              placeholder="Nama Pos"
                            />

                            <div v-if="$v.form.namaPos.$error">
                              <span
                                v-if="!$v.form.namaPos.required"
                                class="help is-danger"
                                >Nama Pos Harus Diisi</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-6">
                        <div class="field">
                          <label>Kode Register</label>
                          <div class="control">
                            <input
                              @blur="$v.form.kodeRegister.$touch()"
                              v-model="form.kodeRegister"
                              type="text"
                              class="input"
                              placeholder="Register"
                            />
                            <div v-if="$v.form.kodeRegister.$error">
                              <span
                                v-if="!$v.form.kodeRegister.required"
                                class="help is-danger"
                                >Register Pos Harus Diisi</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-6">
                        <div class="field">
                          <label>Latitude</label>
                          <div class="control">
                            <input
                              @blur="$v.form.lat.$touch()"
                              v-model="form.lat"
                              type="number"
                              class="input"
                              placeholder="Latitude"
                            />
                            <div v-if="$v.form.lat.$error">
                              <span
                                v-if="!$v.form.lat.required"
                                class="help is-danger"
                                >Latitude Harus Diisi</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-6">
                        <div class="field">
                          <label>Longitude</label>
                          <div class="control">
                            <input
                              @blur="$v.form.lng.$touch()"
                              v-model="form.lng"
                              type="number"
                              class="input"
                              placeholder="Longitude"
                            />
                            <div v-if="$v.form.lng.$error">
                              <span
                                v-if="!$v.form.lng.required"
                                class="help is-danger"
                                >Longitude Harus Diisi</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-6">
                        <div class="field">
                          <label>Elevasi</label>
                          <div class="control">
                            <input
                              @blur="$v.form.elevasi.$touch()"
                              v-model="form.elevasi"
                              type="number"
                              class="input"
                              placeholder="elevasi"
                            />
                            <div v-if="$v.form.elevasi.$error">
                              <span
                                v-if="!$v.form.elevasi.required"
                                class="help is-danger"
                                >elevasi Harus Diisi</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-6">
                        <div class="field">
                          <label>Lokasi Pos</label>
                          <div class="control">
                            <textarea
                              @blur="$v.form.lokasiPos.$touch()"
                              v-model="form.lokasiPos"
                              type="text"
                              class="textarea is-normal"
                              placeholder="Lokasi Pos"
                            />
                            <div v-if="$v.form.lokasiPos.$error">
                              <span
                                v-if="!$v.form.lokasiPos.required"
                                class="help is-danger"
                                >lokasi Pos Harus Diisi</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--Fieldset-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";
import SidebarNav from "../dashboardnav/SidebarNav.vue";
import MobileNavbarDash from "../dashboardnav/MobileNavbarDash.vue";
import MobileSidebarDash from "../dashboardnav/MobileSidebarDash.vue";
// import AppSpinner from "../shared/AppSpinner.vue"
import FormInputGambar from "./FormInputGambar";
import debounce from "lodash/debounce";

export default {
  name: "inputKadaster",
  components: {
    SidebarNav,
    MobileNavbarDash,
    MobileSidebarDash,
    // AppSpinner
    FormInputGambar,
  },

  data() {
    return {
      isChecked: false, // Menyimpan status checkbox utama
      sungaiData: [],
      form: {
        gambar: null,
        namaPos: null,
        kodeRegister: null,
        lat: null,
        lng: null,
        elevasi: null,
        lokasiPos: null,
        dasId: null,
        sungaiId: null,
        isTelemetryPda: false,
        isTelemetryPch: false,
        isManualPda: false,
        isManualPch: false,
        isWindSpeed: false,
        isWindDirection: false,
        isHumidity: false,
        isTemperature: false,
        isRainfall: false,
        isRadiation: false,
        isEvaporate: false,
        isAtmosphere: false,
      },
      keepFirst: false,
      openOnFocus: false,
      namaSungai: "",
      namaDas: "",
      selected: null,
      clearable: false,
      isFetching: false,
    };
  },
  validations: {
    form: {
      namaPos: { required },
      kodeRegister: { required },
      lat: { required },
      lng: { required },
      elevasi: { required },
      lokasiPos: { required },
    },
  },
  computed: {
    isFormInvalid() {
      return this.$v.form.$invalid;
    },
    ...mapState({
      sungaiList: (state) => state.sungai.items,
      images: (state) => state.image.items,
      das: (state) => state.das.items,
    }),
    filteredDataDas() {
      return this.das.filter((option) => {
        return option.namaDas.toString().indexOf(this.namaDas) >= 0;
      });
    },
    filteredDataSungai() {
      return this.sungaiData.filter((option) => {
        return option.namaSungai.toString().indexOf(this.namaSungai) >= 0;
      });
    },
  },
  created() {
    this.fetchSungai();
    this.fetchDas();
  },
  methods: {
    ...mapActions("sungai", ["fetchSungai", "searchDas"]),
    ...mapActions("image", ["fetchImage"]),
    ...mapActions("das", ["fetchDas"]),
    // ini gambar idnya
    getGambar: function (fileName) {
      this.form.gambar = fileName;
    },

    sendKadaster() {
      this.$store
        .dispatch("kadasters/createKadaster", this.form)
        .then(() => {
          // this.$emit("kadasterAddSubmitted", this.form)
          this.$toasted.success("Tambah Data Kadaster Berhasil", {
            duration: 5000,
          });
          this.$router.push("/dashboard/pos");
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 3000 });
        });
    },
    getAsyncData: debounce(function (dasId) {
      if (!dasId.length) {
        this.sungaiData = [];
        return;
      }
      this.isFetching = true;
      const params = [`dasId=${this.form.dasId}`].join("&");

      this.searchDas(params)
        .then((response) => {
          this.sungaiData = [];
          response.sungai.forEach((item) => {
            this.isFetching = true;
            this.sungaiData.push(item);
          });
        })
        .catch((error) => {
          this.sungaiData = [];
          throw error;
        })
        .finally(() => {
          this.isFetching = false;
        });
    }, 500),
  },
};
</script>
