import axiosInstance from "../../conf/axiosAdmin";

export default {
  namespaced: true,
  state: {
    items: [],
    item: {},
  },
  actions: {
    async fetchDataKlimDash({ commit }, registerId) {
      // Reset previous items before fetching new data
      commit(
        "setItems",
        { resource: "klimatologi", items: [] },
        { root: true }
      );

      try {
        // Fetch climatological data from the API
        const res = await axiosInstance.get(
          `/api/channelklim/view/${registerId}`
        );
        // console.log(res);

        const klim = res.data;

        // Commit the fetched data to the store
        commit(
          "setItems",
          { resource: "klimatologi", items: klim },
          { root: true }
        );

        // Return the response data directly
        return klim;
      } catch (error) {
        console.error("Failed to fetch data:", error);
        throw error; // Rethrow error to handle it in the component
      }
    },
  },
  mutations: {
    setItems(state, { resource, items }) {
      // Set the items for the provided resource (e.g., klimatologi)
      state[resource] = items;
    },
  },
};
