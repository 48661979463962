import axios from "../../conf/axios";

export default {
  namespaced: true,
  state: {
    items: [],
    item: {},
  },
  actions: {
    async fetchDataKlim({ commit }, registerId) {
      commit(
        "setItems",
        { resource: "landingKlim", items: [] },
        { root: true }
      );
      try {
        const res = await axios.get(`/api/landing/klim/${registerId}`);
        const klim = res.data;

        // Commit the response data to the store
        commit(
          "setItems",
          { resource: "landingKlim", items: klim },
          { root: true }
        );

        // Return the response data directly instead of state.items
        return klim; // Return the actual data here
      } catch (error) {
        console.error("Failed to fetch data:", error);
        throw error; // Rethrow error to handle it in the component
      }
    },
  },
  mutations: {
    setItems(state, { resource, items }) {
      state[resource] = items; // Set the items for the provided resource
    },
  },
};
