import axiosInstance from "../../conf/axiosAdmin";

export default {
  namespaced: true,
  state: {
    items: [],
    item: {},
  },
  getters: {},
  actions: {
    addAnswer(context, payload) {
      const headers = {
        "Content-Type": "application/json",
      };
      return axiosInstance
        .post("/api/survey/answer/create", payload, {
          headers: headers,
        })
        .then((res) => res.data);
    },
    updateAnswer(context, payload) {
      const headers = {
        "Content-Type": "application/json",
      };
      return axiosInstance.put(
        `/api/survey/answer/update/${payload.id}`,
        payload,
        {
          headers: headers,
        }
      );
    },
    fetchAnswerById({ state, commit }, Id) {
      commit("setItem", { resource: "question", item: {} }, { root: true });
      return axiosInstance.get(`/api/survey/answer/view/${Id}`).then((res) => {
        const question = res.data;
        commit(
          "setItem",
          { resource: "question", item: question },
          { root: true }
        );
        return state.item;
      });
    },
    deletAnswerById({ state, commit }, id) {
      commit("setItem", { resource: "question", item: [] }, { root: true });
      return axiosInstance
        .delete(`/api/survey/answer/remove//${id}`)
        .then((res) => {
          const question = res.data;
          commit(
            "setItem",
            { resource: "question", item: question },
            { root: true }
          );
          return state.item;
        });
    },
  },
  mutations: {},
};
