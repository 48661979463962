import axiosInstance from "../../conf/axiosAdmin";
// import ApiMethods from '../../conf/config.js'

export default {
  namespaced: true,
  state: {
    items: [],
    item: {},
  },
  getters: {},
  actions: {
    createVolumeEvaporasi(context, payload) {
      const headers = {
        "Content-Type": "application/json",
      };
      return axiosInstance
        .post("/api/evaporasi/manage/create", payload, {
          headers: headers,
        })
        .then((res) => res.data);
    },
    fetchEvaporasiByRegisterId({ state, commit }, registerId) {
      commit("setItem", { resource: "evaporasi", item: {} }, { root: true });
      return axiosInstance
        .get(`/api/evaporasi/manage/view/${registerId}`)
        .then((res) => {
          const evaporasi = res.data;
          commit(
            "setItem",
            { resource: "evaporasi", item: evaporasi },
            { root: true }
          );
          return state.item;
        });
    },
    updateVolume(context, payload) {
      const headers = {
        "Content-Type": "application/json",
      };
      return axiosInstance.put(
        `/api/evaporasi/manage/update/${payload.id}`,
        payload,
        {
          headers: headers,
        }
      );
    },
    // deleteVolume({ state, commit }, id) {
    //   commit("setItem", { resource: "evaporasi", item: {} }, { root: true });
    //   return axiosInstance
    //     .delete(`/api/evaporasi/manage/remove/${id}`)
    //     .then(() => {
    //       commit(
    //         "setItem",
    //         { resource: "evaporasi", item: {} },
    //         { root: true }
    //       );
    //       return state.item;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
  },
  mutations: {},
};
