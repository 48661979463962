<template>
  <div :class="['modal', { 'is-active': showSidebar }]">
    <div class="modal-content">
      <div class="togle button is-focused" @click="showNav">
        <span class="text">&times;</span>
      </div>
      <div class="modal-card">
        <div class="card">
          <div
            v-if="isLoaded"
            class="table-data"
            ref="tableWrapper"
            @mousedown="startDrag"
            @mousemove="onDrag"
            @mouseup="endDrag"
            @mouseleave="endDrag"
          >
            <table>
              <thead>
                <tr>
                  <th class="sticky-col">WAKTU <i class="fas fa-clock"></i></th>
                  <th
                    class="tanggal"
                    v-for="(time, index) in tanggal"
                    :key="index"
                  >
                    {{ formatDate(time) }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(channel, index) in channels" :key="index">
                  <th class="sticky-col">
                    {{ channel.channelName }} ({{ channel.satuan }})
                  </th>
                  <td
                    v-for="(time, index) in tanggal"
                    :key="index + channel.channelName"
                  >
                    <template v-if="channel.channelName === 'PENYINARAN'">
                      <div v-if="penyinaran[index] !== null" class="Penyinaran">
                        <div v-if="penyinaran[index] <= 100">
                          <img
                            src="@/assets/image/moon-app.png"
                            style="max-height: 25px"
                          />
                        </div>
                        <div v-else-if="penyinaran[index] <= 1000">
                          <img
                            src="@/assets/image/cloud-app.png"
                            style="max-height: 25px"
                          />
                        </div>
                        <div v-else>
                          <img
                            src="@/assets/image/sun-app.png"
                            style="max-height: 25px"
                          />
                        </div>
                      </div>
                    </template>
                    <template v-if="channel.channelName === 'ARAH ANGIN'">
                      <div
                        v-if="windDirection[index] !== null"
                        class="arah-agin"
                      >
                        <div
                          class="wind-icon"
                          :style="{
                            transform:
                              'rotate(' + windDirection[index] + 'deg)',
                          }"
                        >
                          <img
                            src="@/assets/image/Red_Arrow.png"
                            style="max-height: 40px"
                          />
                        </div>
                        <div class="wind-description">
                          {{
                            getWindDirectionDescription(windDirection[index])
                          }}
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      {{ channel.value ? channel.value[index] : "-" }}
                    </template>
                    <template v-if="channel.channelName === 'KELEMBAPAN'">
                      <div
                        v-if="humidity[index] !== null"
                        class="humidity-description"
                      >
                        {{ getHumidityDescription(index) }}
                      </div>
                    </template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else class="flex-list-inner spiner">
            <Roket />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Roket from "../shared/Roket.vue";

export default {
  name: "ModalTableKlim",
  components: {
    Roket,
  },
  props: {
    registerId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      isOpen: false,
      isLoaded: false,
      showSidebar: false,
      tanggal: [],
      channels: [],
      windDirection: [],
      humidity: [],
      penyinaran: [],
      isLoading: true, // Set loading state initially to true
    };
  },
  created() {
    this.fetchDataKlim(this.registerId).then(() => {
      this.isLoaded = true;
    });
  },
  computed: {
    ...mapState({
      landingKlim: (state) => state.landingKlim.items,
    }),
  },
  methods: {
    ...mapActions("landingKlim", ["fetchDataKlim"]),
    loadAsyncData(n) {
      this.fetchDataKlim(n)
        .then((klimData) => {
          // Parse 'tanggal' from the response
          this.tanggal = klimData.channel.map((entry) => entry.tanggal); // Extract 'tanggal' from 'channel'

          // Process 'channels' data
          this.channels = klimData.header.map((header, index) => {
            return {
              channelName: header.channelName,
              satuan: header.satuan,
              value: klimData.channel.map((entry) => entry[`ch${index + 1}`]), // map ch1, ch2, ch3, etc.
            };
          });

          // Set humidity, wind direction, and other specific data if needed
          const humidityChannel = this.channels.find(
            (channel) => channel.channelName === "KELEMBAPAN"
          );
          this.humidity = humidityChannel ? humidityChannel.value : [];

          const penyinaranChannel = this.channels.find(
            (channel) => channel.channelName === "PENYINARAN"
          );
          this.penyinaran = penyinaranChannel ? penyinaranChannel.value : [];

          const windDirectionChannel = this.channels.find(
            (channel) => channel.channelName === "ARAH ANGIN"
          );
          this.windDirection = windDirectionChannel
            ? windDirectionChannel.value
            : [];

          this.isLoading = false; // Set loading state to false once data is fetched
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          this.isLoading = false;
        });
    },

    formatDate(dateString) {
      const date = new Date(dateString);
      const day = ("0" + date.getDate()).slice(-2);
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      const hours = ("0" + date.getHours()).slice(-2);
      const minutes = ("0" + date.getMinutes()).slice(-2);
      const seconds = ("0" + date.getSeconds()).slice(-2);
      return `${day}-${month}-${year}, ${hours}:${minutes}:${seconds}`;
    },
    getWindDirectionDescription(degrees) {
      if (degrees >= 0 && degrees < 22.5) {
        return "Utara";
      } else if (degrees >= 22.5 && degrees < 67.5) {
        return "Timur Laut";
      } else if (degrees >= 67.5 && degrees < 112.5) {
        return "Timur";
      } else if (degrees >= 112.5 && degrees < 157.5) {
        return "Tenggara";
      } else if (degrees >= 157.5 && degrees < 202.5) {
        return "Selatan";
      } else if (degrees >= 202.5 && degrees < 247.5) {
        return "Barat Daya";
      } else if (degrees >= 247.5 && degrees < 292.5) {
        return "Barat";
      } else if (degrees >= 292.5 && degrees < 337.5) {
        return "Barat Laut";
      } else {
        return "Utara";
      }
    },
    getHumidityDescription(index) {
      const humidityValue = this.humidity[index];
      if (humidityValue >= 0 && humidityValue < 30) {
        return "Kering";
      } else if (humidityValue >= 30 && humidityValue < 60) {
        return "Normal";
      } else if (humidityValue >= 60 && humidityValue < 80) {
        return "Lembab";
      } else {
        return "Sangat Lembab";
      }
    },

    showNav() {
      this.showSidebar = !this.showSidebar;
      this.isOpen = !this.isOpen;
    },

    startDrag(event) {
      this.isDragging = true;
      this.startX = event.clientX;
      this.scrollLeft = this.$refs.tableWrapper.scrollLeft;
    },
    onDrag(event) {
      if (!this.isDragging) return;
      const x = event.clientX;
      const walk = (x - this.startX) * 2;
      this.$refs.tableWrapper.scrollLeft = this.scrollLeft - walk;
    },
    endDrag() {
      this.isDragging = false;
    },
  },
  mounted() {
    this.$root.$on("klm-notification", this.loadAsyncData);
    this.$root.$on("klm-notification", () => {
      if (!this.showSidebar) {
        this.showSidebar = !this.showSidebar;
      }
    });
  },
  beforeDestroy() {
    this.$root.$off("klm-notification", this.registerId);
  },
};
</script>

<style scoped>
.arah-agin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.wind-icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-bottom: 5px;
  font-size: 14px;
  transition: transform 0.3s ease-in-out;
}
.wind-description {
  font-size: 13px;
  font-weight: 600;
  color: #767474;
  text-align: center;
}
.humidity-description {
  font-size: 13px;
  font-weight: 600;
  color: #767474;
  text-align: center;
}
.fa-clock {
  margin-left: 10px;
}
.modal {
  height: 0vh;
}
.modal-card {
  position: fixed;
  width: 100%;
}
.modal .modal-content {
  position: fixed;
  max-width: 100%;
  bottom: -100%; /* Mulai di luar layar (bawah) */
  width: 100%;
  overflow: initial;
  transition: all 1s ease-in-out;
  animation: slideUp 1.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

@keyframes slideUp {
  0% {
    bottom: -100%; /* Mulai dari bawah layar */
  }
  100% {
    bottom: 270px; /* Posisi akhir, sesuai dengan pengaturan Anda */
  }
}
.card {
  position: relative;
  width: 100%;
  height: 270px;
  background: #fff;
  background: rgba(243, 242, 242, 0.645);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(233, 231, 231, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.171);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.109);
}
.table-data {
  position: relative;
  width: 100%;
  height: 238px;
  top: 30px;
  margin-left: 5px;
  white-space: nowrap;
  overflow: scroll;
  cursor: grab;
  user-select: none;
}

.table-data table {
  width: max-content;
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  border-collapse: collapse;
  cursor: pointer;
}

.table-data table tr {
  /* border-bottom: 1px solid rgba(128, 128, 128, 0.398); */
  padding: 5px;
}
.table-data table td {
  padding: 5px;
  text-align: center;
  /* color: #fff; */
}

.sticky-col {
  position: sticky;
  padding-right: 10px;
  padding: 5px;
  left: 0;
  backdrop-filter: blur(5px);
  background: rgb(145, 142, 142);
  border: 1px solid rgba(233, 231, 231, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.171);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.109);
  z-index: 1;
  text-align: left;
}
.tanggal {
  width: 150px;
  font-size: 13px;
  padding-top: 5px;
}
.togle {
  position: absolute;
  left: 20px;
  margin-top: 2px;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #212c5f;
  border: 1px solid #d6d5d5;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.109);
  line-height: 40px;
  cursor: pointer;
  transition: all 1s ease-in-out;
  animation-delay: 1s;
  z-index: 999;
}

.togle:hover {
  background: #2d6efd;
  transform: rotate(360deg);
  transition: all 1s ease-in-out;
  animation-delay: 1s;
}

.text {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 20px;
  font-weight: 700;
}
@media screen and (max-width: 768px) {
  .modal-card {
    left: -20px;
  }
  .sticky-col {
    font-size: 12px;
    font-weight: 600;
  }
  .togle {
    position: absolute;
    left: 20px;
    margin-top: 2px;
    width: 26px;
    height: 26px;
  }
  .text {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 30px;
    font-weight: 600;
  }
  .wind-description {
    font-size: 12px;
    font-weight: 600;
    color: #767474;
    text-align: center;
  }
  .humidity-description {
    font-size: 12px;
    font-weight: 600;
    color: #767474;
    text-align: center;
  }
}
</style>
