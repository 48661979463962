import axiosInstance from "../../conf/axiosAdmin";

export default {
  namespaced: true,
  state: {
    items: [],
    item: {},
  },
  getters: {},
  actions: {
    searchKepuasan({ state, commit }) {
      commit("setItems", { resource: "kepuasan", items: {} }, { root: true });
      return axiosInstance.get("/api/survei/search").then((res) => {
        const kepuasan = res.data.surveiList;
        // console.log(kepuasan);
        commit(
          "setItems",
          { resource: "kepuasan", items: kepuasan },
          { root: true }
        );
        return state.items;
      });
    },
  },
};
