<template>
  <div class="container">
    <div class="spiner">
      <span class="loader"></span>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}
.spiner {
  padding-top: 30px;
  height: 100px;
}
.loader {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  border-top: 4px solid #0e6ad3;
  border-right: 4px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 0;
  top: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border-left: 4px solid #fbd104;
  border-bottom: 4px solid transparent;
  animation: rotation 0.5s linear infinite reverse;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
