<template>
  <div class="modal h-modal is-big" :class="['modal', { 'is-active': isOpen }]">
    <div class="modal-background h-modal-close"></div>
    <div class="modal-content">
      <div class="modal-card">
        <header class="modal-card-head">
          <h3>Informasi Pos</h3>
          <button class="close" aria-label="close" @click="closed">
            <i class="fas fa-window-close"></i>
          </button>
        </header>
        <div class="modal-card-body" v-if="isLoaded">
          <app-spinner />
        </div>
        <div class="modal-card-body" v-else>
          <!-- <div class="inner-content">
              <div class="section-placeholder">
                 <section class="modal-card-body">         -->
          <div class="tabs-wrapper">
            <!-- <div class="tabs-inner"> -->
            <div class="tabs">
              <ul>
                <li v-bind:class="{ 'is-active': isActive == 'Detail Pos' }">
                  <a v-on:click="isActive = 'Detail Pos'">Detail Pos</a>
                </li>
                <li
                  v-bind:class="{ 'is-active': isActive == 'pdaManual' }"
                  v-if="kadaster.isManualPda"
                >
                  <a v-on:click="isActive = 'pdaManual'">Duga Air Manual</a>
                </li>
                <li
                  v-bind:class="{ 'is-active': isActive == 'pchManual' }"
                  v-if="kadaster.isManualPch"
                >
                  <a v-on:click="isActive = 'pchManual'">Curah Hujan Manual</a>
                </li>
                <li
                  v-bind:class="{ 'is-active': isActive == 'PDA' }"
                  v-if="kadaster.isTelemetryPda"
                >
                  <a v-on:click="isActive = 'PDA'">Duga Air Otomatis</a>
                </li>
                <li
                  v-bind:class="{ 'is-active': isActive == 'PCH' }"
                  v-if="kadaster.isTelemetryPch"
                >
                  <a v-on:click="isActive = 'PCH'">Curah Hujan Otomatis</a>
                </li>
                <li
                  v-bind:class="{ 'is-active': isActive == 'KLM' }"
                  v-if="kadaster.jenisDevice === 'KLM'"
                >
                  <a v-on:click="isActive = 'KLM'">Data Klimatologi</a>
                </li>
              </ul>
            </div>
          </div>
          <div
            class="tab-content"
            v-bind:class="{ 'is-active': isActive == 'Detail Pos' }"
          >
            <div class="detail">
              <div class="card">
                <section class="columns">
                  <div class="column is-12">
                    <div class="cards scroll-page">
                      <div class="columns">
                        <div class="column is-7 scroll">
                          <div class="header">
                            <h2 class="title is-4 is-narrow">Identitas Pos</h2>
                          </div>

                          <div class="card-content">
                            <div class="content">
                              <table class="table">
                                <tbody>
                                  <tr>
                                    <td>Nama Pos</td>
                                    <td>:</td>
                                    <td>{{ kdstr.namaPos }}</td>
                                  </tr>
                                  <tr>
                                    <td>Lokasi Pos</td>
                                    <td>:</td>
                                    <td>{{ kdstr.lokasiPos }}</td>
                                  </tr>

                                  <tr>
                                    <td>DAS</td>
                                    <td>:</td>
                                    <td>
                                      {{ kdstr.das ? kdstr.das.namaDas : "" }}
                                    </td>
                                  </tr>
                                  <tr v-if="kdstr.sungai">
                                    <td>Sungai</td>
                                    <td>:</td>
                                    <td>
                                      {{
                                        kdstr.sungai
                                          ? kdstr.sungai.namaSungai
                                          : ""
                                      }}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                        <div class="column gambar">
                          <div class="card is-4">
                            <div class="card-image">
                              <figure class="image is-3by2">
                                <img
                                  :src="getImgUrl"
                                  @error="onImageLoadFailure($event)"
                                />
                              </figure>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>

          <div
            class="tab-content"
            v-bind:class="{ 'is-active': isActive == 'PDA' }"
            v-if="kadaster.isTelemetryPda"
          >
            <ModalTablePda :registerId="registerId" />
          </div>

          <div
            class="tab-content"
            v-bind:class="{ 'is-active': isActive == 'PCH' }"
            v-if="kadaster.isTelemetryPch"
          >
            <section class="columns tombol">
              <div class="column is-12 tma">
                <div class="card-debit">
                  <div class="card-conten banting-kanan">
                    <button
                      class="button h-button is-success is-raised"
                      @click.prevent="cariChannelPch"
                    >
                      <i class="fas fa-search"></i>
                      Cari Data
                    </button>
                  </div>
                </div>
              </div>
            </section>
            <br />
            <div class="debit-tabs">
              <section class="columns">
                <div class="column is-12 tma">
                  <div class="card-debit">
                    <div class="card">
                      <div class="card-header"></div>
                      <div class="card-conten chart">
                        <v-chart autoresize class="chart" :option="arr" />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <br />

              <section class="channel">
                <div class="tabel-channel">
                  <!-- TABLE PCH -->
                  <ModalTablePch :registerId="registerId" />
                </div>
              </section>
            </div>
          </div>

          <div
            class="tab-content"
            v-bind:class="{ 'is-active': isActive == 'pdaManual' }"
          >
            <ModalTablePdaManual :registerId="registerId" />
          </div>

          <div
            class="tab-content"
            v-bind:class="{ 'is-active': isActive == 'pchManual' }"
          >
            <ModalTablePchManual :registerId="registerId" />
          </div>

          <div
            class="tab-content"
            v-bind:class="{ 'is-active': isActive == 'KLM' }"
          >
            <ModalTableKlim :registerId="registerId" />
          </div>
        </div>
        <div class="modal-card-foot is-right">
          <button @click="closed" class="button is-normal is-focused tutup">
            <span>Tutup</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
import VChart from "vue-echarts";
import AppSpinner from "../shared/AppSpinner.vue";
import ModalTablePda from "./ModalTablePda.vue";
import ModalTablePch from "./ModalTablePch.vue";
import ModalTablePdaManual from "./ModalTablePdaManual.vue";
import ModalTablePchManual from "./ModalTablePchManual.vue";
import ModalTableKlim from "./ModalTableKlim.vue";
export default {
  name: "ModalPosTelemetry",
  components: {
    AppSpinner,
    VChart,
    ModalTablePda,
    ModalTablePch,
    ModalTablePdaManual,
    ModalTablePchManual,
    ModalTableKlim,
  },
  data() {
    return {
      selected: "Detail Pos",
      isActive: "Detail Pos",
      registerId: {},
      isOpen: false,
      isLoaded: true,
      awlrLine: {},
      notifications: [],
      kdstr: {
        elevasi: null,
        gambar: null,
        kodeRegister: null,
        lat: null,
        lng: null,
        lokasiPos: null,
        namaPos: null,
        isManualPch: true,
        isManualPda: true,
        isTelemetryPch: true,
        isTelemetryPda: true,
        isManualKlim: true,
        sungai: {
          namaSungai: null,
        },
      },
      // charts
      waspada: 5,
      siaga: 50,
      awas: 60,
      tinggiTanggul: 150,
      chartOptions: {},

      arr: {},
      // data suhu & voltase
      voltPda: null,
      suhuPda: {},
      voltPch: {},
      suhuPch: [],
      // data table
      pda: [],
      pch: [],
      hidrologiPda: [],
      hidrologiPch: [],
      debit: [],
      totalPda: 0,
      totalPch: 0,
      totalHidroPda: 0,
      totalHidroPch: 0,
      totalDebit: 0,
      loading: false,
      sortField: "tanggal",
      sortOrder: "ASC",
      defaultSortOrder: "ASC",
      pagePda: 0,
      pagePch: 0,
      pageHidroPda: 0,
      pageHidroPch: 0,
      pageDebit: 0,
      perPage: 10,
      columnsPda: [
        {
          field: "tanggal",
          label: "Tanggal",
        },
        {
          field: "ch1",
          label: "TMA (cm)",
        },
      ],
      columnsPch: [
        {
          field: "tanggal",
          label: "Tanggal",
        },
        {
          field: "ch1",
          label: "Curah Hujan (mm)",
        },
      ],
      columnsHidroPda: [
        {
          field: "tanggal",
          label: "Tanggal",
        },
        {
          field: "pagi",
          label: "Pagi (07.00)",
        },
        {
          field: "siang",
          label: "Siang (12.00)",
        },
        {
          field: "sore",
          label: "Sore (17.00)",
        },
      ],
      columnsHidroPch: [
        {
          field: "tanggal",
          label: "Tanggal",
        },
        {
          field: "value",
          label: "value (mm)",
        },
      ],
      columnsDebit: [
        {
          field: "timestamp",
          label: "Tanggal",
        },
        {
          field: "debit",
          label: "Debit (m³)",
        },
      ],
      hasDisablePreviousPage: true,
      hasDisableNextPage: false,
      isEmpty: false,
      isBordered: false,
      isStriped: true,
      isNarrowed: true,
      isHoverable: false,
      isFocusable: false,
      isLoading: false,
      hasMobileCards: true,
      imageFile: null,
    };
  },
  computed: {
    ...mapState({
      kadaster: (state) => state.landingKadaster.item,
      channels: (state) => state.landingChannel.items,
      kanalList: (state) => state.landingHidrologi.items,
      gambar: (state) => state.landingImage.item,
      level: (state) => state.landingLevel.item,
      pchchannels: (state) => state.landingPchChannel.items,
      dugaAirManual: (state) => state.hidrologiPdaLanding.items,
      curahHujanManual: (state) => state.hidrologiPchLanding.items,
    }),
    getImgUrl() {
      var images = this.gambar;
      return images;
      // const arrayBuffer = new Uint8Array(this.imageFile);
      // const blob  = new Blob([arrayBuffer], {type: "image/png"});

      // return window.URL.createObjectURL(blob);
    },
  },
  methods: {
    ...mapActions("landingKadaster", [
      "fetchLandingKadasterById",
      "getLandingKadasterFile",
    ]),
    ...mapActions("landingChannel", ["fetchLandingPdaChannel", "addChannel"]),
    ...mapActions("landingHidrologi", ["fetchLandingPdaHidrologiKr"]),
    ...mapActions("landingImage", ["getGambar"]),
    ...mapActions("landingLevel", ["fetchLevelByRegisterId"]),
    ...mapActions("landingPchChannel", [
      "fetchLandingPchChannel",
      "addPchChannel",
    ]),
    ...mapActions("hidrologiPdaLanding", ["fetchPdaManual"]),
    ...mapActions("hidrologiPchLanding", ["fetchPchManual"]),

    setSelected(tab) {
      this.selected = tab;
    },
    appendData(n) {
      this.fetchLevelByRegisterId(n).then((resp) => {
        this.waspada = resp.waspada;
        this.siaga = resp.siaga;
        this.awas = resp.awas;
        this.tinggiTanggul = resp.tinggiTanggul;
      });

      this.fetchLandingKadasterById(n).then((resp) => {
        this.kdstr = resp;
        this.registerId = n;

        const kdreg = resp.kodeRegister;
        this.kodeRegister = kdreg;

        this.isLoaded = false;

        const gambar = resp.gambar;
        if (gambar) {
          this.getGambar(this.registerId);
        }
      });

      // LOAD PDA

      this.fetchLandingPchChannel(n).then((resp) => {
        const formatType = "YYYY-MM-DD HH:mm:ss";

        let tanggal = resp.map((r) => moment(r.tanggal).format(formatType));
        let channel1 = resp.map((r) => r.ch1);

        this.arr = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          xAxis: [
            {
              type: "category",
              data: tanggal,
              axisTick: {
                alignWithLabel: true,
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              axisLabel: {
                formatter: "{value} mm",
              },
            },
          ],
          series: [
            {
              name: "Volume",
              type: "bar",
              barWidth: "60%",
              data: channel1,
            },
          ],
        };
      });
      //END PCH
      //  this.getGambar(n).then((filename) => {

      //   this.getLandingKadasterFile(filename).then((resp)=> {
      //     //  const arrayBuffer = new Uint8Array(resp);
      //     //  const blob  = new Blob([arrayBuffer], {type: "image/png"});
      //     //  this.imageFile = window.URL.createObjectURL(blob);
      //      this.imageFile = resp;
      //  });
      // });
    },

    onImageLoadFailure(event) {
      event.target.src = require("../../assets/image1.png");
    },
    closed() {
      this.isOpen = false;
      this.isActive = "Detail Pos";
      this.isLoaded = true;
    },
    // channnel table

    //end Load Async Data
    addChannelMethod(val) {
      const chan = JSON.parse(val);
      this.addPda({ chan, serialNumber: chan.serialNumber });
    },
    /*
     * Handle page-change event
     */
    onPagePdaChange(page) {
      this.pagePda = page;
      this.loadAsyncData();
    },
    onPagePchChange(page) {
      this.pagePch = page;
      this.loadAsyncData();
    },
    onPageHidroPdaChange(page) {
      this.pageHidroPda = page;
      this.loadAsyncData();
    },
    onPageHidroPchChange(page) {
      this.pageHidroPch = page;
      this.loadAsyncData();
    },
    onPageDebitChange(page) {
      this.pageDebit = page;
      this.loadAsyncData();
    },
    /*
     * Handle sort event
     */
    onSort(field, order) {
      this.sortField = field;
      this.sortOrder = order;
      this.loadAsyncData();
    },
    incrementPda() {
      this.pagePda += 1;
      this.loadAsyncData();
    },
    incrementPch() {
      this.pagePch += 1;
      this.loadAsyncData();
    },

    decrementPda() {
      this.pagePda -= 1;
      this.loadAsyncData();
    },
    decrementPch() {
      this.pagePch -= 1;
      this.loadAsyncData();
    },

    cariChannelPch() {
      this.$router.push(`/caridata/telemetry/pch/${this.registerId}`);
    },
  },
  mounted() {
    this.$root.$on("global-notification", this.appendData);
    this.$root.$on("global-notification", () => {
      if (!this.isOpen) {
        this.isOpen = !this.isOpen;
      }
    });
  },
  beforeDestroy() {
    this.$root.$off("global-notification", this.registerId);
  },
};
</script>

<style scoped>
.modal-card-head,
.modal-card-body,
.modal-card-foot {
  animation: swingIn 1.2s ease-in-out;
}

@keyframes swingIn {
  0% {
    transform: scaleY(0.005) scaleX(0);
  }
  50% {
    transform: scaleY(0.005) scaleX(1);
  }
  100% {
    transform: scaleY(1) scaleX(1);
  }
}

.header {
  margin-left: 30px;
}

.gambar {
  margin: auto;
  margin-right: 10px;
}
button.close {
  width: 30px;
  font-size: 20px;
  color: #c0c5cb;
  align-self: flex-end;
  background-color: transparent;
  border: none;
  margin-bottom: 10px;
}
button.tutup {
  background-color: #ed6755;
  border: none;
  border-radius: 5px;
  /* width: 50px; */
  padding: 14px;
  font-size: 16px;
  color: white;
  box-shadow: 0px 6px 18px -5px rgba(237, 103, 85, 1);
}
.chart {
  height: 400px;
  width: 100%;
}

.fas {
  margin-right: 10px;
  margin-left: -10px;
}

@media (max-width: 768px) {
  .chart {
    margin-left: 2px;
  }
  .gambar {
    margin-left: 10px;
  }
}
.banting-kanan {
  float: right;
}
.tombol {
  padding-bottom: 10px;
}
</style>
