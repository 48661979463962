<template>
  <div>
    <SidebarNav />
    <MobileNavbarDash />
    <MobileSidebarDash />
    <SidebarNav />
    <!-- Content Wrapper -->
    <div class="view-wrapper">
      <div class="page-content-wrapper">
        <div class="page-content is-relative">
          <div class="page-title has-text-centered">
            <!-- Sidebar Trigger -->
          </div>
          <div class="page-content-inner">
            <!--Form Layout 1-->
            <div class="form-layout">
              <div class="form-outer">
                <div class="form-header stuck-header">
                  <div class="form-header-inner">
                    <div class="left">
                      <h3>Form Perubahan Data</h3>
                    </div>
                    <div class="right">
                      <div class="buttons">
                        <button
                          @click="$router.go(-1)"
                          class="button h-button is-light is-dark-outlined"
                        >
                          <span class="icon">
                            <i class="lnir lnir-arrow-left rem-100"></i>
                          </span>
                          <span>Batal</span>
                        </button>
                        <button
                          class="button h-button is-primary is-raised"
                          @click.prevent="updatedKadaster"
                          :disabled="isFormInvalid"
                        >
                          Simpan
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-body">
                  <!--Fieldset-->
                  <div class="form-fieldset">
                    <div class="fieldset-heading">
                      <FormInputGambar @gambarSubmitted="getGambar($event)" />
                    </div>

                    <!-- Menu star Checkbox  -->

                    <div class="field">
                      <label class="label">Jenis Pos</label>
                      <div class="control">
                        <div class="select is-normal">
                          <b-field type="is-link">
                            <b-select
                              v-model="form.jenisDevice"
                              placeholder="Pilih Jenis Pos"
                              expanded
                            >
                              <option value="PDA">PDA</option>
                              <option value="PCH">PCH</option>
                              <option value="KLM">KLM</option>
                            </b-select>
                          </b-field>
                        </div>
                      </div>
                    </div>

                    <div v-if="form.jenisDevice === 'PDA'" class="pda">
                      <div class="fieldset-heading">
                        <label class="checkbox is-outlined is-primary">
                          <input type="checkbox" v-model="form.isManualPda" />
                          <span></span>
                          PDA Manual
                        </label>
                        <label class="checkbox is-outlined is-primary">
                          <input
                            type="checkbox"
                            v-model="form.isTelemetryPda"
                          />
                          <span></span>
                          PDA Telemetry
                        </label>
                      </div>
                    </div>

                    <div v-if="form.jenisDevice === 'PCH'" class="pch">
                      <div class="fieldset-heading">
                        <label class="checkbox is-outlined is-primary">
                          <input type="checkbox" v-model="form.isManualPch" />
                          <span></span>
                          PCH Manual
                        </label>
                        <label class="checkbox is-outlined is-primary">
                          <input
                            type="checkbox"
                            v-model="form.isTelemetryPch"
                          />
                          <span></span>
                          PCH Telemetry
                        </label>
                      </div>
                    </div>

                    <div v-if="form.jenisDevice === 'KLM'" class="klm">
                      <div class="fieldset-heading">
                        <!-- <label class="checkbox is-outlined is-primary">
                          <input type="checkbox" v-model="form.isManualKlim" />
                          <span></span>
                          Manual Klim
                        </label> -->
                        <label class="checkbox is-outlined is-primary">
                          <input type="checkbox" v-model="form.isWindSpeed" />
                          <span></span>
                          Kecepatan Angin
                        </label>
                        <label class="checkbox is-outlined is-primary">
                          <input
                            type="checkbox"
                            v-model="form.isWindDirection"
                          />
                          <span></span>
                          Arah Angin
                        </label>
                        <label class="checkbox is-outlined is-primary">
                          <input type="checkbox" v-model="form.isHumidity" />
                          <span></span>
                          Kelembapan
                        </label>
                        <label class="checkbox is-outlined is-primary">
                          <input type="checkbox" v-model="form.isTemperature" />
                          <span></span>
                          Suhu
                        </label>
                        <label class="checkbox is-outlined is-primary">
                          <input type="checkbox" v-model="form.isRainfall" />
                          <span></span>
                          Curah Hujan
                        </label>
                        <label class="checkbox is-outlined is-primary">
                          <input type="checkbox" v-model="form.isRadiation" />
                          <span></span>
                          Penyinaran
                        </label>
                        <label class="checkbox is-outlined is-primary">
                          <input type="checkbox" v-model="form.isEvaporate" />
                          <span></span>
                          Penguapan
                        </label>
                        <label class="checkbox is-outlined is-primary">
                          <input type="checkbox" v-model="form.isAtmosphere" />
                          <span></span>
                          Tekanan Udara
                        </label>
                      </div>
                    </div>
                    <!-- End Checkbox  -->

                    <!-- <div class="fieldset-heading">                                     
                                     <div class="column is-12">
                                        <div class="field">
                                          <label>Jenis Pos</label>
                                            <div class="control"> 
                                              <label class="checkbox is-outlined is-primary">
                                                    <input type="checkbox" v-model="form.isManualPda">
                                                    <span></span>
                                                     PDA Manual
                                                </label>
                                                <label class="checkbox is-outlined is-primary">
                                                    <input type="checkbox" v-model="form.isTelemetryPda">
                                                    <span></span>
                                                    PDA Otomatis
                                                </label>
                                                <label class="checkbox is-outlined is-primary">
                                                    <input type="checkbox"  v-model="form.isManualPch">
                                                    <span></span>
                                                    PCH manual
                                                </label>                                                
                                                <label class="checkbox is-outlined is-primary">
                                                    <input type="checkbox" v-model="form.isTelemetryPch">
                                                    <span></span>
                                                    PCH Otomatis
                                                </label>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div> -->

                    <div class="columns is-multiline">
                      <div class="column is-6">
                        <div class="field">
                          <label>Nama DAS</label>
                          <div class="control has-icon">
                            <b-autocomplete
                              v-model="namaDas"
                              placeholder="e.g. Cimanuk"
                              :keep-first="keepFirst"
                              :open-on-focus="openOnFocus"
                              :data="filteredDataDas"
                              field="namaDas"
                              @select="
                                (option) =>
                                  option !== null
                                    ? (form.dasId = option.id)
                                    : Number()
                              "
                              :clearable="clearable"
                            >
                              <template #empty
                                >No results for {{ namaDas }}</template
                              >
                            </b-autocomplete>
                            <div class="form-icon">
                              <i class="lnil lnil-trees-alt-1"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-6">
                        <div class="field">
                          <label>Nama Sungai</label>
                          <div class="control has-icon">
                            <b-autocomplete
                              :data="filteredDataSungai"
                              v-model="namaSungai"
                              placeholder="e.g. Cimanuk"
                              @typing="getAsyncData"
                              :loading="isFetching"
                              field="namaSungai"
                              @select="
                                (option) =>
                                  option !== null
                                    ? (form.sungaiId = option.id)
                                    : Number()
                              "
                              :clearable="clearable"
                            >
                              <template #empty
                                >No results for {{ namaSungai }}</template
                              >
                            </b-autocomplete>
                            <div class="form-icon">
                              <i class="lnil lnil-air-flow"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--Fieldset-->
                  <div class="form-fieldset">
                    <div class="fieldset-heading">
                      <h4>Informasi Pos</h4>
                    </div>

                    <div class="columns is-multiline">
                      <div class="column is-6">
                        <div class="field">
                          <label>Nama Pos</label>
                          <div class="control has-icon">
                            <input
                              @blur="$v.form.namaPos.$touch()"
                              v-model="form.namaPos"
                              type="text"
                              class="input"
                              placeholder="Nama Pos"
                            />

                            <div v-if="$v.form.namaPos.$error">
                              <span
                                v-if="!$v.form.namaPos.required"
                                class="help is-danger"
                                >Nama Pos Harus Diisi</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-6">
                        <div class="field">
                          <label>Kode Register</label>
                          <div class="control">
                            <input
                              @blur="$v.form.kodeRegister.$touch()"
                              v-model="form.kodeRegister"
                              type="text"
                              class="input"
                              placeholder="Register"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="column is-6">
                        <div class="field">
                          <label>Latitude</label>
                          <div class="control">
                            <input
                              @blur="$v.form.lat.$touch()"
                              v-model="form.lat"
                              type="number"
                              class="input"
                              placeholder="Latitude"
                            />
                            <div v-if="$v.form.lat.$error">
                              <span
                                v-if="!$v.form.lat.required"
                                class="help is-danger"
                                >Latitude Harus Diisi</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-6">
                        <div class="field">
                          <label>Longitude</label>
                          <div class="control">
                            <input
                              @blur="$v.form.lng.$touch()"
                              v-model="form.lng"
                              type="number"
                              class="input"
                              placeholder="Longitude"
                            />
                            <div v-if="$v.form.lng.$error">
                              <span
                                v-if="!$v.form.lng.required"
                                class="help is-danger"
                                >Longitude Harus Diisi</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-6">
                        <div class="field">
                          <label>Elevasi</label>
                          <div class="control">
                            <input
                              @blur="$v.form.elevasi.$touch()"
                              v-model="form.elevasi"
                              type="number"
                              class="input"
                              placeholder="elevasi"
                            />
                            <div v-if="$v.form.elevasi.$error">
                              <span
                                v-if="!$v.form.elevasi.required"
                                class="help is-danger"
                                >elevasi Harus Diisi</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-6">
                        <div class="field">
                          <label>Lokasi Pos</label>
                          <div class="control">
                            <textarea
                              @blur="$v.form.lokasiPos.$touch()"
                              v-model="form.lokasiPos"
                              type="text"
                              class="textarea is-normal"
                              placeholder="Lokasi Pos"
                            />
                            <div v-if="$v.form.lokasiPos.$error">
                              <span
                                v-if="!$v.form.lokasiPos.required"
                                class="help is-danger"
                                >lokasi Pos Harus Diisi</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--Fieldset-->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";
import SidebarNav from "../dashboardnav/SidebarNav.vue";
import MobileNavbarDash from "../dashboardnav/MobileNavbarDash.vue";
import MobileSidebarDash from "../dashboardnav/MobileSidebarDash.vue";
// import AppSpinner from "../shared/AppSpinner.vue"
import FormInputGambar from "./FormInputGambar";
import debounce from "lodash/debounce";

export default {
  name: "updateKadaster",
  components: {
    SidebarNav,
    MobileNavbarDash,
    MobileSidebarDash,
    // AppSpinner
    FormInputGambar,
  },

  data() {
    return {
      sungaiData: [],
      dasData: [],
      form: {
        gambar: null,
        namaPos: null,
        kodeRegister: null,
        lat: null,
        lng: null,
        elevasi: null,
        lokasiPos: null,
        dasId: null,
        sungaiId: null,
        isTelemetryPda: null,
        isTelemetryPch: null,
        isManualPda: null,
        isManualPch: null,
        isWindSpeed: null,
        isWindDirection: null,
        isHumidity: null,
        isTemperature: null,
        isRainfall: null,
        isRadiation: null,
        isEvaporate: null,
        isAtmosphere: null,
      },
      keepFirst: false,
      openOnFocus: false,
      namaSungai: "",
      namaDas: "",
      selected: null,
      clearable: false,
      isFetching: false,
    };
  },
  validations: {
    form: {
      namaPos: { required },
      lat: { required },
      lng: { required },
      elevasi: { required },
      lokasiPos: { required },
    },
  },
  computed: {
    isFormInvalid() {
      return this.$v.form.$invalid;
    },
    ...mapState({
      sungaiList: (state) => state.sungai.items,
      images: (state) => state.image.items,
      das: (state) => state.das.items,
    }),
    filteredDataDas() {
      return this.das.filter((option) => {
        return option.namaDas.toString().indexOf(this.namaDas) >= 0;
      });
    },
    filteredDataSungai() {
      return this.sungaiData.filter((option) => {
        return option.namaSungai.toString().indexOf(this.namaSungai) >= 0;
      });
    },
  },
  created() {
    this.registerId = this.$route.params.registerId;
    this.fetchSungai();
    this.fetchDas();
    this.fetchKadasterById(this.registerId).then((res) => {
      // console.log(res);
      this.form = res;
      this.namaSungai = res.sungai.namaSungai;
      this.form.sungaiId = res.sungai.id;
      this.namaDas = res.das.namaDas;
      this.form.dasId = res.das.id;
    });
  },
  methods: {
    ...mapActions("kadasters", ["updateKadaster", "fetchKadasterById"]),
    ...mapActions("sungai", ["fetchSungai", "searchDas"]),
    ...mapActions("image", ["fetchImage"]),
    ...mapActions("das", ["fetchDas"]),
    // ini gambar idnya
    getGambar: function (fileName) {
      this.form.gambar = fileName;
    },
    updatedKadaster() {
      this.updateKadaster(this.form)
        .then(() => {
          this.$toasted.success("Rubah Data Kadaster Berhasil", {
            duration: 3000,
          });
          this.$router.push(`/dashboard/pos/${this.registerId}`);
        })
        .catch((error) => {
          this.$toasted.error(error.response.data.message, { duration: 3000 });
        });
    },
    deletKadaster() {
      this.$swal({
        title: "Apakah anda yakin akan menghapus Data Pos tersebut?!",
        text: "Mohon pastikan kembali!",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#c0392b",
        confirmButtonText: "YA, HAPUS!",
        cancelButtonText: "Batal!",
      }).then((result) => {
        // <--
        if (result.value) {
          // <-- if confirmed
          this.$router.push("/kadaster");
          this.$store.dispatch("kadaster/deleteKadasterById", this.registerId);
          this.$toasted.success("Hapus Data Kadaster Berhasil", {
            duration: 5000,
          });
          this.$emit("kadasterRemoveSubmitted", this.registerId);
        }
      });
    },

    dasAsyncData: debounce(function (namaDas) {
      if (!namaDas.length) {
        this.dasData = [];
        return;
      }
      this.isFetching = true;

      this.fetchDas()
        .then((response) => {
          this.dasData = [];
          response.forEach((item) => {
            this.isFetching = true;
            this.dasData.push(item);
          });
        })
        .catch((error) => {
          this.dasData = [];
          throw error;
        })
        .finally(() => {
          this.isFetching = false;
        });
    }, 500),

    getAsyncData: debounce(function (dasId) {
      if (!dasId.length) {
        this.sungaiData = [];
        return;
      }
      this.isFetching = true;
      const params = [`dasId=${this.form.das.id}`].join("&");

      this.searchDas(params)
        .then((response) => {
          this.sungaiData = [];
          response.sungai.forEach((item) => {
            this.isFetching = true;
            this.sungaiData.push(item);
          });
        })
        .catch((error) => {
          this.sungaiData = [];
          throw error;
        })
        .finally(() => {
          this.isFetching = false;
        });
    }, 500),
  },
};
</script>
