<template>
  <div class="">
    <div class="">
      <div class="nav">
        <div class="linknav">
          <LinkNavigationBaru />
        </div>
      </div>

      <div class="layer2">
        <div class="field">
          <div class="control">
            <InformasiPosBaruShare />
          </div>
        </div>
      </div>

      <div>
        <l-map
          class="maps"
          :zoom="zoom"
          :center="center"
          :options="{ zoomControl: false }"
          @click="onMapClick"
        >
          <l-control-zoom position="topright"></l-control-zoom>
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-geo-json :geojson="wilKer" :options-style="styleWilker" />
          <l-geo-json :geojson="geojson" :options-style="styleFunction" />
          <l-marker
            v-for="(pos, index) in posDinas"
            :lat-lng="[pos.lat, pos.lng]"
            :key="index"
            :icon="getIcon(pos)"
          >
            <l-tooltip>
              <span>{{ pos.namaPos }}</span>
            </l-tooltip>
            <l-popup class="popup">
              <table class="table">
                <tbody>
                  <tr>
                    <td>Nama Pos</td>
                    <td>:</td>
                    <td>{{ pos.namaPos }}</td>
                  </tr>
                  <tr>
                    <td>Lokasi</td>
                    <td>:</td>
                    <td>{{ pos.lokasiPos }}</td>
                  </tr>
                  <tr>
                    <td>DAS</td>
                    <td>:</td>
                    <td>{{ pos.namaDas }}</td>
                  </tr>
                  <tr>
                    <td>Pengelola Pos</td>
                    <td>:</td>
                    <td>{{ pos.dinasPengelola }}</td>
                  </tr>
                </tbody>
              </table>
              <div class="tombol" v-if="pos.registerId !== null">
                <button
                  class="button lihat"
                  @click="handleEmit(pos.registerId)"
                  type="is-success"
                  outlined
                >
                  <i class="fas fa-search"></i>
                  <span class="title2"> Lihat Data </span>
                </button>
              </div>
            </l-popup>
          </l-marker>

          <!-- <l-control class="layer-control" position="topleft">
            <div class="field">
              <div class="control">
            <LinkNavigationBaru />
              </div>
          </div>
          </l-control>   -->

          <l-control class="layer-control" position="bottomright">
            <!-- legenda info pos -->

            <div class="layer1">
              <div class="cards">
                <span class="title1"> Informasi Peta </span>
              </div>
              <div class="field">
                <div class="control">
                  <div class="lcontrol">
                    <div class="field">
                      <div class="">
                        <div class="biru"></div>
                      </div>

                      <span>Pos Duga Air</span>
                    </div>

                    <div class="field">
                      <div class="">
                        <div class="hijau"></div>
                      </div>

                      <span>Pos Curah Hujan</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- end -->
          </l-control>
        </l-map>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  LMap,
  LTileLayer,
  LMarker,
  LGeoJson,
  LControl,
  LControlZoom,
} from "vue2-leaflet";
import { icon } from "leaflet";
import LinkNavigationBaru from "../navigation/LinkNavigationBaru.vue";
import InformasiPosBaruShare from "./InformasiPosBaruShare.vue";

export default {
  name: "PosDinasMap",
  components: {
    LMap,
    LControlZoom,
    LTileLayer,
    LMarker,
    LGeoJson,
    LControl,
    LinkNavigationBaru,
    InformasiPosBaruShare,
  },
  data() {
    return {
      zoom: 9.5,
      center: [-6.7473905, 108.5505455],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors - Unit Hidrologi dan Kualitas Air - BBWS Cimanuk Cisanggarung © 2024',
      markerLatLng: [-6.73003, 108.54273],
      newLoc: "",
      newLt: 0,
      newLng: 0,
      locations: [
        {
          name: "BBWS Cimanuk",
          position: [-6.73003, 108.54273],
        },
      ],
      posDinas: [],
      icon: null,
      geojson: require("@/assets/geojson/SUNGAI_LN.json"),
      wilKer: require("@/assets/geojson/administrasi.json"),
    };
  },
  created() {
    this.fetchLandingPos().then((resp) => {
      // console.log(resp);
      resp.forEach((item) => {
        this.posDinas.push(item);
      });
    });
  },
  computed: {
    ...mapState({
      landingPosDinas: (state) => state.landingPosDinas.items,
    }),
    styleFunction() {
      return () => {
        return {
          weight: 1,
          color: "#0652DD",
          opacity: 1,
          fillColor: "#c23616",
          fillOpacity: 1,
        };
      };
    },
    styleWilker() {
      return () => {
        return {
          weight: 3,
          color: "#4b6584",
          opacity: 1,
          fillColor: "#c23616",
          fillOpacity: 1,
        };
      };
    },
  },
  methods: {
    ...mapActions("landingPosDinas", ["fetchLandingPos"]),
    onMapClick(e) {
      this.newLt = e.latlng.lat;
      this.newLng = e.latlng.lng;
      this.newLoc =
        "Location on click: " +
        e.latlng.lat.toFixed(6) +
        "," +
        e.latlng.lng.toFixed(6);
    },
    lokasi() {
      this.fetchLandingPos();
    },
    getIcon(pos) {
      if (pos.jenisPos === "PDA") {
        return icon({
          iconUrl: require("../../assets/image/PDA-32.gif"),
          iconSize: [37, 32],
          iconAnchor: [16, 37],
        });
      } else {
        return icon({
          iconUrl: require("../../assets/image/PCH-32.gif"),
          iconSize: [32, 37],
          iconAnchor: [16, 37],
        });
      }
    },
    handleEmit(event) {
      this.$root.$emit("pos-dinas", event);
    },
  },
  mounted() {
    this.newLoc = this.locations[0].name;
    this.newLt = this.locations[0].position[0];
    this.newLng = this.locations[0].position[1];
    this.lokasi();
  },
};
</script>

<style scoped>
.maps {
  width: 100%;
  height: 100vh;
  z-index: 1;
}
.nav {
  position: absolute;
  margin-top: 5px;
  margin-left: 10px;
  z-index: 9;
}

.layer2 {
  position: absolute;
  margin-top: 125px;
  right: 5px;
  z-index: 9;
}

.fas {
  color: #fff;
}

button.lihat {
  background-color: #ed6755;
  border: none;
  border-radius: 5px;
  /* width: 50px; */
  padding: 14px;
  font-size: 16px;
  color: white;
  box-shadow: 0px 6px 18px -5px rgba(237, 103, 85, 1);
}

.title2 {
  font-size: 12px;
  font-weight: 500;
  margin-left: 10px;
  padding-top: 2px;
  color: #fff;
}

/* legenda info pos  */

.cards {
  background: #212c5f;
  text-align: center;
}

.title1 {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  padding-top: 10px;
  margin-left: 15px;
}

.layer1 {
  position: relative;
  width: 250px;
  height: 75px;
  box-sizing: border-box;
  backdrop-filter: blur(5px);
  background: rgba(255, 254, 254, 0.369);
  border: 1px solid rgba(233, 231, 231, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.171);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.109);
  border-radius: 0 0 10px 10px;
  transition: 0.5s;
}

.lcontrol {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
}

span {
  font-family: "Roboto", Arial, sans-serif;
  font-size: 12px;
  font-weight: 500;
  color: black;
}

.biru {
  background-image: url("../../assets/image/PDA-32.gif");
  width: 20px;
  height: 20px;
  margin-right: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-color: white;
  border-radius: 2px;
}

.hijau {
  background-image: url("../../assets/image/PCH-32.gif");
  width: 20px;
  height: 20px;
  background-size: contain;
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-color: white;
  border-radius: 2px;
}

@media screen and (max-width: 1366px) {
  .layer2 {
    right: 22px;
  }
}

@media screen and (max-width: 1024px) {
  .layer2 {
    overflow: hidden;
  }
}

@media screen and (max-width: 768px) {
  .layer2 {
    display: none;
  }
  .nav {
    margin-top: 5px;
    margin-left: 4px;
  }
  .maps {
    width: 100%;
    height: 92vh;
  }
  .layer1 {
    position: relative;
    width: 200px;
    height: 75px;
    backdrop-filter: blur(5px);
    background: rgba(255, 254, 254, 0.369);
    border: 1px solid rgba(233, 231, 231, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.171);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.109);
    border-radius: 0 0 10px 10px;
    transition: 0.5s;
  }

  .lcontrol {
    display: flex;
    justify-content: space-evenly;
    margin-top: 0px;
  }

  span {
    font-family: "Roboto", Arial, sans-serif;
    font-size: 10px;
    font-weight: 500;
    color: black;
  }

  .biru {
    background-image: url("../../assets/image/PDA-32.gif");
    width: 16px;
    height: 16px;
    margin-top: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-color: white;
    border-radius: 2px;
  }

  .hijau {
    background-image: url("../../assets/image/PCH-32.gif");
    width: 16px;
    height: 16px;
    margin-top: 10px;
    background-size: contain;
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-color: white;
    border-radius: 2px;
  }
}
</style>
