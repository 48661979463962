import axiosInstance from "../../conf/axiosAdmin";

export default {
  namespaced: true,
  state: {
    items: [],
    item: {},
  },
  getters: {},
  actions: {
    async fetchActivity({ state, commit }) {
      commit("setItems", { resource: "activity", items: [] }, { root: true });
      return axiosInstance.get("/api/activity/search").then((res) => {
        const activities = res.data.activities;
        // console.log(res.data.activities)
        commit(
          "setItems",
          { resource: "activity", items: activities },
          { root: true }
        );
        return state.items;
      });
    },
    async searchActivity({ state, commit }, params) {
      commit("setItems", { resource: "activity", items: [] }, { root: true });
      return await axiosInstance
        .get(`/api/activity/search?${params}`)
        .then((res) => {
          const results = res.data;
          // console.log(res.data.activities)
          commit(
            "setItems",
            { resource: "activity", items: results },
            { root: true }
          );
          return state.items;
        });
    },
  },
  mutations: {},
};
